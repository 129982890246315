import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import pluralize from 'pluralize';
import AddCompanyModal from '../../components/partner/AddCompanyModal';
import {
    Heading2,
    BigModal,
    TextTiny,
    TextDefault,
    TextLead,
    Block,
    FlexDiv,
    SpaceBetweenDiv,
    SearchInput,
    StatusBadge,
    ThemedButton,
    palette,
    DarkArrowTooltipIcon,
} from '@awarego/awarego-components';
import { useStores } from '../../hooks/use-stores';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import SubscriptionOverview from './SubscriptionOverview';

import AddCompanyConfirm from '../../components/partner/AddCompanyConfirm';
import RemoveSubscriptionConfirm from '../../components/partner/RemoveSubscriptionConfirm';
import StopCampaignsConfirm from '../../components/partner/StopCampaignsConfirm';
import { formatCurrency, isSubscriptionPlanFree } from '../../utils/helpers';
import TableClient from '../../components/table/TableClient';
import StyledLink from '../../components/StyledLink';

export default observer(() => {
    const {
        store,
        commonStore,
        companyStore,
        subscriptionStore,
        partnerStore,
    } = useStores();

    const { partnerId } = useParams();

    const { partnerEstimate, estimationLoading, companyForm } =
        subscriptionStore;

    const {
        loadingPartnerCompanies,
        filteredPartnerCompanies,
        loadingPartnerSubscriptionsOverview,
        partnerSubscriptionsOverview,
        companySearchValue,
        companies,
        activeCompaniesCount,
        inactiveCompaniesCount,
    } = partnerStore;

    const [showNewCompanyModal, setShowNewCompanyModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [company, setOpenedCompany] = useState(null);
    const [deletingCompany, setDeletingCompany] = useState(false);
    const rowsPerPage = 20;
    const partnerCompanyLink = {
        url: `/partner/${partnerId}/companies/`,
        suffix: 'id',
    };

    let navigate = useNavigate();
    const now = useMemo(() => new Date(), []);

    const loadData = useCallback(async () => {
        await partnerStore.loadPartnerSubscriptionsOverview(partnerId);
        await partnerStore.loadPartnerCompanies(partnerId);
        await subscriptionStore.loadSubscriptionPlans(partnerId);
    }, [partnerId, subscriptionStore, partnerStore]);

    useEffect(() => {
        void loadData();
    }, [loadData, partnerId]);

    const handleSubmitAddNewCompany = useCallback(() => {
        setShowNewCompanyModal(false);
        commonStore.showConfirm(
            isSubscriptionPlanFree(
                subscriptionStore.subscriptionPlans,
                subscriptionStore.companyForm.values().subscription
            ) ? (
                <FlexDiv column>
                    <TextLead mb={16}>
                        You are creating a Free plan for{' '}
                        {subscriptionStore.companyForm.values().companyName}.
                    </TextLead>
                    <TextLead>
                        The Free plan available for up to 10 users includes
                        selected training subjects and assessment questions.
                    </TextLead>
                </FlexDiv>
            ) : (
                <AddCompanyConfirm
                    companyForm={subscriptionStore.companyForm}
                    partnerEstimate={subscriptionStore.partnerEstimate}
                />
            ),
            'Confirm',
            'Confirm new company',
            async () => {
                const result = await companyStore.handleCreateCompany(
                    partnerId,
                    loadData
                );
                if (result)
                    navigate(
                        '/partner/' +
                            partnerId +
                            '/companies/' +
                            companyStore.company.company_id
                    );
            },

            false
        );
    }, [
        commonStore,
        companyStore,
        loadData,
        partnerId,
        subscriptionStore.companyForm,
        subscriptionStore.partnerEstimate,
        subscriptionStore.subscriptionPlans,
    ]);

    const handleDownloadCSV = () => {
        partnerStore.downloadCSV(partnerId);
    };

    const openNewCompanyModal = () => {
        subscriptionStore.initCompanyForm(partnerId, handleSubmitAddNewCompany);
        setShowNewCompanyModal(true);
    };

    const closeNewCompanyModal = () => {
        setShowNewCompanyModal(false);
        subscriptionStore.setPartnerEstimate(null);
    };

    const onDelete = async (
        companyId,
        companyName,
        subscriptionCode,
        subscriptionName,
        isInactive
    ) => {
        setDeletingCompany(true);
        let canBeRemovedResult = await subscriptionStore.canBeRemoved(
            partnerId,
            companyId
        );
        if (canBeRemovedResult && canBeRemovedResult.canBeRemoved) {
            let estimationResult = await subscriptionStore.loadEstimation(
                partnerId,
                subscriptionCode,
                0,
                companyId
            );
            if (estimationResult) {
                setOpenedCompany(null);
                commonStore.showConfirm(
                    <RemoveSubscriptionConfirm
                        company={company}
                        partnerEstimate={subscriptionStore.partnerEstimate}
                        verb={'delete'}
                        action={'deleting'}
                        isFree={isSubscriptionPlanFree(
                            subscriptionStore.subscriptionPlans,
                            subscriptionCode
                        )}
                        subscriptionName={subscriptionName}
                        isInactive={isInactive}
                    />,
                    'Yes, delete',
                    'Delete company',
                    async () => {
                        let deletionResult = await partnerStore.deleteCompany(
                            partnerId,
                            companyId
                        );
                        if (deletionResult) {
                            loadData();
                            commonStore.success('Company deleted.');
                        } else {
                            if (partnerStore.error) {
                                commonStore.error(partnerStore.error);
                            }
                        }
                    },
                    true
                );
            } else {
                setOpenedCompany(null);
                commonStore.showConfirm(
                    <StopCampaignsConfirm
                        action={'delete ' + companyName}
                        result={canBeRemovedResult}
                    />,
                    'Ok',
                    'Unable to delete company',
                    () => {},
                    false,
                    false
                );
            }
        } else {
            setOpenedCompany(null);
            commonStore.showConfirm(
                <StopCampaignsConfirm
                    action={'delete ' + companyName}
                    result={canBeRemovedResult}
                />,
                'Ok',
                'Unable to delete company',
                () => {},
                false,
                false
            );
        }
        setDeletingCompany(false);
    };

    const visitDashboard = async (companyId) => {
        await store.setCurrentCompany(companyId);
        await navigate(`/`);
    };

    const totalAllotments = companies.reduce((allotments, company) => {
        return (
            allotments +
            (company.allowedUsers ? parseInt(company.allowedUsers) : 0)
        );
    }, 0);
    const employeeTotal = companies
        .filter((c) => c.valid_to !== null)
        .reduce(
            (totalEmployees, company) => totalEmployees + company.userCount,
            0
        );

    const columns = useMemo(
        () => [
            {
                Header: 'Company',
                accessor: 'name',
                Cell: (x) => {
                    return (
                        <>
                            <StyledLink
                                to={`/partner/${partnerId}/companies/${x.row.original.id}`}
                                $hoverColor={palette.graphite.onyx}
                                $underlined={true}
                            >
                                {x.value}
                            </StyledLink>
                        </>
                    );
                },
            },
            {
                Header: 'Subscription',
                accessor: 'subscriptionName',
            },
            {
                Header: 'Seats',
                accessor: 'allowedUsers',
                Cell: (x) => (x.value !== null ? x.value : '-'),
            },
            {
                Header: 'Employees',
                accessor: 'userCount',
            },
            {
                Header: 'Subscription status',
                accessor: (x) => {
                    const validTo = x.valid_to;
                    return validTo && new Date(validTo) > now ? 1 : -1;
                },
                Cell: (x) => {
                    const validTo = x.row.original.valid_to;
                    return validTo && new Date(validTo) > now ? (
                        <StatusBadge
                            color={palette.evergreen.aspargus}
                            text={'Active'}
                        />
                    ) : (
                        <StatusBadge
                            color={palette.vibrant.red}
                            text={'Inactive'}
                        />
                    );
                },
            },
            {
                Header: 'Active until',
                accessor: 'valid_to',
                Cell: (x) => {
                    return (
                        <div
                            style={
                                x.value && new Date(x.value) > now
                                    ? {}
                                    : { color: palette.vibrant.red }
                            }
                        >
                            {x.value
                                ? moment(x.value).format('DD/MM/YYYY')
                                : '-'}
                        </div>
                    );
                },
            },
            {
                Header: 'Monthly cost',
                accessor: 'price',
                Cell: observer((x) => {
                    const validTo = x.row.original.valid_to;
                    return validTo && new Date(validTo) > now
                        ? formatCurrency(
                              x.value,
                              partnerSubscriptionsOverview &&
                                  partnerSubscriptionsOverview.currencyCode
                          )
                        : `-`;
                }),
            },
            {
                Header: ' ',
                Cell: (x) => buildExtraActionsColumn(x.row.original),
            },
        ],
        [now, partnerSubscriptionsOverview]
    );

    const buildExtraActionsColumn = (row) => {
        return (
            <>
                <DarkArrowTooltipIcon
                    customFunction={handleMenuOpen}
                    row={row}
                    title="More"
                    icon={'more'}
                ></DarkArrowTooltipIcon>
            </>
        );
    };

    const handleMenuOpen = (e, company) => {
        setOpenedCompany(company);
        setAnchorEl(e.currentTarget);
        e.stopPropagation();
    };

    const handleMenuClose = (e) => {
        setOpenedCompany(null);
        setAnchorEl(null);
        e.stopPropagation();
    };

    return (
        <Fragment>
            <SubscriptionOverview
                partnerId={partnerId}
                partnerSubscriptionsOverview={partnerSubscriptionsOverview}
                loadingPartnerSubscriptionsOverview={
                    loadingPartnerSubscriptionsOverview
                }
            />
            <Block>
                <SpaceBetweenDiv ml={16} pr={32}>
                    <FlexDiv column>
                        <Heading2>Companies</Heading2>
                        <TextDefault>
                            {companies ? companies.length : 0}{' '}
                            {pluralize('company', companies.length)}
                            {(companies.length !== activeCompaniesCount ||
                                inactiveCompaniesCount > 0) && (
                                <>
                                    {' '}
                                    ({activeCompaniesCount} active,{' '}
                                    {inactiveCompaniesCount} inactive)
                                </>
                            )}{' '}
                            · {employeeTotal} / {totalAllotments} seats
                            allocated
                        </TextDefault>
                    </FlexDiv>
                    <FlexDiv>
                        <SearchInput
                            label=""
                            placeholder="Search companies"
                            searchValue={companySearchValue}
                            onChange={(e) => {
                                partnerStore.setCompanySearchValue(
                                    e.target.value
                                );
                            }}
                            onClear={() =>
                                partnerStore.setCompanySearchValue('')
                            }
                        />
                        <ThemedButton
                            mode="secondary"
                            $airy
                            onClick={openNewCompanyModal}
                            text="Add Company"
                        />
                        <ThemedButton
                            mode="secondary"
                            $airy
                            onClick={handleDownloadCSV}
                            text="Export"
                        />
                    </FlexDiv>
                </SpaceBetweenDiv>
                {loadingPartnerCompanies === null ||
                loadingPartnerCompanies ||
                companyStore.creatingCompany ||
                partnerStore.removingCompanies.length ? (
                    <ThemedSpinner />
                ) : (
                    <FlexDiv mt={32}>
                        {filteredPartnerCompanies && (
                            <TableClient
                                data={filteredPartnerCompanies}
                                columns={columns}
                                link={partnerCompanyLink}
                                initialPageSize={rowsPerPage}
                                defaultSortDescent={false}
                                defaultSortBy={'name'}
                                hidePagination={
                                    filteredPartnerCompanies.length <=
                                    rowsPerPage
                                }
                            />
                        )}
                    </FlexDiv>
                )}
                {company && (
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={true}
                        onClose={handleMenuClose}
                        elevation={1}
                    >
                        <MenuItem onClick={() => visitDashboard(company.id)}>
                            <TextTiny bold>Go to dashboard</TextTiny>
                        </MenuItem>
                        <MenuItem
                            component={Link}
                            to={`/partner/${partnerId}/companies/${company.id}`}
                        >
                            <TextTiny bold>Edit subscription</TextTiny>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                onDelete(
                                    company.id,
                                    company.company_name,
                                    company.subscriptionCode,
                                    company.subscriptionName,
                                    company.price === null
                                );
                            }}
                        >
                            {deletingCompany ? (
                                <Spinner size={30} />
                            ) : (
                                <TextTiny bold error>
                                    Delete
                                </TextTiny>
                            )}
                        </MenuItem>
                    </Menu>
                )}
            </Block>
            <BigModal
                isOpen={showNewCompanyModal}
                onRequestClose={closeNewCompanyModal}
                heading="Add new company"
                modalContent={
                    <AddCompanyModal
                        companyForm={companyForm}
                        partnerId={partnerId}
                        partnerEstimate={subscriptionStore.partnerEstimate}
                        estimationLoading={estimationLoading}
                    />
                }
                modalFooterContent={
                    <SpaceBetweenDiv>
                        <FlexDiv column>
                            <TextTiny lighter bold>
                                Total
                            </TextTiny>
                            {!estimationLoading && !partnerEstimate && (
                                <TextTiny bold lighter>
                                    –
                                </TextTiny>
                            )}
                            {estimationLoading ? (
                                <Spinner />
                            ) : (
                                partnerEstimate && (
                                    <FlexDiv>
                                        <TextLead bold>
                                            {formatCurrency(
                                                partnerEstimate.newCompanyTotal,
                                                partnerEstimate.currencyCode
                                            )}
                                        </TextLead>
                                        <TextLead lighter bold>
                                            /month
                                        </TextLead>
                                    </FlexDiv>
                                )
                            )}
                        </FlexDiv>
                        <FlexDiv>
                            <ThemedButton
                                mode="secondary"
                                onClick={closeNewCompanyModal}
                                text="Cancel"
                            />
                            <ThemedButton
                                disabled={estimationLoading}
                                mode="primary"
                                $airy
                                onClick={() => companyForm.submit()}
                                text="Review and confirm"
                            />
                        </FlexDiv>
                    </SpaceBetweenDiv>
                }
            />
        </Fragment>
    );
});
