import Form from 'mobx-react-form';
import * as validatorjs from 'validatorjs';
import dvr from 'mobx-react-form/lib/validators/DVR';

const plugins = {
    dvr: dvr({
        package: validatorjs,
        extend: ({ validator }) => {
            const messages = validator.getMessages('en');
            messages.required = 'You need to enter some information to save';
            validator.setMessages('en', messages);

            validator.register(
                'noSameValue',
                function (value, requirement) {
                    return value !== requirement;
                },
                'New value must be different than the original.'
            );
        },
    }),
};


class CompanyGenericForm extends Form {
    constructor(fields, setup, options) {
        super({ fields, ...setup }, { plugins, ...options });
    }
}

export default CompanyGenericForm;
