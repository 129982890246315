import React, { Fragment, useEffect, useState } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import clsx from 'classnames';
import pick from 'lodash/pick';
import { Link } from 'react-router-dom';
import AzureFormDef from '../../forms/azure-ad';
import moment from 'moment';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { ThemedDropdownItem } from '../../components/themed/ThemedComponents';
import TextField from '../../components/inputs/textField';
import ErrorMessage from '../../components/errormessage';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useStores } from '../../hooks/use-stores';
import IntegrationDetailSidebar from '../../components/company/integration-detail-sidebar';
import IntegrationDetailGroupSettings from '../../components/company/integration-detail-group-settings';
import IntegrationDetailExcludedUsers from '../../components/company/integration-detail-exluded-users';
import {
    SpaceBetweenDiv,
    TextTiny,
    FlexDiv,
    TextLead,
    Heading2,
    ThemedButton,
    palette,
} from '@awarego/awarego-components';
import io from 'socket.io-client';
import { Spinner } from 'reactstrap';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    Link as MuiLink,
} from '@mui/material';
import Box from '@mui/material/Box';
import { extractErrorMessage } from '../../utils/helpers';
import Setting from '../settings/Setting';
import Modal from 'react-modal';
import CompanyGenericEditor from './CompanyGenericEditor';

function TogglingErrorMessage({ e }) {
    const [showErrorDetails, setShowErrorDetails] = useState(false);
    const toggleShowErrorDetails = () => {
        setShowErrorDetails(!showErrorDetails);
    };

    return (
        <>
            <MuiLink onClick={toggleShowErrorDetails}>
                <TextTiny link>
                    {showErrorDetails ? 'Hide details' : 'Show details'}
                </TextTiny>
            </MuiLink>
            {showErrorDetails && (
                <ErrorMessage error={extractErrorMessage(e)} />
            )}
        </>
    );
}

function CompanyAzureAd({ companyId }) {
    const {
        companyStore,
        commonStore,
        authStore,
        brandingStore,
        azureGroupsStore,
    } = useStores();

    const {
        savingAzureData,
        loadingAzureDatas,
        loadingAzureGroups,
        syncRunning,
    } = companyStore;
    const { account_name, brandingLoaded, integrationADUrl } = brandingStore;
    let data = companyStore.azureDataIndex[companyId];

    const latestRunningSyncId = data?.latestRunningSyncId;
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [lastStatusesDropdown, setLastStatusesDropdown] = useState(false);
    const [showEditCompanyModal, setShowEditCompanyModal] = useState(false);

    const companyFields = {
        'azure.clientSecret': {
            label: 'Client Secret',
            rules: 'string|required|between:4,150',
            placeholder: data && data.clientSecret ? data.clientSecret : '',
        }
    };

    const openDetailsDialog = () => {
        setDetailsDialogOpen(data.syncStatuses[0]);
    };
    const closeDetailsDialog = () => {
        setDetailsDialogOpen(false);
    };

    const openEditModal = () => {
        setShowEditCompanyModal(true);
    };

    const closeEditModal = () => {
        setShowEditCompanyModal(false);
    };

    const toggleLastStatusesDropdown = () => {
        setLastStatusesDropdown(!lastStatusesDropdown);
    };

    useEffect(() => {
        if (!latestRunningSyncId) return;
        const socket = io.connect(import.meta.env.REACT_APP_API_URL, {
            query: `token=${authStore.token}`,
            transports: ['websocket'],
        });

        socket.on('unauthorized', (error) => {
            if (
                error.data.type === 'UnauthorizedError' ||
                error.data.code === 'invalid_token'
            ) {
                console.log('error', error);
            }
        });

        socket.on('connect', async function () {
            console.log('connected');
            socket.emit(`integration`, {
                id: latestRunningSyncId,
            });
        });

        socket.on(
            `integration:${latestRunningSyncId}:update_progress`,
            function (data) {
                companyStore.updateSyncdata(companyId, data);
                if (data.progress === 100) {
                    void doLoadData();
                }
            }
        );

        socket.on('disconnect', function () {
            //  console.log('disconnect');
        });

        return () => {
            socket.disconnect();
            socket.close();
        };
    }, [authStore.token, latestRunningSyncId]);

    const store = useLocalObservable(() => ({
        _form: new AzureFormDef(
            {},
            {
                hooks: {
                    onSuccess: async () => {
                        try {
                            await companyStore.saveIntegrationData(
                                companyId,
                                'azure',
                                { ...store._form.values(), enabled: '1' }
                            );
                            await doLoadData();
                        } catch (e) {
                            commonStore.showConfirm(
                                <>
                                    Looks like we can’t connect to Azure AD.
                                    Please try again and make sure you are using
                                    the right IDs.
                                    <br />
                                    {e && <TogglingErrorMessage e={e} />}
                                </>,
                                'Try again',
                                'Something went wrong',
                                () => {
                                    // store._form.onSubmit();
                                },
                                false,
                                true,
                                false,
                                { confirmationCancelAction: 'Close' }
                            );
                        }
                    },
                },
            }
        ),
        update(values) {
            store._form.update(values);
        },
    }));

    const doLoadData = async () => {
        console.log('doLoadData');
        azureGroupsStore.setError(null);
        await companyStore.loadIntegrationData(companyId, 'azure');

        let obj = companyStore.azureDataIndex[companyId];
        if (obj) {
            store.update(pick(obj, 'tenant', 'clientId', 'clientSecret'));
        }
    };

    useEffect(() => {
        doLoadData();
    }, [companyId]);

    const syncNow = async () => {
        try {
            let result = await companyStore.syncIntegration(companyId, 'azure');
            if (result && !result.error)
                commonStore.showMessage('Sync Started Successful');
            else
                commonStore.showMessage(
                    'Sync failed: ' + result.error,
                    'error'
                );
        } catch (e) {
            commonStore.showMessage('Sync failed: ' + e.message, 'error');
        }
    };

    const handleUnlink = async () => {
        commonStore.showConfirm(
            'Employees that have already been imported with the Active Directory integration will stay, but new employees will not be synced. This will not remove already imported groups or stop current training programs or assessments.',
            'YES, UNLINK',
            'Unlink integration?',
            doHandleUnlink,
            true,
            true,
            false,
            { confirmationCancelAction: 'NO, CANCEL' }
        );
    };
    const doHandleUnlink = async () => {
        try {
            let result = await companyStore.unlinkIntegration(
                companyId,
                'azure'
            );
            store._form.reset();
            await doLoadData();
            if (result) commonStore.showMessage('Sync Unlinked Successful');
        } catch (e) {
            commonStore.showMessage('Unlink failed: ' + e.message, 'error');
        }
    };

    return loadingAzureDatas.includes(companyId) || !data ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <Link to=".." relative="path">
                <div className={'navigation back-button'}>
                    Back to Integration overview
                </div>
            </Link>
            <Row>
                <Col lg="8" xs="12">
                    <div className="integration-detail-block align-left">
                        {azureGroupsStore.error && (
                            <ErrorMessage
                                error={azureGroupsStore.error}
                                marginBottom={true}
                            />
                        )}

                        <FlexDiv fullWidth>
                            <FlexDiv column flexGrow={5} mr={32}>
                                <Heading2 mb={16}>
                                    {(brandingLoaded && account_name) ||
                                        'AwareGO'}{' '}
                                    + Azure Active Directory
                                </Heading2>
                                <p
                                    className={clsx({
                                        'margin-bottom': data.enabled === '0',
                                    })}
                                >
                                    Sync with your Azure AD and import all users
                                    (or only the users you specify) into the{' '}
                                    {(brandingLoaded && account_name) ||
                                        'AwareGO'}{' '}
                                    portal.
                                </p>
                            </FlexDiv>
                            <FlexDiv column flexGrow={1}>
                                <img
                                    src={`/static/img/logo-azure-ad.svg`}
                                    alt="Azure logo"
                                />
                            </FlexDiv>
                        </FlexDiv>

                        {data.enabled === '0' ? (
                            <FlexDiv column fullWidth>
                                <TextField
                                    field={store._form.$('tenant')}
                                    minWidth={'350px'}
                                />
                                <TextField
                                    field={store._form.$('clientId')}
                                    minWidth={'350px'}
                                />
                                <TextField
                                    field={store._form.$('clientSecret')}
                                    minWidth={'350px'}
                                />
                                <FlexDiv justifyEnd mt={16}>
                                    {savingAzureData.includes(companyId) ? (
                                        <Spinner />
                                    ) : (
                                        <ThemedButton
                                            mode="primary"
                                            text="Connect Azure AD"
                                            onClick={store._form.onSubmit}
                                        />
                                    )}
                                </FlexDiv>
                            </FlexDiv>
                        ) : (
                            <>
                                <SpaceBetweenDiv alignEnd>
                                    {!(
                                        syncRunning ||
                                        (data && data.latestRunningSyncId)
                                    ) ? (
                                        <p>
                                            Last synced:{' '}
                                            {data && data.lastSync ? (
                                                <MuiLink
                                                    onClick={openDetailsDialog}
                                                >
                                                    <TextTiny
                                                        bold
                                                        link
                                                        color={
                                                            data.status === 3
                                                                ? palette.vibrant
                                                                    .raspberry
                                                                : palette.evergreen
                                                                    .aspargus
                                                        }
                                                    >
                                                        {moment(
                                                            data.lastSync
                                                        ).fromNow()}
                                                    </TextTiny>
                                                </MuiLink>
                                            ) : (
                                                'Never'
                                            )}
                                        </p>
                                    ) : null}
                                    {syncRunning ||
                                    (data && data.latestRunningSyncId) ? (
                                        <Box
                                            display="flex"
                                            width={300}
                                            flexDirection="column"
                                        >
                                            <TextTiny
                                                bold
                                                color={palette.evergreen.aspargus}
                                            >
                                                {data.message || 'Initializing'}
                                            </TextTiny>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Box width="100%" mr={1}>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={data.progress || 0}
                                                    />
                                                </Box>
                                                <Box minWidth={35}>
                                                    <TextTiny
                                                        bold
                                                        color={
                                                            palette.evergreen
                                                                .aspargus
                                                        }
                                                    >{`${(
                                                        data.progress || 0
                                                    ).toFixed(0)}%`}</TextTiny>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ) : (
                                        <ThemedButton
                                            mode="secondary"
                                            onClick={syncNow}
                                            text="Sync now"
                                        />
                                    )}
                                </SpaceBetweenDiv>
                                <Setting
                                    name="Client secret"
                                    value={data.clientSecret}
                                    actionName="Change"
                                    fullWidth={true}
                                    noPb={true}
                                    isLast={true}
                                    action={openEditModal}
                                />
                            </>


                        )}
                    </div>
                    {data.enabled === '1' ? (
                        <IntegrationDetailGroupSettings
                            data={data}
                            loadingGroups={loadingAzureGroups}
                            companyId={companyId}
                            product={'azure'}
                            savingData={savingAzureData}
                        />
                    ) : null}
                    {data.excludedUsers?.length > 0 && (
                        <IntegrationDetailExcludedUsers
                            companyId={companyId}
                            product={'azure'}
                            data={data.excludedUsers}
                        />
                    )}
                    {data.enabled === '1' ? (
                        <div className="integration-detail-block  align-left">
                            <Heading2 mb={16}>Unlink integration</Heading2>
                            <p
                                className={clsx({
                                    'margin-bottom': 1,
                                })}
                            >
                                By unlinking this integration you will not be
                                able to sync users from Active Directory. This
                                will affect all currently running training
                                programs.
                            </p>
                            <ThemedButton
                                mode="red"
                                size="fullWidth"
                                onClick={handleUnlink}
                                text="Unlink integration"
                            />
                        </div>
                    ) : null}
                </Col>

                <Col lg="4" xs="12">
                    <IntegrationDetailSidebar
                        href={integrationADUrl}
                        product="Azure AD"
                    />
                </Col>
            </Row>
            {detailsDialogOpen && (
                <Dialog
                    open={detailsDialogOpen}
                    onClose={closeDetailsDialog}
                    maxWidth={'sm'}
                    fullWidth
                >
                    <DialogTitle>Sync details</DialogTitle>
                    <DialogContent>
                        <FlexDiv pt={20} pb={20}>
                            <FlexDiv flexGrow={1}>
                                <TextLead bold lighter>
                                    Date
                                </TextLead>
                            </FlexDiv>
                            <FlexDiv column flexGrow={2} ml={32}>
                                <FlexDiv>
                                    <Heading2 mb={4}>
                                        <TextTiny bold>
                                            {moment(
                                                detailsDialogOpen.lastSync
                                            ).format('LLL')}
                                        </TextTiny>
                                    </Heading2>
                                    <FlexDiv ml={4} mr={8} link>
                                        <Dropdown
                                            isOpen={lastStatusesDropdown}
                                            toggle={toggleLastStatusesDropdown}
                                        >
                                            <DropdownToggle caret tag="span" />
                                            <DropdownMenu>
                                                {data &&
                                                    data.syncStatuses.map(
                                                        (x, i) => {
                                                            return (
                                                                <ThemedDropdownItem
                                                                    key={i}
                                                                    onClick={() => {
                                                                        setDetailsDialogOpen(
                                                                            x
                                                                        );
                                                                        toggleLastStatusesDropdown();
                                                                    }}
                                                                >
                                                                    <TextTiny
                                                                        bold={
                                                                            detailsDialogOpen.lastSyncId ===
                                                                            x.lastSyncId
                                                                        }
                                                                        color={
                                                                            x.status ===
                                                                            3
                                                                                ? palette
                                                                                      .vibrant
                                                                                      .raspberry
                                                                                : palette
                                                                                      .evergreen
                                                                                      .aspargus
                                                                        }
                                                                    >
                                                                        {moment(
                                                                            x.lastSync
                                                                        ).format(
                                                                            'LLL'
                                                                        )}
                                                                    </TextTiny>
                                                                </ThemedDropdownItem>
                                                            );
                                                        }
                                                    )}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </FlexDiv>
                                </FlexDiv>

                                {/*<TextTiny>{subtext}</TextTiny>*/}
                            </FlexDiv>
                        </FlexDiv>

                        <FlexDiv pt={20} pb={20}>
                            <FlexDiv flexGrow={1}>
                                <TextLead bold lighter>
                                    Status
                                </TextLead>
                            </FlexDiv>
                            <FlexDiv column flexGrow={2} ml={32}>
                                <TextTiny
                                    bold
                                    color={
                                        detailsDialogOpen.status === 3
                                            ? palette.vibrant.raspberry
                                            : palette.evergreen.aspargus
                                    }
                                >
                                    {detailsDialogOpen.status === 3
                                        ? 'Failed'
                                        : 'Success'}
                                </TextTiny>
                                {/*<TextTiny>{subtext}</TextTiny>*/}
                            </FlexDiv>
                        </FlexDiv>

                        {detailsDialogOpen.status === 3 && (
                            <FlexDiv pt={20} pb={20}>
                                <FlexDiv flexGrow={1}>
                                    <TextLead bold lighter>
                                        Error
                                    </TextLead>
                                </FlexDiv>
                                <FlexDiv column flexGrow={2} ml={32}>
                                    <TextTiny
                                        bold
                                        color={palette.vibrant.raspberry}
                                    >
                                        {detailsDialogOpen.errorDetails}
                                    </TextTiny>
                                    {/*<TextTiny>{subtext}</TextTiny>*/}
                                </FlexDiv>
                            </FlexDiv>
                        )}
                        {detailsDialogOpen.status === 2 && (
                            <>
                                <FlexDiv pt={20} pb={20}>
                                    <FlexDiv flexGrow={1}>
                                        <TextLead bold lighter>
                                            Created Groups
                                        </TextLead>
                                    </FlexDiv>
                                    <FlexDiv column flexGrow={2} ml={32}>
                                        <TextTiny bold>
                                            {detailsDialogOpen.createdGroups}
                                        </TextTiny>
                                        {/*<TextTiny>{subtext}</TextTiny>*/}
                                    </FlexDiv>
                                </FlexDiv>
                                <FlexDiv pt={20} pb={20}>
                                    <FlexDiv flexGrow={1}>
                                        <TextLead bold lighter>
                                            Created Users
                                        </TextLead>
                                    </FlexDiv>
                                    <FlexDiv column flexGrow={2} ml={32}>
                                        <TextTiny bold>
                                            {detailsDialogOpen.createdUsers}
                                        </TextTiny>
                                        {/*<TextTiny>{subtext}</TextTiny>*/}
                                    </FlexDiv>
                                </FlexDiv>
                                <FlexDiv pt={20} pb={20}>
                                    <FlexDiv flexGrow={1}>
                                        <TextLead bold lighter>
                                            Created Memberships
                                        </TextLead>
                                    </FlexDiv>
                                    <FlexDiv column flexGrow={2} ml={32}>
                                        <TextTiny bold>
                                            {
                                                detailsDialogOpen.createdMembership
                                            }
                                        </TextTiny>
                                        {/*<TextTiny>{subtext}</TextTiny>*/}
                                    </FlexDiv>
                                </FlexDiv>
                                <FlexDiv pt={20} pb={20}>
                                    <FlexDiv flexGrow={1}>
                                        <TextLead bold lighter>
                                            Removed Memberships
                                        </TextLead>
                                    </FlexDiv>
                                    <FlexDiv column flexGrow={2} ml={32}>
                                        <TextTiny bold>
                                            {
                                                detailsDialogOpen.removedMembership
                                            }
                                        </TextTiny>
                                        {/*<TextTiny>{subtext}</TextTiny>*/}
                                    </FlexDiv>
                                </FlexDiv>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <ThemedButton
                            mode="secondary"
                            text="Close"
                            onClick={closeDetailsDialog}
                        />
                    </DialogActions>
                </Dialog>
            )}
            <Modal
                isOpen={showEditCompanyModal}
                onRequestClose={closeEditModal}
                className={'Modal auto-size-modal'}
                ariaHideApp={false}
            >
                <CompanyGenericEditor
                    title={'Change client secret'}
                    fields={companyFields}
                    label={'Client secret'}
                    onSaved={closeEditModal}
                    noSameValueCheckFields={['azure.clientSecret']}
                    onRequestClose={closeEditModal}
                    afterSaveFn={doLoadData}
                />
            </Modal>
        </Fragment>
    );
}

export default observer(CompanyAzureAd);
