import RestService from './RestService';
import BouncedEmails from './BouncedEmailsService';

export default class Automations extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/automations`);
        } else super('/automations');
    }

    //Get latest ten trainings,
    listLatestTen() {
        return this.get(`${this.baseUrl}/latest-ten`);
    }

    //Get trainings for report
    listRecent() {
        return this.get(`${this.baseUrl}/for-report`);
    }

    eventUserStatus(automationId, eventId, email) {
        return this.get(
            `${
                this.baseUrl
            }/${automationId}/status/${eventId}/user/${encodeURIComponent(
                email
            )}`
        );
    }

    userStatus(automationId, email) {
        return this.get(
            `${this.baseUrl}/${automationId}/status2/user/${encodeURIComponent(
                email
            )}`
        );
    }

    chartMetrics(automationId) {
        return this.get(`${this.baseUrl}/${automationId}/chartMetrics`);
    }

    userTrainings(email) {
        return this.get(
            `${this.baseUrl}/user-trainings/${encodeURIComponent(email)}`
        );
    }

    resendEventMail(automationId, eventId, email) {
        return this.post(
            `${this.baseUrl}/${automationId}/status/${eventId}/resend`,
            { email }
        );
    }

    loadingRemindersCounter(automationId, reminderOption) {
        return this.post(`${this.baseUrl}/${automationId}/count-reminders`, {
            reminderOption,
        });
    }

    sendReminders(automationId, reminderOption) {
        return this.post(`${this.baseUrl}/${automationId}/reminders`, {
            reminderOption,
        });
    }

    saveDraft(data) {
        return this.post(`${this.baseUrl}/draft`, data);
    }

    updateDraft(data) {
        return this.put(`${this.baseUrl}/draft/${data.id}`, data);
    }

    switchSyncLists(automationId, disabled) {
        return this.put(`${this.baseUrl}/${automationId}/switchSyncLists`, {
            disabled,
        });
    }

    previewSyncLists(automationId) {
        return this.get(`${this.baseUrl}/${automationId}/previewSyncLists`);
    }

    syncUsers(automationId, data) {
        return this.post(`${this.baseUrl}/${automationId}/syncLists`, data);
    }

    sendPreview(data) {
        return this.post(`${this.baseUrl}/sendPreview`, data);
    }

    loadUsersDetails(automationId) {
        return this.get(`${this.baseUrl}/${automationId}/perUsersDetails`);
    }

    pauseAutomation(automationId) {
        return this.post(`${this.baseUrl}/${automationId}/pauseAutomation`);
    }

    resumeAutomation(automationId) {
        return this.post(`${this.baseUrl}/${automationId}/resumeAutomation`);
    }

    renameAutomation(automationId, data) {
        return this.put(`${this.baseUrl}/${automationId}/name`, data);
    }

    bouncedEmailsService(automationId) {
        return new BouncedEmails(this, automationId);
    }
}
