import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container } from 'reactstrap';
import AssessmentList from './AssessmentsListView/AssessmentList';
import AssessmentView from './AssessmentView/AssessmentView';
import { Helmet } from 'react-helmet';
import AssessmentCreate from './AssessmentCreate';
import { useStores } from '../../hooks/use-stores';
import NoItemsYet from '../../components/manager/no-items-yet';
import { FlexDiv, ThemedButton } from '@awarego/awarego-components';
import LinkIfNeed from '../../components/LinkIfNeed';
import ThreatAreaView from './AssessmentView/threatAreas/ThreatAreaView';
import RoleRoute from '../../hocs/RoleRoute';
import AutomationCreate from '../automations/AutomationCreate';
import { flatten } from 'lodash';
import AutomationCreateWrapper from '../automations/AutomationCreateWrapper';
import { USER_ROLE } from '../../utils/constants';

function RedirectToAssessment() {
    const { id } = useParams();

    return <Navigate to={`/assessment/${id}/view/recommendation/`}></Navigate>;
}

export default observer(({ companyId }) => {
    const { assessmentStore, brandingStore, store } = useStores();
    const {
        loadingThreatAreaDefinitions,
        demoAssessmentURL,
        editingAssessment,
    } = assessmentStore;
    const { supportEmail } = brandingStore;
    const {
        assessmentsUnavailable,
        assessmentsUpgradeAllowed,
        currentCompany,
        cmi5enabled,
    } = store;

    useEffect(() => {
        if (currentCompany)
            assessmentStore.loadThreatAreaDefinitions(
                currentCompany.company_id
            );
    }, [assessmentStore, currentCompany]);

    return (
        loadingThreatAreaDefinitions === false && (
            <Container className="manager-content">
                <Helmet>
                    <title>Assessment</title>
                </Helmet>
                {assessmentsUnavailable ? (
                    <NoItemsYet
                        img={'/static/img/assessment-locked.svg'}
                        imgAlt={'assessments are unavailable'}
                        title={
                            'Assessments are unavailable in your current plan'
                        }
                        text={
                            'Please upgrade your subscription to access the assessments.'
                        }
                        heading={'Assessments'}
                        buttons={
                            assessmentsUpgradeAllowed ? (
                                <FlexDiv gap={24}>
                                    <ThemedButton
                                        as="a"
                                        href={demoAssessmentURL}
                                        target="_blank"
                                        rel="noreferrer"
                                        mode="secondary"
                                        text="Try demo"
                                    />
                                    <LinkIfNeed link={'/subscriptions/upgrade'}>
                                        <ThemedButton
                                            mode="primary"
                                            text="Upgrade now"
                                        />
                                    </LinkIfNeed>
                                </FlexDiv>
                            ) : (
                                <FlexDiv gap={24}>
                                    <ThemedButton
                                        as="a"
                                        href={demoAssessmentURL}
                                        target="_blank"
                                        rel="noreferrer"
                                        mode="secondary"
                                        text="Try demo"
                                    />
                                    <ThemedButton
                                        mode="primary"
                                        as="a"
                                        href={`mailto:${supportEmail}`}
                                        text="Contact to upgrade"
                                    />
                                </FlexDiv>
                            )
                        }
                    />
                ) : (
                    <Routes>
                        <Route
                            path="edit/:id"
                            element={
                                <RoleRoute>
                                    <AssessmentCreate
                                        companyId={companyId}
                                        isScorm={false}
                                        parentPath={'/assessment'}
                                    />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path="create/copy/:fromId"
                            element={
                                <RoleRoute>
                                    <AssessmentCreate
                                        companyId={companyId}
                                        isScorm={false}
                                        parentPath={'/assessment'}
                                    />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path="create"
                            element={
                                <RoleRoute>
                                    <AssessmentCreate
                                        companyId={companyId}
                                        isScorm={false}
                                        parentPath={'/assessment'}
                                    />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={`:id/view/recommendation/create-training/:listId?`}
                            element={
                                <RoleRoute>
                                    {editingAssessment ? (
                                        <AutomationCreateWrapper
                                            editingAssessment={
                                                editingAssessment
                                            }
                                            companyId={companyId}
                                            parentPath={`/assessment/${
                                                editingAssessment.isScorm
                                                    ? 'p' + editingAssessment.id
                                                    : editingAssessment.id
                                            }/view/recommendation/`}
                                            customBackUrl={`/assessment/${
                                                editingAssessment.isScorm
                                                    ? 'p' + editingAssessment.id
                                                    : editingAssessment.id
                                            }/view/recommendation/`}
                                            recommendationMode={true}
                                        />
                                    ) : (
                                        <RedirectToAssessment />
                                    )}
                                </RoleRoute>
                            }
                        />
                        {cmi5enabled && (
                            <Route
                                path="create_scorm"
                                element={
                                    <RoleRoute>
                                        <AssessmentCreate
                                            parentPath={'/assessment'}
                                            companyId={companyId}
                                            isScorm={true}
                                        />
                                    </RoleRoute>
                                }
                            />
                        )}
                        <Route
                            path={`:id/view/recommendation/create-scorm`}
                            element={
                                <RoleRoute>
                                    {editingAssessment ? (
                                        <AutomationCreateWrapper
                                            editingAssessment={
                                                editingAssessment
                                            }
                                            companyId={companyId}
                                            parentPath={`/assessment/${
                                                editingAssessment.isScorm
                                                    ? 'p' + editingAssessment.id
                                                    : editingAssessment.id
                                            }/view/recommendation/`}
                                            customBackUrl={`/assessment/${
                                                editingAssessment.isScorm
                                                    ? 'p' + editingAssessment.id
                                                    : editingAssessment.id
                                            }/view/recommendation/`}
                                            recommendationMode={true}
                                            isScorm={true}
                                        />
                                    ) : (
                                        <RedirectToAssessment />
                                    )}
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={`:id/view/threatAreas/:threatAreaCode`}
                            element={<ThreatAreaView companyId={companyId} />}
                        />
                        <Route
                            path=":id/view/*"
                            element={
                                <RoleRoute requiredRole={USER_ROLE.VIEWER}>
                                    <AssessmentView companyId={companyId} />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path="active"
                            element={
                                <AssessmentList
                                    tab="active"
                                    companyId={companyId}
                                />
                            }
                        />
                        <Route
                            path="completed"
                            element={
                                <AssessmentList
                                    tab="completed"
                                    companyId={companyId}
                                />
                            }
                        />
                        <Route
                            path="draft"
                            element={
                                <RoleRoute>
                                    <AssessmentList
                                        tab="draft"
                                        companyId={companyId}
                                    />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path="*"
                            element={
                                <AssessmentList
                                    tab="all"
                                    companyId={companyId}
                                />
                            }
                        />
                    </Routes>
                )}
            </Container>
        )
    );
});
