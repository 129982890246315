import { makeAutoObservable, reaction, runInAction } from 'mobx';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import intersection from 'lodash/intersection';
import pick from 'lodash/pick';
import remove from 'lodash/remove';
import services from '../services';
import Automation from './models/automation';
import { extractErrorMessage } from '../utils/helpers';
import {
    AUTOMATION_SCHEDULE_TYPE,
    AUTOMATION_WIZARD_STEPS,
    STATUS,
} from '../utils/constants';
import moment from 'moment';
import { translateTrainingScheduleType } from '../utils/scheduleType';
import { getDeliveryChannels } from '../utils/deliveryChannel';
import { Buffer } from 'buffer';
import { generateLoadList, generateUpdateEntity } from '../utils/mobx';
import SelectionController from './SelectionController';

const activeStatuses = { [STATUS.SCHEDULED]: 1, [STATUS.ACTIVE]: 1 };
const completedStatuses = { [STATUS.COMPLETED]: 1 };

class AutomationStore {
    automations = [];
    latestTenAutomations = []; //Light-weight objects for the latest (10) updated trainings
    dashboardAutomations = [];
    loadingAutomations = false;
    loadingAutomationsInBackground = false;
    loadingAutomation = false;
    loadingAutomationStatus = false;
    loadingAutomationUserDetails = false;
    loadingCompanyData = false;
    loadingReminderCounter = false;

    reminderCounter = 0;
    lastReminders = [];
    sendAutomationEmails = [];
    perUserDetails = [];
    questions = [];
    rawTemplates = [];
    creatingAutomation = false;
    updatingAutomation = false;
    sendError = null;
    sendingPreview = false;
    automation = undefined;
    automationStatus = undefined;
    automationUserStatus = undefined;
    editingAutomation = undefined;
    loading = false;
    step = AUTOMATION_WIZARD_STEPS.GOAL;
    selectedTemplates = [];
    templateCustomized = false;
    allowedSteps = [];
    confirmedSteps = [];
    error = '';
    dismissedFirstTraining = false;
    perUserDetailsLoaded = false;
    slackConnected = false;
    teamsConnected = false;
    searchValue = '';
    subjectFilter = [];
    employeeFilter = [];
    fromEndDateFilter = null;
    toEndDateFilter = null;
    toStartDateFilter = null;
    fromStartDateFilter = null;
    sortBy = '_rawData.updated';

    //By user
    userAutomations = [];
    loadingUserAutomations = false;

    chartMetrics = [];
    chartMetricsLoading = false;

    chartMetricsMigrating = false;
    loadingPreviewSyncLists = null;

    constructor(
        listStore,
        catalogStore,
        mainStore,
        commonStore,
        companyStore,
        authStore,
        historyStore,
        brandingStore
    ) {
        makeAutoObservable(this);
        this.listStore = listStore;
        this.catalogStore = catalogStore;
        this.mainStore = mainStore;
        this.commonStore = commonStore;
        this.companyStore = companyStore;
        this.historyStore = historyStore;
        this.authStore = authStore;
        this.brandingStore = brandingStore;
        this.dismissedFirstTraining = !!window.localStorage.getItem(
            'dismissedFirstTraining'
        );

        this.previewUsersToAddSelection = new SelectionController(
            this.previewUsersToAddCurrentPage,
            this.toAddPageData,
            (x) => x.email,
            this.previewUsersToAdd.length
        );

        this.previewUsersToRemoveSelection = new SelectionController(
            this.previewUsersToRemoveCurrentPage,
            this.toRemovePageData,
            (x) => x.email,
            this.previewUsersToRemove.length
        );

        reaction(
            () => this.previewUsersToAddCurrentPage,
            (data) => {
                this.previewUsersToAddSelection.setData(
                    data,
                    this.previewUsersToAdd.length
                );
            }
        );

        reaction(
            () => this.previewUsersToRemoveCurrentPage,
            (data) => {
                this.previewUsersToRemoveSelection.setData(
                    data,
                    this.previewUsersToRemove.length
                );
            }
        );
    }

    get templates() {
        return this.rawTemplates.filter(
            (x) => !x.definition || !x.definition.isAddOn
        );
    }

    get addOns() {
        return this.rawTemplates.filter(
            (x) => x.definition && x.definition.isAddOn
        );
    }

    setError = (error) => {
        this.error = error;
    };

    get filteredAutomations() {
        let ordered = orderBy(this.automations, this.sortBy, 'desc');

        let filtered = ordered.filter((x) => {
            if (
                this.subjectFilter.length > 0 &&
                intersection(
                    Object.keys(keyBy(x.subjectsNames, 'id')).map(Number),
                    this.subjectFilter
                ).length === 0
            )
                return false;

            if (
                this.employeeFilter.length > 0 &&
                x.lists !== null &&
                intersection(x._rawData.lists, this.employeeFilter).length === 0
            )
                return false;

            if (
                this.searchValue.length > 0 &&
                x.name !== null &&
                !x.name.toLowerCase().includes(this.searchValue.toLowerCase())
            )
                return false;

            if (
                this.fromStartDateFilter !== null &&
                this.toStartDateFilter !== null &&
                x._rawData.firstBatch !== null &&
                !moment(x._rawData.firstBatch).isBetween(
                    this.fromStartDateFilter,
                    this.toStartDateFilter
                )
            ) {
                return false;
            }

            if (
                this.fromEndDateFilter !== null &&
                this.toEndDateFilter !== null &&
                x._rawData.lastBatch !== null &&
                !moment(x._rawData.lastBatch).isBetween(
                    this.fromEndDateFilter,
                    this.toEndDateFilter
                )
            ) {
                return false;
            }

            return true;
        });
        return filtered;
    }

    get allTab() {
        return this.filteredAutomations;
    }

    get activeTab() {
        return this.filteredAutomations.filter((x) => {
            return activeStatuses[x.status];
        });
    }

    get completedTab() {
        return this.filteredAutomations.filter((x) => {
            return completedStatuses[x.status];
        });
    }

    get draftTab() {
        return this.filteredAutomations.filter((x) => {
            return x.status === STATUS.DRAFT;
        });
    }

    get steps() {
        return [
            {
                number: AUTOMATION_WIZARD_STEPS.GOAL,
                title:
                    this.automation && this.automation.status === STATUS.ACTIVE
                        ? 'Template'
                        : 'Choose template',
            },
            {
                number: AUTOMATION_WIZARD_STEPS.CONTENT,
                title: 'Review Subjects',
            },
            ...(this.automation && this.automation.isScorm
                ? [
                      {
                          number: AUTOMATION_WIZARD_STEPS.LANGUAGES,
                          title: 'Available Languages',
                      },
                  ]
                : [
                      {
                          number: AUTOMATION_WIZARD_STEPS.EMPLOYEES,
                          title: 'Select Employees',
                      },
                      {
                          number: AUTOMATION_WIZARD_STEPS.SCHEDULE,
                          title: 'Schedule',
                      },
                      {
                          number: AUTOMATION_WIZARD_STEPS.REMINDERS,
                          title: 'Reminders',
                      },
                      {
                          number: AUTOMATION_WIZARD_STEPS.INTROMESSAGE,
                          title: 'Intro Message',
                      },
                  ]),
            {
                number: AUTOMATION_WIZARD_STEPS.FINALIZE,
                title: 'Summary',
            },
        ];
    }

    setSubjectFilter(subjectFilter) {
        this.subjectFilter = subjectFilter;
    }

    setSearchValue(searchValue) {
        this.searchValue = searchValue;
    }

    setEmployeeFilter(employeeFilter) {
        this.employeeFilter = employeeFilter;
    }

    setFromEndDateFilter(fromEndDateFilter) {
        this.fromEndDateFilter = fromEndDateFilter;
    }

    setToEndDateFilter(toEndDateFilter) {
        this.toEndDateFilter = toEndDateFilter;
    }

    setFromStartDateFilter(fromStartDateFilter) {
        this.fromStartDateFilter = fromStartDateFilter;
    }

    setToStartDateFilter(toStartDateFilter) {
        this.toStartDateFilter = toStartDateFilter;
    }

    setSortBy(sortBy) {
        this.sortBy = sortBy;
    }

    setStep(step) {
        this.error = '';
        this.step = step;
        this.commonStore.analyticsEvent(
            'Training-wizard',
            'step',
            `step-${step}`
        );
    }

    addStep(addStep, extraCondition = true) {
        if (!this.allowedSteps.includes(addStep) && extraCondition) {
            this.allowedSteps.push(addStep);
            return true;
        }
        return false;
    }

    removeStep(removeStep, extraCondition = true) {
        const removedSteps = remove(
            this.allowedSteps,
            (step) => step === removeStep && extraCondition
        );
        return removedSteps.length > 0;
    }

    get nextStepNumber() {
        let index = this.steps.findIndex((x) => x.number === this.step);
        return this.steps[index + 1]
            ? this.steps[index + 1].number
            : this.step + 1;
    }

    dismissFirstTraining() {
        this.dismissedFirstTraining = true;
        window.localStorage.setItem('dismissedFirstTraining', true);
    }

    get rawTemplatesIndex() {
        return keyBy(this.rawTemplates, 'id');
    }

    get selectedTemplatesData() {
        return this.selectedTemplates.map((x) => this.rawTemplatesIndex[x]);
    }

    setTemplate(templateId) {
        this.selectedTemplates = [templateId];
        let template = this.rawTemplates.find((x) => x.id === templateId);
        if (template) {
            this.automation.templateCustomized = false;
            this.commonStore.analyticsEvent(
                'Training-wizard',
                'choose-template',
                `template-${template.title}`
            );
            //A template contains both subjects and disabledSubjects
            this.automation.selectedVideosIds.replace(
                template.subjects.concat(template.disabledSubjects)
            );
            this.automation.setFrequency(template.frequency);
            this.automation.templateTip = template.tip;
        }

        if (this.selectedTemplates.length > 0) {
            this.addStep(AUTOMATION_WIZARD_STEPS.CONTENT);
        } else {
            this.removeStep(AUTOMATION_WIZARD_STEPS.CONTENT);
        }
    }

    toggleFinalStep(form) {
        if (this.automation && this.automation.isScorm) {
            this.addStep(
                AUTOMATION_WIZARD_STEPS.FINALIZE,
                this.allowedSteps.includes(AUTOMATION_WIZARD_STEPS.LANGUAGES)
            );
            this.removeStep(
                AUTOMATION_WIZARD_STEPS.FINALIZE,
                !this.allowedSteps.includes(AUTOMATION_WIZARD_STEPS.LANGUAGES)
            );
            return;
        }
        const enabled = this.automation && this.automation.introMessageEnabled;
        const messageEmpty =
            form &&
            (form.$('message').$value === '<p><br></p>' ||
                form.$('message').$value === '');

        if (
            this.allowedSteps.includes(AUTOMATION_WIZARD_STEPS.INTROMESSAGE) &&
            (!enabled || !messageEmpty)
        ) {
            this.addStep(AUTOMATION_WIZARD_STEPS.FINALIZE);
        } else {
            this.removeStep(AUTOMATION_WIZARD_STEPS.FINALIZE);
        }
    }

    toggleRemindersStep() {
        const dueDateIsOK = this.automation.dueDate
            ? moment(this.automation.dueDate).isAfter(
                  this.automation.scheduleType ===
                      AUTOMATION_SCHEDULE_TYPE.FUTURE
                      ? this.automation.deliverySchedule.length > 0
                          ? moment(
                                this.automation.deliverySchedule[
                                    this.automation.deliverySchedule.length - 1
                                ].date
                            )
                          : moment(this.automation.date)
                      : moment()
              )
            : true;

        if (
            (this.automation.scheduleType ===
                AUTOMATION_SCHEDULE_TYPE.DOWNLOAD_LINKS ||
                (this.automation.scheduleType ===
                    AUTOMATION_SCHEDULE_TYPE.FUTURE &&
                    this.automation.date !== null &&
                    dueDateIsOK &&
                    moment().isBefore(this.automation.date)) ||
                this.automation.scheduleType ===
                    AUTOMATION_SCHEDULE_TYPE.NOW) &&
            this.automation.channel !== 0 &&
            this.allowedSteps.includes(AUTOMATION_WIZARD_STEPS.SCHEDULE)
        ) {
            this.addStep(AUTOMATION_WIZARD_STEPS.REMINDERS);
        } else {
            this.removeStep(AUTOMATION_WIZARD_STEPS.REMINDERS);
        }
    }

    get reminderStepValid() {
        if (!this.automation) return false;

        if (!this.automation.reminders_enabled) return true;

        return (
            this.automation.reminders_steps.length > 0 &&
            this.automation.reminders_channel !== 0
        );
    }

    toggleIntroMessageStep() {
        if (
            this.reminderStepValid &&
            this.allowedSteps.includes(AUTOMATION_WIZARD_STEPS.REMINDERS)
        ) {
            this.addStep(AUTOMATION_WIZARD_STEPS.INTROMESSAGE);
        } else {
            this.removeStep(AUTOMATION_WIZARD_STEPS.INTROMESSAGE);
        }
    }

    toggleRecipientsStep() {
        if (this.automation && this.automation.selectedVideosIds.length > 0) {
            this.addStep(
                AUTOMATION_WIZARD_STEPS.LANGUAGES,
                this.automation.isScorm
            );
            this.addStep(
                AUTOMATION_WIZARD_STEPS.EMPLOYEES,
                !this.automation.isScorm
            );
        }

        if (
            !this.automation ||
            this.automation.selectedVideosIds.length === 0
        ) {
            this.removeStep(
                AUTOMATION_WIZARD_STEPS.LANGUAGES,
                this.automation.isScorm
            );
            this.removeStep(
                AUTOMATION_WIZARD_STEPS.EMPLOYEES,
                !this.automation.isScorm
            );
        }
    }

    toggleScheduleStep() {
        if (this.automation && this.automation.selectedUserCount > 0)
            this.addStep(AUTOMATION_WIZARD_STEPS.SCHEDULE);

        if (!this.automation || this.automation.selectedUserCount === 0) {
            this.removeStep(AUTOMATION_WIZARD_STEPS.SCHEDULE);
        }
    }

    async sendPreview(
        companyId,
        targetEmail,
        previewType,
        selectedEvents,
        introMessage
    ) {
        this.sendingPreview = true;
        this.sendError = '';
        try {
            const data = { targetEmail };
            if (previewType === 1) {
                data.previewSubjects = this.automation.deliverySchedule.map(
                    (x) => {
                        return {
                            index: x.tempId,
                            subjectIds: x.subjects.map(
                                (s) => s.subject_id || s.id
                            ),
                        };
                    }
                );
            } else {
                data.previewSubjects = this.automation.deliverySchedule
                    .filter((x) => selectedEvents.includes(x.tempId))
                    .map((x) => {
                        return {
                            index: x.tempId,
                            subjectIds: x.subjects.map(
                                (s) => s.subject_id || s.id
                            ),
                        };
                    });
            }
            if (this.automation.introMessageEnabled)
                data.introductoryData = introMessage;

            await services.Companies.automationsService(companyId).sendPreview(
                data
            );
            this.commonStore.analyticsEvent(
                'Training-wizard',
                'preview-sent',
                `company-${companyId}`
            );
            return true;
        } catch (e) {
            this.sendError = extractErrorMessage(e);
        } finally {
            this.sendingPreview = false;
        }
    }

    async loadRemindersCounter(companyId, automationId, reminderOption) {
        if (this.loadingReminderCounter) return;
        this.loadingReminderCounter = true;
        this.reminderCounter = null;
        this.lastReminders.replace([]);
        try {
            let result = await services.Companies.automationsService(
                companyId
            ).loadingRemindersCounter(automationId, reminderOption);
            this.reminderCounter = result.remindersCount;
            this.lastReminders.replace(result.lastReminders);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingReminderCounter = false;
        }
    }

    async sendReminders(companyId, automationId, reminderOption) {
        try {
            return await services.Companies.automationsService(
                companyId
            ).sendReminders(automationId, reminderOption);
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    async prepareWizard(
        companyId,
        copyFrom,
        editId,
        isScorm,
        initialData,
        recommendationMode = false,
        enabledLanguages = []
    ) {
        if (this.loadingCompanyData) return;
        this.loadingCompanyData = true;
        this.error = null;
        this.automations.clear();
        this.automation = null;
        this.isScorm = isScorm;
        this.selectedTemplates = [];
        try {
            await this.catalogStore.loadCatalog(companyId);
            let templates = await services.Companies.templatesService(
                companyId
            ).list();
            this.rawTemplates.replace(templates);
            let questions = await services.Companies.questionsService(
                companyId
            ).list();
            this.questions.replace(questions);

            await this.listStore.loadCompanyLists(companyId);
            await this.companyStore.loadIntegrationData(companyId, 'slack');
            await this.companyStore.loadIntegrationData(companyId, 'teams');
            let fromData;

            this.slackConnected =
                this.companyStore.slackDataIndex[companyId] &&
                this.companyStore.slackDataIndex[companyId].connected;
            this.teamsConnected =
                this.companyStore.teamsDataIndex[companyId] &&
                this.companyStore.teamsDataIndex[companyId].connected &&
                this.companyStore.teamsDataIndex[companyId].enabled === '1';

            if (copyFrom) {
                let fromAutomation =
                    await services.Companies.automationsService(
                        companyId
                    ).fetch(copyFrom);

                fromData = {
                    ...pick(
                        fromAutomation,
                        'definition',
                        'frequency',
                        'lists',
                        'events',
                        'reminders_enabled',
                        'reminders_settings',
                        'reminders_steps',
                        'scheduleType',
                        'subjects',
                        'template_id'
                    ),
                    name: fromAutomation.name + ' Copy',
                    channel:
                        fromAutomation.sendOptions === null
                            ? 0
                            : fromAutomation.sendOptions.channel || 0,
                    reminders_channel:
                        fromAutomation.reminders_settings === null
                            ? 0
                            : fromAutomation.reminders_settings.channel || 0,
                    id: null,
                    isCopy: true,
                };
                this.setStep(AUTOMATION_WIZARD_STEPS.CONTENT);
                if (isScorm) {
                    this.allowedSteps.replace([
                        AUTOMATION_WIZARD_STEPS.GOAL,
                        AUTOMATION_WIZARD_STEPS.CONTENT,
                        AUTOMATION_WIZARD_STEPS.LANGUAGES,
                        AUTOMATION_WIZARD_STEPS.FINALIZE,
                    ]);
                } else
                    this.allowedSteps.replace([
                        AUTOMATION_WIZARD_STEPS.GOAL,
                        AUTOMATION_WIZARD_STEPS.CONTENT,
                        AUTOMATION_WIZARD_STEPS.EMPLOYEES,
                        AUTOMATION_WIZARD_STEPS.SCHEDULE,
                        AUTOMATION_WIZARD_STEPS.REMINDERS,
                        AUTOMATION_WIZARD_STEPS.INTROMESSAGE,
                        AUTOMATION_WIZARD_STEPS.FINALIZE,
                    ]);
                this.selectedTemplates.replace([fromData.template_id]);
            } else if (editId) {
                fromData = await services.Companies.automationsService(
                    companyId
                ).fetch(editId);

                this.setStep(AUTOMATION_WIZARD_STEPS.GOAL);
                this.allowedSteps.replace([
                    AUTOMATION_WIZARD_STEPS.GOAL,
                    AUTOMATION_WIZARD_STEPS.CONTENT,
                    AUTOMATION_WIZARD_STEPS.EMPLOYEES,
                    AUTOMATION_WIZARD_STEPS.SCHEDULE,
                    AUTOMATION_WIZARD_STEPS.REMINDERS,
                    AUTOMATION_WIZARD_STEPS.INTROMESSAGE,
                    AUTOMATION_WIZARD_STEPS.LANGUAGES,
                    AUTOMATION_WIZARD_STEPS.FINALIZE,
                ]);
                this.selectedTemplates.replace([fromData.template_id]);
            } else if (initialData) {
                fromData = initialData;
                this.setStep(AUTOMATION_WIZARD_STEPS.SCHEDULE);
                this.allowedSteps.replace([
                    AUTOMATION_WIZARD_STEPS.GOAL,
                    AUTOMATION_WIZARD_STEPS.CONTENT,
                    AUTOMATION_WIZARD_STEPS.EMPLOYEES,
                    AUTOMATION_WIZARD_STEPS.SCHEDULE,
                ]);
                this.selectedTemplates.replace([-1]);
            } else {
                this.setStep(AUTOMATION_WIZARD_STEPS.GOAL);
                this.allowedSteps.replace([AUTOMATION_WIZARD_STEPS.GOAL]);
            }

            this.automation = new Automation(
                this.listStore,
                this.catalogStore,
                this.mainStore,
                companyId,
                this.questionsIndex,
                fromData,
                this.mainStore.isTrial,
                this.mainStore.havePartnerSubscription,
                null,
                enabledLanguages
            );
            this.automation.isScorm = isScorm;
            if (recommendationMode) {
                this.automation.subjectsPerInterval = 2;
                this.automation.name = 'Untitled Training';
            } else if (this.automation.subjectsPerInterval == null) {
                this.automation.subjectsPerInterval = 3;
            }

            if (recommendationMode && isScorm)
                this.setStep(AUTOMATION_WIZARD_STEPS.FINALIZE);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingCompanyData = false;
            this.commonStore.saveAnalyticsEvent(
                'training',
                'creation_started',
                {
                    sourcePage: this.historyStore.previousPath,
                }
            );
        }
    }

    async loadUserAutomations(companyId, email) {
        if (this.loadingUserAutomations || !email) return;
        this.loadingUserAutomations = true;
        try {
            const result = await services.Companies.automationsService(
                companyId
            ).userTrainings(email);

            this.userAutomations.replace(
                result.map(
                    (x) =>
                        new Automation(
                            this.listStore,
                            this.catalogStore,
                            this.mainStore,
                            companyId,
                            this.questionsIndex,
                            x,
                            this.mainStore.isTrial,
                            null,
                            email
                        )
                )
            );
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingUserAutomations = false;
        }
    }

    get usersLatestTraining() {
        if (!this.userAutomations || this.userAutomations < 1) return null;

        const ordered = this.userAutomations
            .slice()
            .filter(
                (training) =>
                    training.status !== STATUS.SCHEDULED &&
                    training.status !== STATUS.DRAFT &&
                    training._rawData.subjectsSent > 0
            )
            .sort(
                (a, b) =>
                    new Date(b._rawData.created || 0) -
                    new Date(a._rawData.created || 0)
            );
        return ordered[0];
    }

    async loadLatestTenAutomations(companyId) {
        if (this.loadingAutomations || this.loadingAutomationsInBackground)
            return;
        if (this.latestTenAutomations.length > 0)
            this.loadingAutomationsInBackground = true;
        else this.loadingAutomations = true;
        try {
            const result = await services.Companies.automationsService(
                companyId
            ).listLatestTen();

            this.latestTenAutomations.replace(
                result.map(
                    (x) =>
                        new Automation(
                            this.listStore,
                            this.catalogStore,
                            this.mainStore,
                            companyId,
                            this.questionsIndex,
                            x,
                            this.mainStore.isTrial
                        )
                )
            );
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingAutomations = false;
            this.loadingAutomationsInBackground = false;
        }
    }

    // d = show drafts (boolean), p = page number, n = number of rows per page
    async loadAutomations(companyId, d, p, n, isDashboard = false) {
        if (this.loadingAutomations || this.loadingAutomationsInBackground)
            return;
        if (!isDashboard && this.automations.length > 0)
            this.loadingAutomationsInBackground = true;
        else this.loadingAutomations = true;
        try {
            const result = await services.Companies.automationsService(
                companyId
            ).list({ d, p, n });

            let mappedAutomations = result.map(
                (x) =>
                    new Automation(
                        this.listStore,
                        this.catalogStore,
                        this.mainStore,
                        companyId,
                        this.questionsIndex,
                        x,
                        this.mainStore.isTrial
                    )
            );

            if (isDashboard) {
                this.dashboardAutomations.replace(mappedAutomations);
            } else {
                this.automations.replace(mappedAutomations);
            }
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingAutomations = false;
            this.loadingAutomationsInBackground = false;
        }
    }

    setChartMetric(data) {
        this.chartMetrics.replace(data);
        this.chartMetricsMigrating = false;
    }

    insertChartMetric(data) {
        if (this.chartMetricsMigrating) {
            return;
        }
        //try to find existing metric
        let existing = this.chartMetrics.find(
            (x) => x.lastTimestamp === data.lastTimestamp
        );
        if (!existing) {
            this.chartMetrics.push(data);
        } else {
            Object.assign(existing, data);
        }
    }

    loadAutomationChartMetrics = generateLoadList(
        'chartMetrics',
        this,
        'chartMetricsLoading',
        async (companyId, automationId) => {
            const result = await services.Companies.automationsService(
                companyId
            ).chartMetrics(automationId);
            if (result && result.migration) {
                runInAction(() => {
                    this.chartMetricsMigrating = true;
                });
                return [];
            } else return result;
        },
        'chartMetrics'
    );

    async loadAutomation(companyId, automationId) {
        if (this.loadingAutomation) return;
        this.loadingAutomation = true;
        this.error = null;
        this.chartMetrics.replace([]);
        this.chartMetricsMigrating = false;
        let companyChanged;
        try {
            await this.catalogStore.loadCatalog(companyId);

            let questions = await services.Companies.questionsService(
                companyId
            ).list();
            this.questions.replace(questions);

            await this.listStore.loadCompanyLists(companyId);

            const result = await services.Companies.automationsService(
                companyId
            ).fetch(automationId);

            this.commonStore.analyticsEvent(
                'Training',
                'load',
                `company-${companyId}-automation-${automationId}`
            );

            this.editingAutomation = new Automation(
                this.listStore,
                this.catalogStore,
                this.mainStore,
                companyId,
                this.questionsIndex,
                result,
                this.mainStore.isTrial
            );
            this.perUserDetails.replace([]);
            this.perUserDetailsLoaded = false;
            companyChanged = result.company_id !== companyId;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingAutomation = false;
        }
        return companyChanged;
    }

    async loadUsersDetails(companyId, automationId) {
        try {
            const result = await services.Companies.automationsService(
                companyId
            ).loadUsersDetails(automationId);
            this.perUserDetails.replace(result);
            this.perUserDetailsLoaded = true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    async resendEventMail(companyId, automationId, eventId, email) {
        if (this.sendAutomationEmails.includes(email)) return;
        this.sendAutomationEmails.push(email);
        try {
            await services.Companies.automationsService(
                companyId
            ).resendEventMail(automationId, eventId, email);
            this.commonStore.success('Reminder sent.');
            this.commonStore.analyticsEvent(
                'Training',
                'resend-email',
                `company-${companyId}-automation-${automationId}`
            );
            let user =
                this.automationStatus &&
                this.automationStatus.users &&
                this.automationStatus.users.find((x) => x.email === email);
            if (user) user.sent = true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.sendAutomationEmails.remove(email);
        }
    }

    async loadEventUserDetails(companyId, automationId, eventId, email) {
        if (this.loadingAutomationUserDetails) return;
        this.loadingAutomationUserDetails = true;
        try {
            this.automationUserStatus =
                await services.Companies.automationsService(
                    companyId
                ).eventUserStatus(automationId, eventId, email);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingAutomationUserDetails = false;
        }
    }

    async loadUserDetails(companyId, trainingId, email) {
        if (this.loadingAutomationUserDetails) return;
        this.loadingAutomationUserDetails = true;
        try {
            this.automationUserStatus =
                await services.Companies.automationsService(
                    companyId
                ).userStatus(trainingId, email);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingAutomationUserDetails = false;
        }
    }

    async delete(companyId, automationId) {
        if (this.deletingAutomation) return;
        this.deletingAutomation = true;
        try {
            await services.Companies.automationsService(companyId).delete(
                automationId
            );
            this.editingAutomation = null;
            this.automation = null;
            this.automations.replace(
                this.automations.filter((x) => x.id !== automationId)
            );
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.deletingAutomation = false;
        }
    }

    async startAutomation(companyId, extraData) {
        if (this.creatingAutomation) return;
        this.creatingAutomation = true;
        let result;

        try {
            if (this.automation.id) {
                result = await services.Companies.automationsService(
                    companyId
                ).update(
                    this.automation.toDTO({
                        ...extraData,
                        templateId: this.selectedTemplates,
                    })
                );
            } else {
                result = await services.Companies.automationsService(
                    companyId
                ).create(
                    this.automation.toDTO({
                        ...extraData,
                        templateId: this.selectedTemplates,
                    })
                );
            }

            this.commonStore.analyticsEvent(
                'trainings',
                'create',
                `company-${companyId}`
            );

            // 'await' intentionally missing
            this.sendAutomationCreatedEvent(result.automation.id);

            return result.automation;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.creatingAutomation = false;
        }
    }

    async saveDraft(companyId, extraData) {
        if (this.savingDraft) return;
        this.savingDraft = true;

        try {
            if (this.automation.id) {
                await services.Companies.automationsService(
                    companyId
                ).updateDraft(
                    this.automation.toDTO({
                        ...extraData,
                        templateId: this.selectedTemplates,
                    })
                );
            } else {
                let result = await services.Companies.automationsService(
                    companyId
                ).saveDraft(
                    this.automation.toDTO({
                        ...extraData,
                        templateId: this.selectedTemplates,
                    })
                );
                if (result.success) this.automation.setId(result.automation.id);
            }
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingDraft = false;
        }
    }

    async pauseAutomation(companyId, automationId, isAssesmentList) {
        if (this.updatingAutomation) return;
        this.updatingAutomation = true;
        try {
            await services.Companies.automationsService(
                companyId
            ).pauseAutomation(automationId);
            if (isAssesmentList) {
                let respectiveAutomation = this.automations.find(
                    (x) => x.id === automationId
                );
                respectiveAutomation.status = STATUS.STOPPED;
            } else {
                this.editingAutomation.status = STATUS.STOPPED;
            }
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.updatingAutomation = false;
        }
    }

    async resumeAutomation(companyId, automationId, isAssesmentList) {
        if (this.updatingAutomation) return;
        this.updatingAutomation = true;
        try {
            const { newStatus } = await services.Companies.automationsService(
                companyId
            ).resumeAutomation(automationId);
            if (isAssesmentList) {
                let respectiveAutomation = this.automations.find(
                    (x) => x.id === automationId
                );
                respectiveAutomation.status = newStatus;
            } else {
                this.editingAutomation.status = newStatus;
            }
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.updatingAutomation = false;
        }
    }

    get wizardActionButtonText() {
        if (this.step === AUTOMATION_WIZARD_STEPS.FINALIZE) {
            if (this.automation && this.automation.id) {
                if (this.automation.status === STATUS.DRAFT) return 'Activate';
                return 'Update';
            }
            return 'Create';
        }
        return 'Continue';
    }

    get perUserDetailsIndex() {
        if (!this.perUserDetailsLoaded) return null;

        return keyBy(this.perUserDetails, 'email');
    }

    get questionsIndex() {
        return keyBy(this.questions, 'question_id');
    }

    async sendAutomationCreatedEvent(trainingId) {
        await this.commonStore.saveAnalyticsEvent('training', 'created', {
            trainingId,
            totalSubjects: this.automation.selectedVideosIds.length,
            deliveryType: translateTrainingScheduleType(
                this.automation.scheduleType
            ),
            deliveryCadence: this.automation.interval,
            subjectDeliveryChannel: getDeliveryChannels(
                this.automation.channel
            ),
            subjectsPerInterval:
                parseInt(this.automation.subjectsPerInterval) || 0,
            totalReminders: this.automation.reminders_steps.length,
            totalEmployees: this.automation.selectedUserCount,
            employeeListIds: [...this.automation.selectedUserListsIds],
            programName: (() => {
                const selected =
                    this.selectedTemplates.length > 0
                        ? this.selectedTemplates[0]
                        : null;
                if (!selected) return '';
                const template = this.rawTemplates.find(
                    (t) => t.id === selected
                );
                return template ? template.title : '';
            })(),
            programId:
                this.selectedTemplates.length > 0
                    ? `${this.selectedTemplates[0]}`
                    : '',
            subjectIds: [...this.automation.selectedVideosIds],
            startDate: new Date(
                this.automation.deliverySchedule[0].date
            ).toISOString(),
            endDate: (() => {
                const endDate = new Date(
                    this.automation.deliverySchedule[
                        this.automation.deliverySchedule.length - 1
                    ].date
                );
                endDate.setDate(endDate.getDate() + 7);
                return endDate.toISOString();
            })(),
        });
    }

    async rename(companyId, automationId, data) {
        if (this.updatingAutomation) return;
        this.updatingAutomation = true;
        try {
            await services.Companies.automationsService(
                companyId
            ).renameAutomation(automationId, data);
            this.editingAutomation.name = data.name;
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.updatingAutomation = false;
        }
    }

    get demoTrainingURL() {
        if (!this.mainStore.currentCompany || !this.authStore.currentUser)
            return null;
        const reportTo = `"${this.authStore.currentUser.name}" <${this.authStore.currentUser.email}>`;
        return `${this.brandingStore.myDomain}/training/try-demo/${
            this.mainStore.currentCompany.company_uid
        }/${Buffer.from(reportTo, 'utf8').toString('base64')}`;
    }

    switchSyncLists = generateUpdateEntity(
        'switchSyncLists',
        this,
        'switchingSyncLists',
        async (companyId, automationId, desiredState) => {
            await services.Companies.automationsService(
                companyId
            ).switchSyncLists(automationId, desiredState);
            runInAction(() => {
                this.editingAutomation.disableSyncLists = desiredState;
            });
            return true;
        }
    );

    previewSyncLists = [];
    loadPreviewSyncLists = generateLoadList(
        'previewSyncLists',
        this,
        'loadingPreviewSyncLists',
        async (companyId, automationId) => {
            return await services.Companies.automationsService(
                companyId
            ).previewSyncLists(automationId);
        },
        'previewSyncLists'
    );

    syncUsers = generateUpdateEntity(
        'syncUsers',
        this,
        'syncingUsers',
        async (companyId, automationId) => {
            await services.Companies.automationsService(companyId).syncUsers(
                automationId,
                {
                    added: this.previewUsersToAddSelection.selectionContext,
                    removed:
                        this.previewUsersToRemoveSelection.selectionContext,
                }
            );
            return true;
        }
    );

    get previewUsersToAdd() {
        return this.previewSyncLists.filter((x) => x.added);
    }

    get previewUsersToRemove() {
        return this.previewSyncLists.filter((x) => x.removed);
    }

    toAddPageData = {
        pageIndex: 0,
        pageSize: 3,
    };

    toRemovePageData = {
        pageIndex: 0,
        pageSize: 3,
    };

    setToAddPageData(pageIndex, pageSize) {
        this.toAddPageData.pageIndex = pageIndex;
        this.toAddPageData.pageSize = pageSize;
    }

    setToRemovePageData(pageIndex, pageSize) {
        this.toRemovePageData.pageIndex = pageIndex;
        this.toRemovePageData.pageSize = pageSize;
    }

    setEnabledLanguages(languages) {
        this.automation.enabledLanguages = languages;
    }

    get previewUsersToAddCurrentPage() {
        const { pageIndex, pageSize } = this.toAddPageData;

        return this.previewUsersToAdd.slice(
            pageIndex * pageSize,
            pageIndex * pageSize + pageSize
        );
    }

    get previewUsersToRemoveCurrentPage() {
        const { pageIndex, pageSize } = this.toRemovePageData;

        return this.previewUsersToRemove.slice(
            pageIndex * pageSize,
            pageIndex * pageSize + pageSize
        );
    }
}

export default AutomationStore;
