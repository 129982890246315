import Form from 'mobx-react-form';
import * as validatorjs from 'validatorjs';

const plugins = {
    dvr: {
        package: validatorjs,
        extend: ($validator) => {
            var messages = $validator.getMessages('en');
            messages.required = 'You need to enter some information to save';
            $validator.setMessages('en', messages);
        },
    },
};

const fields = {
    trainingHeader: {
        label: 'Header in training emails',
        rules: 'string',
    },
    trainingFooter: {
        label: 'Footer in training emails',
        rules: 'string',
    },
    reminderHeader: {
        label: 'Header in reminder emails',
        rules: 'string',
    },
    reminderFooter: {
        label: 'Footer in reminder emails',
        rules: 'string',
    },
    assessmentHeader: {
        label: 'Header in assessment emails',
        rules: 'string',
    },
    assessmentFooter: {
        label: 'Footer in assessment emails',
        rules: 'string',
    },
    welcomeMessage: {
        label: 'User Welcome Enrollment emails',
        rules: 'string',
    },
    mrkdwn_trainingHeader: {},
    mrkdwn_trainingFooter: {},
    mrkdwn_reminderHeader: {},
    mrkdwn_reminderFooter: {},
    mrkdwn_assessmentHeader: {},
    mrkdwn_assessmentFooter: {},
    mrkdwn_welcomeMessage: {},
};

class EmailSettingsForm extends Form {
    constructor(setup, options) {
        super({ fields, ...setup }, { plugins, ...options });
    }
}

export default EmailSettingsForm;
