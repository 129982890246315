import React from 'react';
import { observer } from 'mobx-react';
import ErrorMessage from '../errormessage';

function GenericEdit ({ label, field, form, error }){
        return (
            <div className="create-campaign">
                <div className="form-holder">
                    <p>{label}</p>
                    <input
                        type="text"
                        name={field}
                        className={
                            form.$(field).isValid
                                ? 'bigInput valid'
                                : 'bigInput'
                        }
                        {...form.$(field).bind()}
                    />
                    <div className="error">{form.$(field).error}</div>
                </div>

                <ErrorMessage error={error} errorType="warning" />
            </div>
        );
}

export default observer(GenericEdit);
