import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import CompaniesAppearance from './CompanyAppearance';
import CompanySubscription from './CompanySubscription';
import CompaniesManagersContainer from './CompanyManagers';
import SettingSelect from '../settings/SettingSelect';
import { useStores } from '../../hooks/use-stores';
import {
    FlexDiv,
    SpaceBetweenDiv,
    Block,
    Heading2,
    TextTiny,
    BigModal,
    ThemedButton,
} from '@awarego/awarego-components';
import pluralize from 'pluralize';
import EditIcon from '@mui/icons-material/Edit';
import CompanyForm from '../../components/partner/CompanyForm';
import moment from 'moment';
import NotFound from '../../components/NotFound';
import SettingMultiSelect from '../settings/SettingMultiSelect';

function CompanyDetails() {
    const {
        store,
        partnerStore,
        companyStore,
        subscriptionStore,
        commonStore,
    } = useStores();
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [initiallyLoaded, setInitiallyLoaded] = useState(false);

    let navigate = useNavigate();

    const { editingCompany, loadingCompany, enabledLanguages } = partnerStore;
    const { languages } = store;
    const { companyForm } = subscriptionStore;
    const { companyId, partnerId } = useParams();
    const handleRenameCompany = async () => {
        subscriptionStore.initCompanyForm(
            partnerId,
            handleSubmitCompanyRename,
            editingCompany,
            true
        );
        setShowRenameModal(true);
    };

    const setEnabledLanguages = async (value) => {
        editingCompany?.languages.forEach((lang) => {
            lang.enabled = value.includes(lang.code) || lang.code === (editingCompany.defaultLanguage ?? 'en');
        });

        return new Promise((resolve) => {
            companyStore.saveLanguageSettings(editingCompany.company_id, editingCompany.languages, resolve);
        });
    };


    const handleSubmitCompanyRename = async () => {
        closeRenameModal();
        const newName = subscriptionStore.companyForm.values().companyName;
        const result = await companyStore.save(editingCompany.company_id, {
            name: newName,
        });
        if (result) {
            commonStore.success('Company renamed.');
            partnerStore.editingCompany.company_name = newName;
        } else {
            if (companyStore.error) {
                commonStore.error(companyStore.error);
            }
        }
    };

    const loadData = async () => {
        try {
            setInitiallyLoaded(true);
            await partnerStore.loadCompany(companyId, partnerId);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        void loadData();
    }, []);

    const visitDashboard = async () => {
        await store.setCurrentCompany(editingCompany.company_id);
        await navigate(`/`);
    };

    const closeRenameModal = () => {
        setShowRenameModal(false);
    };

    const createOptionsArray = (languages, enabledOnly) => {
        let optionsArray = [];
        if(enabledOnly) {
            languages = languages.filter((lang) => enabledLanguages?.includes(lang.code)) || [];
        }
        for (let i = 0; i < languages.length; i++) {
            optionsArray.push({
                key: languages[i].code,
                value: languages[i].code,
                name: languages[i].english_name,
                img: languages[i].flag_image,
            });
        }
        return optionsArray;
    };

    const onChangeLanguage = async (languageKey) => {
        editingCompany.defaultLanguage = languageKey;
        await partnerStore.update(editingCompany.company_id, {
            ...{ defaultLanguage: languageKey },
        });
    };

    if (initiallyLoaded && !loadingCompany && !editingCompany) {
        return <NotFound />;
    }

    return loadingCompany ? (
        <ThemedSpinner />
    ) : (
        editingCompany && (
            <Fragment>
                <Link
                    to={`/partner/${
                        editingCompany && editingCompany.partnerId
                    }`}
                >
                    <div className={'navigation back-button'}>
                        Back to Companies
                    </div>
                </Link>
                <Block>
                    <SpaceBetweenDiv>
                        <FlexDiv column>
                            <FlexDiv alignCenter mb={4}>
                                <Heading2>
                                    {editingCompany.company_name}
                                </Heading2>
                                <FlexDiv
                                    pl={8}
                                    onClick={handleRenameCompany}
                                    link
                                >
                                    <EditIcon color="primary" alt="pencil" />
                                </FlexDiv>
                            </FlexDiv>
                            <TextTiny lighter>
                                Joined on{' '}
                                {moment(editingCompany.company_created).format(
                                    'LL'
                                )}
                                {' · '}
                                {editingCompany.users_total}{' '}
                                {pluralize(
                                    'employee',
                                    editingCompany.users_total
                                )}
                                {' · '}{' '}
                                {editingCompany.status.trainings.active.value}{' '}
                                active training{' '}
                                {pluralize(
                                    'program',
                                    editingCompany.status.trainings.active.value
                                )}
                                {' · '}
                                {
                                    editingCompany.status.assessments.active
                                        .value
                                }{' '}
                                active{' '}
                                {pluralize(
                                    'assessment',
                                    editingCompany.status.assessments.active
                                        .value
                                )}
                            </TextTiny>
                        </FlexDiv>
                        <ThemedButton
                            mode="primary"
                            text="Go to dashboard"
                            onClick={() => visitDashboard()}
                        />
                    </SpaceBetweenDiv>
                </Block>
                <CompaniesManagersContainer company={editingCompany} />
                <CompanySubscription
                    company={editingCompany}
                    partnerId={editingCompany.partnerId}
                    loadCompany={loadData}
                />

                <Block>
                    <FlexDiv column>
                        <Heading2>Company Settings</Heading2>
                        <SettingSelect
                            name="Default language for employees"
                            options={createOptionsArray(languages, true)}
                            defaultValue={editingCompany.defaultLanguage || 'en'}
                            action={onChangeLanguage}
                            isPartnerCompanyDetails
                        />
                        <SettingMultiSelect
                            name="Available languages for employees"
                            options={createOptionsArray(languages)}
                            value={enabledLanguages}
                            action={(value) => (setEnabledLanguages(value))}
                            isPartnerCompanyDetails
                            isLast
                        />
                    </FlexDiv>
                </Block>

                <CompaniesAppearance company={editingCompany} />

                <BigModal
                    isOpen={showRenameModal}
                    onRequestClose={closeRenameModal}
                    heading="Rename company"
                    modalContent={<CompanyForm companyForm={companyForm} />}
                    modalFooterContent={
                        <Fragment>
                            <ThemedButton
                                mode="secondary"
                                onClick={() => closeRenameModal()}
                                text="Cancel"
                            />
                            <ThemedButton
                                mode="primary"
                                onClick={() => companyForm.submit()}
                                text="Save"
                            />
                        </Fragment>
                    }
                />
            </Fragment>
        )
    );
}

export default observer(CompanyDetails);
