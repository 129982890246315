import React, { Fragment } from 'react';
import {observer, useLocalObservable} from 'mobx-react';
import FormDef from '../../forms/company-generic-edit';
import GenericEdit from '../../components/company/company-generic';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { Heading1, ThemedButton } from '@awarego/awarego-components';
import {useStores} from "../../hooks/use-stores";


function GenericEditContainer ({
                                   title,
                                   fields = {},
                                   dataSource = {},
                                   noSameValueCheckFields = [],
                                   onRequestClose,
                                   onSaved,
                                   afterSaveFn,
                               }) {
    const { authStore, store } = useStores();

    const safeFields = {};
    Object.entries(fields).forEach(([originalKey, fieldConfig]) => {
        const safeKey = originalKey.replace(/\./g, '__setting__');


        if (noSameValueCheckFields.includes(originalKey)) {
            const oldValue = dataSource[originalKey] || '';
            const oldRules = fieldConfig.rules || 'string';
            fieldConfig = {
                ...fieldConfig,
                rules: `${oldRules}|noSameValue:${oldValue}`,
            };
        }

        safeFields[safeKey] = fieldConfig;
    });

    // 2) onSuccess: transform back and save
    const onSuccess = async () => {
        const values = localStore._form.values();
        const transformedValues = { generic_settings: {} };

        Object.keys(values).forEach(safeKey => {
            // Convert back to azure.clientSecret, azure.tenant, etc.
            const realKey = safeKey.replace(/__setting__/g, '.');
            transformedValues.generic_settings[realKey] = values[safeKey];
        });

        // Call store to save
        await store.saveCurrentCompany({
            ...transformedValues,
        });

        // Optional post-save side effects
        if (afterSaveFn) afterSaveFn();

        onSaved && onSaved(transformedValues);
    };

    // 3) Build the form
    const localStore = useLocalObservable(() => {
        const _form = new FormDef(
            safeFields,
            {}, // any additional “setup” here
            {
                hooks: { onSuccess },
            }
        );

        // Prefill data
        // e.g. dataSource => { "azure.clientSecret": "abc", "azure.tenant": "def" }
        // transform them with __setting__ so the form sees the correct keys
        const safeData = {};
        Object.entries(dataSource).forEach(([originalKey, value]) => {
            const safeKey = originalKey.replace(/\./g, '__setting__');
            safeData[safeKey] = value;
        });
        _form.update(safeData);

        return { _form };
    });

    const { error, savingCurrentCompany } = store;
    const { trialError } = authStore;

    return (
        <Fragment>
            <div className="modalHeader">
                <Heading1>{title}</Heading1>
                <img
                    className="modal-close-right"
                    src="/static/img/close.svg"
                    alt="Close"
                    onClick={onRequestClose}
                />
            </div>
            <div className="modalContent">
                {Object.keys(safeFields).map(safeKey => {
                    const realKey = safeKey.replace(/__setting__/g, '.');
                    const fieldConfig = fields[realKey] || {};
                    return (
                        <GenericEdit
                            key={safeKey}
                            form={localStore._form}
                            error={error || trialError}
                            field={safeKey}
                            label={fieldConfig.label}
                        />
                    );
                })}
            </div>
            <div className="modalFooter">
                {savingCurrentCompany ? (
                    <ThemedSpinner size={50} />
                ) : (
                    <ThemedButton
                        mode="secondary"
                        onClick={localStore._form.onSubmit}
                        text="Done"
                    />
                )}
            </div>
        </Fragment>
    );
}

export default observer(GenericEditContainer);

