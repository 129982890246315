import React from 'react';
import {
    FlexDiv,
    TextLead,
    TextTiny,
    TextWithTooltipIcon,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import Link from '@mui/material/Link';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default observer(
    ({
        name,
        value,
        subtext,
        actionName,
        action,
        helpText,
        helpId,
        isLast,
        fullWidth,
        noPb,
        noPt,
    }) => {
        return (
            <FlexDiv pt={noPt ? "0" : 20} pb={noPb ? "0" : 20} borderBottom={!isLast} fullWidth={fullWidth}>
                <FlexDiv flexGrow={1}>
                    <TextLead bold lighter>
                        {helpId ? (
                            <TextWithTooltipIcon
                                tooltipText={helpText}
                                textComponent={name}
                                icon={<HelpOutlineIcon />}
                            />
                        ) : (
                            name
                        )}
                    </TextLead>
                </FlexDiv>
                <FlexDiv column flexGrow={2} ml={32}>
                    <TextTiny bold>{value}</TextTiny>
                    <TextTiny>{subtext}</TextTiny>
                </FlexDiv>
                {actionName && action && (
                    <FlexDiv justifyEnd flexGrow={1}>
                        <Link onClick={action}>
                            <TextLead themed bold link>
                                {actionName}
                            </TextLead>
                        </Link>
                    </FlexDiv>
                )}
            </FlexDiv>
        );
    }
);
