import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import { useNavigate, useParams } from 'react-router-dom';
import classnames from 'classnames';
import {
    CardDeck,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Alert,
} from 'reactstrap';
import { removeNonBreakingSpaces } from 'content-editable-formatter';
import Sidebar from '../../components/manager/assessment/sidebar';
import KeyBehaviourCoverage from '../../components/manager/assessment/key-behaviour-coverage';
import WizardSummary from '../../components/manager/wizard/WizardSummary';
import QuestionsOrder from '../../components/manager/assessment/questions-order';
import AssessmentDistribution from '../../components/manager/assessment/distribution';
import AssessmentReminders from '../../components/manager/assessment/reminders';
import UsersSelectForm from '../../containers/users/components/select-users-step';
import PersonalizeForm from '../../components/manager/assessment/personalize-form';
import PreviewEmail from '../../components/manager/assessment/preview-email';
import IntroductoryForm from '../../components/manager/wizard/introductory-form';
import IntroductoryFormDef from '../../forms/introductoryForm';
import ErrorMessage from '../../components/errormessage';
import Modal from 'react-modal';
import Snackbar from '../../components/Snackbar';
import { Checkbox, FormControlLabel } from '@mui/material';
import {
    querystring,
    isNameUntitled,
    stripStringOfHtml,
} from '../../utils/helpers';
import ThemedLinkButton from '../../components/themed/ThemedLinkButton';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import pluralize from 'pluralize';
import { ASSESSMENT_WIZARD_STEPS, STATUS } from '../../utils/constants';
import './AssessmentCreate.scss';
import ListEditorModal from '../users/modals/ListEditorModal';
import ChangeName from '../../components/manager/wizard/change-name';
import PreviewQuestion from '../../components/manager/assessment/preview-question';
import SaveChanges from '../../components/manager/wizard/save-changes';
import moment from 'moment';

import WizardHeader from '../../components/manager/wizard/WizardHeader';
import SelectEmployees from '../../components/manager/wizard/select-employees';
import LockIcon from '@mui/icons-material/Lock';
import AlertWithIcon from '../../components/Alert';
import { TextTiny, Heading1, ThemedCard } from '@awarego/awarego-components';
import AddScenariosModal from '../../components/manager/assessment/AddScenariosModal';
import StartScreen from '../../components/manager/assessment/start-screen';
import WizardStepHeader from '../../components/manager/wizard/WizardStepHeader';
import ChangeDuration from '../../components/manager/wizard/change-duration';
import SettingMultiSelect from '../settings/SettingMultiSelect';

function AssessmentCreate({ companyId, parentPath, isScorm = false }) {
    const {
        assessmentStore,
        commonStore,
        listStore,
        userStore,
        store,
        authStore,
        brandingStore,
        cmi5Store,
    } = useStores();
    const [questionsModal, setQuestionsModal] = useState(false);
    const [recipientsModal, setRecipientsModal] = useState(false);
    const [addEmployeeModal, setAddEmployeeModal] = useState(false);
    const [previewQuestionsModal, setPreviewQuestionsModal] = useState(false);
    const [previewedQuestion, setPreviewedQuestion] = useState(false);
    const [createdAssessment, setCreatedAssessment] = useState(false);
    const [message, setMessage] = useState(null);
    const [messageShown, setMessageShown] = useState(false);
    const [messageType, setMessageType] = useState(null);
    const [nameChangeModal, setNameChangeModal] = useState(false);
    const [durationOverrideModal, setDurationOverrideModal] = useState(false);
    const [isDraft, setIsDraft] = useState(false);
    const [redirectLink, setRedirectLink] = useState(null);
    const [hasMoreQuestions, setHasMoreQuestions] = useState(false);
    const [saveChangesModal, setSaveChangesModal] = useState(false);
    const [emailPreview, setEmailPreview] = useState(false);
    const [area, setArea] = useState('all');
    const [behaviour, setBehaviour] = useState('all');

    const navigate = useNavigate();
    const { id: editId, fromId: copyFrom } = useParams();
    const back = querystring('back');

    const {
        assessment,
        loadingCompanyData,
        step,
        steps,
        allowedSteps,
        questions,
        slackConnected,
        teamsConnected,
        error,
        templates,
        selectedTemplates,
        sendError,
        maxByAreaAndBehaviourForAllQuestions,
        personalizeForm,
        threatAreaDefinitions,
        summaryOutput,
    } = assessmentStore;

    const { currentUser, timeZone } = authStore;
    const { listsWithUsers: lists, error: listError } = listStore;
    const { loadingUsers } = userStore;
    const {
        currentCompany,
        havePartnerSubscription,
        slackAvailable,
        teamsAvailable,
        assessmentManualScheduleEnabled,
        ssoEnabled,
        languages,
        enabledLanguages
    } = store;
    const { supportEmail, myDomain } = brandingStore;
    let createListDisabled = !currentCompany || !currentCompany.canAddUsers;
    const ongoing = assessment && assessment.status === STATUS.ACTIVE;

    const localStore = useLocalObservable(() => {
        let introductoryForm = new IntroductoryFormDef({}, {});

        introductoryForm.observe({
            path: 'message',
            key: 'value',
            call: ({ form }) => {
                form.submit();
                assessmentStore.toggleFinalStep(introductoryForm);
            },
        });

        return { introductoryForm };
    });

    const loadData = useCallback(async () => {
        await assessmentStore.prepareWizard(
            companyId,
            copyFrom,
            editId,
            isScorm,
            enabledLanguages
        );
        localStore.introductoryForm.reset();
        localStore.introductoryForm.showErrors(false);
    }, [
        assessmentStore,
        companyId,
        copyFrom,
        editId,
        isScorm,
        localStore.introductoryForm,
        enabledLanguages
    ]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const toggleQuestionSelected = (questionId) => {
        if (!assessment.templateCustomized) {
            assessmentStore.setTemplateAsCustom();
        }
        assessment.toggleQuestionSelected(questionId);
        assessmentStore.togglePersonalizeStep();
    };

    const sendPreview = async (targetEmail) => {
        let result = await assessmentStore.sendPreview(
            companyId,
            targetEmail,
            localStore.introductoryForm.values()
        );
        if (result) {
            hideEmailPreview();
            showSnackBar('Preview sent successfully.');
        }
    };

    const openEmailPreview = () => {
        setEmailPreview(true);
    };

    const hideEmailPreview = () => {
        setEmailPreview(false);
    };

    const openNameChangeModal = (isDraft, redirectLink) => {
        assessment.setName('');
        setSaveChangesModal(false);
        setNameChangeModal(true);
        setIsDraft(isDraft);
        setRedirectLink(redirectLink);
    };

    const hideNameChangeModal = () => {
        setNameChangeModal(false);
    };

    const openDurationOverrideModal = () => {
        setDurationOverrideModal(true);
    };

    const hideDurationOverrideModal = () => {
        setDurationOverrideModal(false);
    };

    const redirectToUpgrade = () => {
        commonStore.showConfirm(
            'Do you want to save this assessment as draft?',
            'Save as draft & continue',
            'Before you upgrade your subscription',
            () => {
                initiateSavingProgress(null, '/subscriptions/upgrade');
            }
        );
    };

    const initiateSavingProgress = (redirectTo) => {
        if (isNameUntitled(assessment.name, 'assessment'))
            return openNameChangeModal(true, redirectTo);
        return saveChanges(redirectTo);
    };

    const saveChanges = async (redirectTo = '/assessment') => {
        const isNew = assessment.id === null;
        let result = await assessmentStore.saveDraft(companyId, {
            introductoryData: localStore.introductoryForm.values(),
            personalizeData:
                assessment.personalizeEnabled && personalizeForm
                    ? personalizeForm.values()
                    : undefined,
        });

        if (result) {
            !isNew
                ? commonStore.success('Assessment updated!')
                : commonStore.success('Assessment draft saved!');
            navigate(redirectTo);
        } else {
            if (assessmentStore.error) {
                showSnackBar(assessmentStore.error, 'error');
            }
        }
    };

    const createOptionsArray = (languages, enabledOnly) => {
        let optionsArray = [];
        if(enabledOnly) {
            languages = languages.filter((lang) => enabledLanguages?.includes(lang.code)) || [];
        }
        for (let i = 0; i < languages.length; i++) {
            optionsArray.push({
                key: languages[i].code,
                value: languages[i].code,
                name: languages[i].english_name,
                img: languages[i].flag_image,
            });
        }
        return optionsArray;
    };

    const openSaveChangesModal = () => {
        if (isScorm) navigate(back || parentPath);
        else setSaveChangesModal(true);
    };

    const hideSaveChangesModal = () => {
        setSaveChangesModal(false);
    };

    const toggleUserListSelected = (listId) => {
        assessment.toggleUserListSelected(listId);
        assessmentStore.toggleScheduleStep();
    };

    const reorderList = (sourceIndex, targetIndex) => {
        assessment.reorderQuestionsList(sourceIndex, targetIndex);
    };

    const onChangeScheduleType = (value) => {
        assessment.setScheduleType(value);
        assessmentStore.toggleRemindersStep();
    };

    const onChangeDate = (value) => {
        assessment.setDate(value);
        assessmentStore.toggleRemindersStep();
    };

    const onChangeReminder = (value, i, reminder) => {
        if (
            assessment.status === STATUS.ACTIVE &&
            moment(value).isBefore(moment())
        ) {
            showSnackBar("Can't set a reminder to earlier than now!", 'error');
        } else {
            assessment.setReminder(value, i, reminder);
            assessmentStore.toggleIntroMessageStep();
        }
    };

    const onChangeRemindersEnabled = () => {
        assessment.setRemindersEnabled();
        assessmentStore.toggleIntroMessageStep();
    };

    const onDeleteReminderStep = (item) => {
        assessment.deleteReminderStep(item);
        assessmentStore.toggleIntroMessageStep();
    };

    const onAddReminderStep = () => {
        assessment.addReminderStep();
        assessmentStore.toggleIntroMessageStep();
    };

    const onChangeChannel = (event) => {
        if (
            !assessment.setChannel(
                Number(event.target.value),
                event.target.checked
            )
        ) {
            commonStore.warn('At least one channel needs to be selected');
        }
        assessmentStore.toggleRemindersStep();
    };

    const onChangeRemindersChannel = (event) => {
        if (
            !assessment.setRemindersChannel(
                Number(event.target.value),
                event.target.checked
            )
        ) {
            commonStore.warn('At least one channel needs to be selected');
        }
        assessmentStore.toggleIntroMessageStep();
    };

    const startAssessment = async () => {
        if (isScorm) {
            const dto = assessment.toDTO({
                templateIds: assessmentStore.selectedTemplates,
                type: 'assessment',
            });
            if (assessment.personalizeEnabled)
                dto.personalizeData = personalizeForm.values();

            const result = await cmi5Store.createPackage(companyId, dto);

            if (result) {
                assessmentStore.setStep(ASSESSMENT_WIZARD_STEPS.START);
                personalizeForm.reset();
                setCreatedAssessment(result);
            } else {
                if (cmi5Store.error) {
                    showSnackBar(cmi5Store.error, 'error');
                }
            }
        } else {
            await localStore.introductoryForm.validate();
            await localStore.introductoryForm.showErrors(true);

            if (
                !assessment.introMessageEnabled ||
                localStore.introductoryForm.isValid
            ) {
                let result = await assessmentStore.startAssessment(companyId, {
                    introductoryData: localStore.introductoryForm.values(),
                    personalizeData: assessment.personalizeEnabled
                        ? personalizeForm.values()
                        : undefined,
                });
                if (result) {
                    assessmentStore.setStep(ASSESSMENT_WIZARD_STEPS.START);
                    localStore.introductoryForm.reset();
                    setCreatedAssessment(result);
                } else {
                    if (assessmentStore.error) {
                        showSnackBar(assessmentStore.error, 'error');
                    }
                }
            }
        }
    };

    const stepChange = (step) => {
        assessmentStore.setStep(step);
    };

    const onChangeName = (e) => {
        assessment.setName(e.target.value);
    };

    const onChangeDuration = (minutes) => {
        assessment.setDurationOverride(minutes * 60);
    };

    const getOverrideDurationInMinutes = () => {
        return (
            assessment &&
            assessment.overrideDuration &&
            Math.ceil(assessment.overrideDuration / 60)
        );
    };

    const onChangeDueDate = (value) => {
        assessment.setDueDate(value);
        assessmentStore.toggleRemindersStep();
    };

    const toggleIntroMessage = () => {
        assessment.toggleIntroMessage();
        assessmentStore.toggleFinalStep(localStore.introductoryForm);
    };

    const selectTemplate = (template) => {
        assessmentStore.setTemplate(template.id);
    };

    const nextStep = () => {
        if (assessmentStore.step === ASSESSMENT_WIZARD_STEPS.PERSONALIZE) {
            assessment.personalizedKeys.map((pk) =>
                window.localStorage.setItem(
                    `${pk}-${store.currentCompany.company_id}`,
                    personalizeForm.$(pk).value
                )
            );
        }

        if (assessmentStore.step === ASSESSMENT_WIZARD_STEPS.FINALIZE)
            if (
                assessment.name === 'Untitled Assessment' ||
                assessment.name === '' ||
                assessment.name == null
            ) {
                openNameChangeModal(false);
            } else {
                startAssessment();
            }
        else {
            const nextStep = assessmentStore.nextStepNumber;
            assessmentStore.setStep(nextStep);
            assessmentStore.togglePersonalizeStep();
            assessmentStore.toggleRecipientsStep(personalizeForm);
            assessmentStore.toggleRemindersStep();
            assessmentStore.toggleIntroMessageStep();
            assessmentStore.toggleFinalStep(localStore.introductoryForm);
        }
    };

    const prevStep = () => {
        let index = assessmentStore.steps.findIndex(
            (x) => x.number === assessmentStore.step
        );
        let prevStep = assessmentStore.steps[index - 1]
            ? assessmentStore.steps[index - 1].number
            : assessmentStore.step - 1;

        assessmentStore.setStep(prevStep);
    };

    const showQuestionsModal = () => {
        setQuestionsModal(true);
    };

    const hideQuestionsModal = () => {
        setQuestionsModal(false);
    };

    const showRecipientsModal = () => {
        setRecipientsModal(true);
    };

    const hideRecipientsModal = () => {
        setRecipientsModal(false);
    };

    const showPreviewQuestionsModal = (questionId, type) => {
        setPreviewQuestionsModal(true);
        setPreviewedQuestion(questionId);
        setHasMoreQuestions(type);
    };

    const hidePreviewQuestionsModal = () => {
        setPreviewQuestionsModal(false);
    };

    const showAddEmployeeModal = () => {
        setAddEmployeeModal(true);
    };

    const hideAddEmployeeModal = () => {
        setAddEmployeeModal(false);
    };

    const createRecipientsList = async (list) => {
        list.users = list.users.filter((x) => x.email);
        let result = await listStore.save(companyId, null, list);
        if (result) {
            hideRecipientsModal();
            toggleUserListSelected(result);
            if (!addEmployeeModal) showAddEmployeeModal();
        }
    };

    const requestUsersForList = (listId) => {
        userStore.loadUsers(companyId, listId, true);
    };

    const showSnackBar = (message, messageType = 'success') => {
        setMessage(message);
        setMessageShown(true);
        setMessageType(messageType);
    };

    const closeSnackbar = () => {
        setMessage(null);
        setMessageShown(false);
        setMessageType(null);
    };

    const getTemplateQuestionsStats = (template) => {
        if (!template) return null;

        const questionCount =
            template.questions.length + template.disabledQuestions.length;
        if (questionCount === 0) return null;

        const text = `${questionCount} questions · ${
            template.verboseDuration
        } ${pluralize('minute', template.verboseDuration)}`;

        return text;
    };

    const getTemplateQuestionsAvailabilityElement = (template) => {
        if (!template) return null;
        if (
            !template.disabledQuestions ||
            template.disabledQuestions.length === 0
        ) {
            return null;
        }

        const text = `${template.disabledQuestions.length}/${
            template.questions.length + template.disabledQuestions.length
        } questions unavailable in your plan`;

        return (
            <TextTiny bold error>
                <LockIcon fontSize={'inherit'} /> {text}
            </TextTiny>
        );
    };

    const getReviewQuestionsAvailabilityElement = () => {
        const selectedQuestions = assessment.questionsSelected;

        if (!selectedQuestions) return null;

        const disabledQuestionsCount =
            assessment.unavailableQuestionsSelected &&
            assessment.unavailableQuestionsSelected.length;

        if (disabledQuestionsCount === 0) return null;

        const count = `${disabledQuestionsCount}/${selectedQuestions.length}`;

        return havePartnerSubscription ? (
            <AlertWithIcon severity="error">
                {count} selected questions are unavailable in your plan. Please{' '}
                contact <a href={`mailto:${supportEmail}`}>{supportEmail}</a> to
                upgrade or remove unavailable.
            </AlertWithIcon>
        ) : (
            <AlertWithIcon severity="error">
                {count} selected questions are unavailable in your plan. Please{' '}
                <u style={{ cursor: 'pointer' }} onClick={redirectToUpgrade}>
                    upgrade
                </u>
                {/* or <a href="#">remove unavailable</a> */}.
            </AlertWithIcon>
        );
    };

    const onDownloadPackage = (id, uniqueId, type) => {
        cmi5Store.downloadZIP(companyId, id, uniqueId, type);
    };

    return (
        <Fragment>
            <ErrorMessage error={error} />
            {loadingCompanyData || !assessment ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    {assessment.status === STATUS.COMPLETED ? (
                        "You can't edit an already completed assessment"
                    ) : currentCompany && currentCompany.canCreateTraining ? (
                        <div className={'training-wizard'}>
                            <WizardHeader
                                selectedTemplate={selectedTemplates}
                                isStartStep={
                                    step === ASSESSMENT_WIZARD_STEPS.START
                                }
                                openSaveChangesModal={openSaveChangesModal}
                                item={assessment}
                                onChangeName={onChangeName}
                                itemString="assessment"
                                initiateSavingProgress={initiateSavingProgress}
                                isNameUntitled={isNameUntitled(
                                    assessment.name,
                                    'assessment'
                                )}
                                draftAllowed={!isScorm}
                            />
                            {assessment.id !== null &&
                                (assessment.status === STATUS.SCHEDULED ||
                                    ongoing) && (
                                    <div className="assessment-wizard-info-message">
                                        <span style={{ fontWeight: 700 }}>
                                            Edit assessment&nbsp;
                                        </span>{' '}
                                        {ongoing &&
                                            '– Some parts of the assessment can not be modified as employees have already received questions.'}
                                    </div>
                                )}
                            <div className={'training-wizard-body'}>
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    autoHideDuration={15000}
                                    variant={messageType}
                                    message={message || ''}
                                    open={messageShown}
                                    onClose={closeSnackbar}
                                />
                                {step !== ASSESSMENT_WIZARD_STEPS.START && (
                                    <Sidebar
                                        steps={steps}
                                        activeStep={step}
                                        allowedSteps={allowedSteps}
                                        onStepChange={stepChange}
                                        warningSteps={[
                                            assessment.hasUnavailableQuestions
                                                ? ASSESSMENT_WIZARD_STEPS.CONTENT
                                                : null,
                                        ]}
                                        // extraInfo={assessment.id && (ongoing)}
                                    />
                                )}
                                <div
                                    className={classnames(
                                        'training-wizard-content',
                                        {
                                            'expand-content':
                                                step ===
                                                ASSESSMENT_WIZARD_STEPS.START,
                                        }
                                    )}
                                >
                                    {step === ASSESSMENT_WIZARD_STEPS.GOAL && (
                                        <Fragment>
                                            <WizardStepHeader
                                                title={
                                                    assessment.id !== null &&
                                                    ongoing
                                                        ? 'Selected template for your assessment'
                                                        : 'Set a template for your assessment'
                                                }
                                                subtitle={
                                                    assessment.id !== null &&
                                                    ongoing
                                                        ? 'This is a template set for this assessment.'
                                                        : 'Use one of our ready-made set of questions. You can always add additional questions.'
                                                }
                                            />
                                            <br />
                                            <CardDeck
                                                className={'training-templates'}
                                            >
                                                {(ongoing
                                                    ? templates.filter((t) =>
                                                          selectedTemplates.includes(
                                                              t.id
                                                          )
                                                      )
                                                    : templates
                                                ).map((t) =>
                                                    t.hidden ||
                                                    !t.enabled ? null : (
                                                        <ThemedCard
                                                            key={t.id}
                                                            outline
                                                            disabled
                                                            {...{
                                                                selected:
                                                                    selectedTemplates.includes(
                                                                        t.id
                                                                    ),
                                                            }}
                                                            className={classnames(
                                                                'training-template',
                                                                {
                                                                    disabled:
                                                                        !t.enabled,
                                                                },
                                                                `training-template-${t.id}`
                                                            )}
                                                            onClick={() => {
                                                                if (
                                                                    t.enabled &&
                                                                    !ongoing
                                                                )
                                                                    selectTemplate(
                                                                        t
                                                                    );
                                                            }}
                                                        >
                                                            <CardBody>
                                                                <CardTitle>
                                                                    <Heading1
                                                                        displayBlock
                                                                        mb={8}
                                                                    >
                                                                        {
                                                                            t.title
                                                                        }
                                                                    </Heading1>
                                                                </CardTitle>
                                                                <CardText>
                                                                    {
                                                                        t.description
                                                                    }
                                                                </CardText>
                                                                <CardSubtitle>
                                                                    <span>
                                                                        {getTemplateQuestionsStats(
                                                                            t
                                                                        )}
                                                                    </span>
                                                                </CardSubtitle>
                                                                {getTemplateQuestionsAvailabilityElement(
                                                                    t
                                                                )}
                                                            </CardBody>
                                                        </ThemedCard>
                                                    )
                                                )}
                                            </CardDeck>
                                        </Fragment>
                                    )}
                                    {step ===
                                        ASSESSMENT_WIZARD_STEPS.CONTENT && (
                                        <Fragment>
                                            <WizardStepHeader
                                                title={'Review scenarios'}
                                                subtitle={
                                                    ongoing
                                                        ? 'You cannot modify scenarios since at least some were already sent to people.'
                                                        : 'You can edit or add more scenarios to this set of scenarios.'
                                                }
                                                summary={`${
                                                    assessment.questionsSelected
                                                        .length
                                                } ${pluralize(
                                                    'scenario',
                                                    assessment.questionsSelected
                                                        .length
                                                )} · ${
                                                    assessment.selectedQuestionsDuration
                                                } ${pluralize(
                                                    'minute',
                                                    assessment.selectedQuestionsDuration
                                                )}`}
                                                buttonText={
                                                    !ongoing && 'Add scenarios'
                                                }
                                                onButtonClick={
                                                    showQuestionsModal
                                                }
                                            />

                                            {getReviewQuestionsAvailabilityElement()}

                                            <QuestionsOrder
                                                questions={
                                                    assessment.questionsSelected
                                                }
                                                onRemoveQuestion={
                                                    toggleQuestionSelected
                                                }
                                                onReorderList={reorderList}
                                                showPreviewQuestionsModal={
                                                    showPreviewQuestionsModal
                                                }
                                                ongoing={ongoing}
                                            />
                                        </Fragment>
                                    )}
                                    {step ===
                                        ASSESSMENT_WIZARD_STEPS.PERSONALIZE && (
                                        <Fragment>
                                            <WizardStepHeader
                                                title={
                                                    'Personalize to make it relatable'
                                                }
                                                subtitle={
                                                    'We use this information to make the assessment scenarios more realistic an relatable.'
                                                }
                                            />
                                            <PersonalizeForm
                                                form={personalizeForm}
                                                editable={
                                                    assessment.personalizeEnabled
                                                }
                                                ongoing={ongoing}
                                                personalizedKeys={
                                                    assessment.personalizedKeys
                                                }
                                            />
                                        </Fragment>
                                    )}

                                    {step ===
                                        ASSESSMENT_WIZARD_STEPS.EMPLOYEES && (
                                        <SelectEmployees
                                            itemString="assessment"
                                            item={assessment}
                                            showRecipientsModal={
                                                showRecipientsModal
                                            }
                                            showAddEmployeeModal={
                                                showAddEmployeeModal
                                            }
                                            lists={lists}
                                            toggleUserListSelected={
                                                toggleUserListSelected
                                            }
                                            ongoing={ongoing}
                                            redirectToUpgrade={
                                                redirectToUpgrade
                                            }
                                        />
                                    )}

                                    {step ===
                                        ASSESSMENT_WIZARD_STEPS.SCHEDULE && (
                                        <Fragment>
                                            <WizardStepHeader
                                                title={'Schedule'}
                                                subtitle={
                                                    ongoing
                                                        ? 'Modify delivery channels and due date for this assessment.'
                                                        : 'Set the time of delivery and due date for this assessment.'
                                                }
                                            />
                                            <AssessmentDistribution
                                                ongoing={ongoing}
                                                scheduledDate={assessment.date}
                                                scheduleType={
                                                    assessment.scheduleType
                                                }
                                                channel={assessment.channel}
                                                slackAvailable={slackAvailable}
                                                slackConnected={slackConnected}
                                                teamsAvailable={teamsAvailable}
                                                teamsConnected={teamsConnected}
                                                dueDate={assessment.due}
                                                onChangeScheduleType={
                                                    onChangeScheduleType
                                                }
                                                onChangeChannel={
                                                    onChangeChannel
                                                }
                                                onChangeDate={onChangeDate}
                                                onChangeDueDate={
                                                    onChangeDueDate
                                                }
                                                manualEnabled={
                                                    assessmentManualScheduleEnabled
                                                }
                                                adminTimeZone={timeZone}
                                            />
                                            <br />
                                        </Fragment>
                                    )}

                                    {step ===
                                        ASSESSMENT_WIZARD_STEPS.REMINDERS && (
                                        <Fragment>
                                            <WizardStepHeader
                                                title={'Reminders (optional)'}
                                                subtitle={
                                                    'Encourage your employees to complete the assessment by setting automatic reminders'
                                                }
                                            />

                                            <AssessmentReminders
                                                reminders_enabled={
                                                    assessment.reminders_enabled
                                                }
                                                reminders_steps={
                                                    assessment.reminders_steps
                                                }
                                                channel={
                                                    assessment.reminders_channel
                                                }
                                                slackAvailable={slackAvailable}
                                                slackConnected={slackConnected}
                                                teamsAvailable={teamsAvailable}
                                                teamsConnected={teamsConnected}
                                                onChangeRemindersEnabled={
                                                    onChangeRemindersEnabled
                                                }
                                                onChangeReminder={
                                                    onChangeReminder
                                                }
                                                onChangeChannel={
                                                    onChangeRemindersChannel
                                                }
                                                onAddReminderStep={
                                                    onAddReminderStep
                                                }
                                                onDeleteReminderStep={
                                                    onDeleteReminderStep
                                                }
                                                scheduledDate={assessment.date}
                                                dueDate={assessment.due}
                                                adminTimeZone={timeZone}
                                            />
                                            <br />
                                        </Fragment>
                                    )}

                                    {step ===
                                        ASSESSMENT_WIZARD_STEPS.INTROMESSAGE && (
                                        <Fragment>
                                            <WizardStepHeader
                                                title={
                                                    'Intro message (optional)'
                                                }
                                                subtitle={
                                                    'Add a personal message and introduce this assessment to your recipients.'
                                                }
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            assessment.introMessageEnabled
                                                        }
                                                        onChange={
                                                            toggleIntroMessage
                                                        }
                                                        name="introMessage"
                                                        color="primary"
                                                    />
                                                }
                                                label="Include introductory message"
                                            />
                                            <div>
                                                {assessment.introMessageEnabled && (
                                                    <IntroductoryForm
                                                        form={
                                                            localStore.introductoryForm
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <br />
                                        </Fragment>
                                    )}
                                    {step ===
                                        ASSESSMENT_WIZARD_STEPS.LANGUAGES && (
                                            <Fragment>
                                                <WizardStepHeader
                                                    title={
                                                        'Available languages (optional)'
                                                    }
                                                    subtitle={
                                                        'Select the languages you want to make this assessment available in.'
                                                    }
                                                />
                                                <SettingMultiSelect
                                                    name="Languages"
                                                    options={createOptionsArray(languages)}
                                                    value={assessment.enabledLanguages}
                                                    noSavedMark={true}
                                                    py={10}
                                                    textLead={true}
                                                    action={(value) => (assessmentStore.setEnabledLanguages(value))}
                                                />
                                            </Fragment>
                                        )}
                                    {step ===
                                        ASSESSMENT_WIZARD_STEPS.FINALIZE && (
                                        <Fragment>
                                            <WizardStepHeader
                                                title={'Finalize Assessment'}
                                                subtitle={
                                                    ongoing
                                                        ? 'Check out the summary before applying changes.'
                                                        : 'Check out the summary before you create the assessment.'
                                                }
                                            />

                                            {getReviewQuestionsAvailabilityElement()}

                                            <WizardSummary
                                                summaryOutput={summaryOutput}
                                                assessment={assessment}
                                                ongoing={ongoing}
                                                onChangeDuration={
                                                    openDurationOverrideModal
                                                }
                                                overrideDurationInMinutes={getOverrideDurationInMinutes()}
                                            />
                                            <br />
                                        </Fragment>
                                    )}

                                    {step === ASSESSMENT_WIZARD_STEPS.START && (
                                        <StartScreen
                                            createdAssessment={
                                                createdAssessment
                                            }
                                            isScorm={isScorm}
                                            assessment={assessment}
                                            ongoing={ongoing}
                                            myDomain={myDomain}
                                            ssoEnabled={ssoEnabled}
                                            onDownloadPackage={
                                                onDownloadPackage
                                            }
                                            isPackageDownloading={
                                                cmi5Store.isZIPDownloading
                                            }
                                        />
                                    )}
                                </div>
                                {step !== ASSESSMENT_WIZARD_STEPS.START && (
                                    <KeyBehaviourCoverage
                                        questionsSelected={
                                            assessment.questionsSelected
                                        }
                                        maxByAreaAndBehaviourForSelectedQuestions={
                                            assessment.maxByAreaAndBehaviourForSelectedQuestions
                                        }
                                        maxByAreaAndBehaviourForAllQuestions={
                                            maxByAreaAndBehaviourForAllQuestions
                                        }
                                        visibility={
                                            step ===
                                            ASSESSMENT_WIZARD_STEPS.CONTENT
                                        }
                                        behaviours={threatAreaDefinitions}
                                    />
                                )}
                            </div>
                            {step !== ASSESSMENT_WIZARD_STEPS.START && (
                                <div className={'training-wizard-footer'}>
                                    <div className={'actions'}>
                                        {step !==
                                            ASSESSMENT_WIZARD_STEPS.GOAL &&
                                            step !==
                                                ASSESSMENT_WIZARD_STEPS.START && (
                                                <span
                                                    className="previous-btn"
                                                    onClick={prevStep}
                                                >
                                                    Previous
                                                </span>
                                            )}
                                        <div className="actions-align-right">
                                            {!isScorm &&
                                                step ===
                                                    ASSESSMENT_WIZARD_STEPS.FINALIZE && (
                                                    <ThemedLinkButton
                                                        mode="secondary"
                                                        footer
                                                        onClick={
                                                            openEmailPreview
                                                        }
                                                        disabled={
                                                            assessment.hasUnavailableQuestions
                                                        }
                                                        text="Preview Emails"
                                                    ></ThemedLinkButton>
                                                )}

                                            <ThemedLinkButton
                                                mode="primary"
                                                continue
                                                disabled={
                                                    (!allowedSteps.includes(
                                                            assessmentStore.nextStepNumber
                                                        ) &&
                                                        step !==
                                                            ASSESSMENT_WIZARD_STEPS.FINALIZE) ||
                                                    (assessment.hasUnavailableQuestions &&
                                                        step ===
                                                            ASSESSMENT_WIZARD_STEPS.FINALIZE)
                                                }
                                                onClick={nextStep}
                                                text={
                                                    step ===
                                                    ASSESSMENT_WIZARD_STEPS.FINALIZE
                                                        ? assessment.id
                                                            ? assessment.status ===
                                                              STATUS.DRAFT
                                                                ? 'Activate'
                                                                : 'Update'
                                                            : 'Create'
                                                        : 'Continue'
                                                }
                                            ></ThemedLinkButton>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <AddScenariosModal
                                isOpen={questionsModal}
                                onRequestClose={hideQuestionsModal}
                                area={area}
                                setArea={setArea}
                                behaviour={behaviour}
                                setBehaviour={setBehaviour}
                                questions={questions}
                                threatAreaDefinitions={threatAreaDefinitions}
                                selection={assessment.selectedQuestionsIds}
                                onQuestionSelected={toggleQuestionSelected}
                                showPreviewQuestionsModal={
                                    showPreviewQuestionsModal
                                }
                            />
                            <Modal
                                isOpen={previewQuestionsModal}
                                onRequestClose={hidePreviewQuestionsModal}
                                className="preview-questions-modal"
                            >
                                <PreviewQuestion
                                    questionId={previewedQuestion}
                                    onRequestClose={hidePreviewQuestionsModal}
                                    hasMoreQuestions={hasMoreQuestions}
                                    companyId={companyId}
                                    questionsSelected={assessment.questionsSelected.map(
                                        (x) => x.id
                                    )}
                                    isWizard
                                    redirectToUpgrade={redirectToUpgrade}
                                />
                            </Modal>

                            <Modal
                                isOpen={addEmployeeModal}
                                onRequestClose={hideAddEmployeeModal}
                                className="Modal schedule"
                            >
                                <UsersSelectForm
                                    lists={lists}
                                    currentCompany={currentCompany}
                                    showRecipientsModal={showRecipientsModal}
                                    recipientsModal={recipientsModal}
                                    hideRecipientsModal={hideRecipientsModal}
                                    createRecipientsList={createRecipientsList}
                                    item={assessment}
                                    error={listError}
                                    loadingUsers={loadingUsers}
                                    requestUsersForList={requestUsersForList}
                                    selectedLists={
                                        assessment.selectedUserListsIds
                                    }
                                    selectedUserCount={
                                        assessment.selectedUserCount
                                    }
                                    onUserListSelected={toggleUserListSelected}
                                    hideAddEmployeeModal={hideAddEmployeeModal}
                                    disabled={createListDisabled}
                                    ongoing={ongoing}
                                    itemString="assessment"
                                    redirectToUpgrade={redirectToUpgrade}
                                />
                            </Modal>
                            <Modal
                                isOpen={recipientsModal}
                                onRequestClose={hideRecipientsModal}
                                className="Modal schedule"
                            >
                                <ListEditorModal
                                    result={assessment}
                                    error={listError}
                                    onRequestClose={hideRecipientsModal}
                                    createRecipientsList={createRecipientsList}
                                />
                            </Modal>
                            <Modal
                                isOpen={emailPreview}
                                onRequestClose={hideEmailPreview}
                                className="Modal schedule not-wide"
                            >
                                <PreviewEmail
                                    ownEmail={currentUser.email}
                                    onRequestClose={hideEmailPreview}
                                    sendPreview={sendPreview}
                                    sendError={sendError}
                                />
                            </Modal>
                            <Modal
                                isOpen={nameChangeModal}
                                onRequestClose={hideNameChangeModal}
                                className="Modal schedule not-wide"
                            >
                                <ChangeName
                                    name={assessment.name}
                                    onRequestClose={hideNameChangeModal}
                                    nextStep={nextStep}
                                    onChangeName={onChangeName}
                                    isDraft={isDraft}
                                    redirectLink={redirectLink}
                                    saveChanges={saveChanges}
                                    itemString="assessment"
                                    isNameUntitled={isNameUntitled(
                                        assessment.name,
                                        'assessment'
                                    )}
                                />
                            </Modal>
                            <Modal
                                isOpen={durationOverrideModal}
                                onRequestClose={hideDurationOverrideModal}
                                className="Modal schedule not-wide"
                            >
                                <ChangeDuration
                                    duration={
                                        getOverrideDurationInMinutes() ||
                                        assessment.selectedQuestionsDuration
                                    }
                                    onRequestClose={hideDurationOverrideModal}
                                    saveChanges={onChangeDuration}
                                    isDraft={isDraft}
                                />
                            </Modal>
                            <Modal
                                isOpen={saveChangesModal}
                                onRequestClose={hideSaveChangesModal}
                                className="Modal schedule not-wide"
                            >
                                <SaveChanges
                                    onRequestClose={hideSaveChangesModal}
                                    initiateSavingProgress={
                                        initiateSavingProgress
                                    }
                                    back={back}
                                    parentPath={parentPath}
                                    editing={
                                        assessment.status === STATUS.ACTIVE ||
                                        assessment.status ===
                                            STATUS.SCHEDULED ||
                                        assessment.status === STATUS.DRAFT
                                    }
                                />
                            </Modal>
                        </div>
                    ) : (
                        <Fragment>
                            <br />
                            <br />
                            <br />
                            <ErrorMessage>
                                <Alert
                                    color="warning"
                                    className="header"
                                    isOpen={true}
                                >
                                    No license available
                                </Alert>
                            </ErrorMessage>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
}

export default observer(AssessmentCreate);
