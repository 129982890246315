import React, { useEffect, useState, useCallback } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { Spinner } from 'reactstrap';
import HtmlField from '../../components/inputs/htmlField';
import EmailFormDef from '../../forms/email-settings';
import { fixHtml } from '../../utils/helpers';
import {
    ThemedNav,
    ThemedNavItem,
    ThemedNavLink,
} from '../../components/themed/ThemedComponents';
import { TabContent, TabPane } from 'reactstrap';
import { Heading2, ThemedButton } from '@awarego/awarego-components';
import { useStores } from '../../hooks/use-stores';

function EmailSettingsContainer({ companyId }) {
    const { store, companyStore } = useStores();
    const [activeTab, setActiveTab] = useState('1');
    const [initialValue, setInitialValue] = useState({});

    const localStore = useLocalObservable(() => {
        let _form = new EmailFormDef(
            {},
            {
                hooks: {
                    onSuccess: async () => {
                        const values = _form.values();
                        await companyStore.saveEmailSettings(companyId, {
                            ...values,
                            trainingHeader: fixHtml(values.trainingHeader),
                            trainingFooter: fixHtml(values.trainingFooter),
                            reminderHeader: fixHtml(values.reminderHeader),
                            reminderFooter: fixHtml(values.reminderFooter),
                            assessmentHeader: fixHtml(values.assessmentHeader),
                            assessmentFooter: fixHtml(values.assessmentFooter),
                            welcomeMessage: fixHtml(values.welcomeMessage),
                            mrkdwn_trainingHeader: fixHtml(
                                values.mrkdwn_trainingHeader
                            ),
                            mrkdwn_trainingFooter: fixHtml(
                                values.mrkdwn_trainingFooter
                            ),
                            mrkdwn_reminderHeader: fixHtml(
                                values.mrkdwn_reminderHeader
                            ),
                            mrkdwn_reminderFooter: fixHtml(
                                values.mrkdwn_reminderFooter
                            ),
                            mrkdwn_assessmentHeader: fixHtml(
                                values.mrkdwn_assessmentHeader
                            ),
                            mrkdwn_assessmentFooter: fixHtml(
                                values.mrkdwn_assessmentFooter
                            ),
                            mrkdwn_welcomeMessage: fixHtml(
                                values.mrkdwn_welcomeMessage
                            ),
                        });
                    },
                },
            }
        );

        return { _form };
    });

    const doLoadData = useCallback(async () => {
        localStore._form.update({
            trainingHeader:
                store.currentCompanyUISettings['ui.email.trainingHeader'],
            trainingFooter:
                store.currentCompanyUISettings['ui.email.trainingFooter'],
            reminderHeader:
                store.currentCompanyUISettings['ui.email.reminderHeader'],
            reminderFooter:
                store.currentCompanyUISettings['ui.email.reminderFooter'],
            assessmentHeader:
                store.currentCompanyUISettings['ui.email.assessmentHeader'],
            assessmentFooter:
                store.currentCompanyUISettings['ui.email.assessmentFooter'],
            welcomeMessage:
                store.currentCompanyUISettings['ui.email.welcomeMessage'],
            mrkdwn_trainingHeader:
                store.currentCompanyUISettings[
                    'ui.email.mrkdwn_trainingHeader'
                ],
            mrkdwn_trainingFooter:
                store.currentCompanyUISettings[
                    'ui.email.mrkdwn_trainingFooter'
                ],
            mrkdwn_reminderHeader:
                store.currentCompanyUISettings[
                    'ui.email.mrkdwn_reminderHeader'
                ],
            mrkdwn_reminderFooter:
                store.currentCompanyUISettings[
                    'ui.email.mrkdwn_reminderFooter'
                ],
            mrkdwn_assessmentHeader:
                store.currentCompanyUISettings[
                    'ui.email.mrkdwn_assessmentHeader'
                ],
            mrkdwn_assessmentFooter:
                store.currentCompanyUISettings[
                    'ui.email.mrkdwn_assessmentFooter'
                ],
            mrkdwn_welcomeMessage:
                store.currentCompanyUISettings[
                    'ui.email.mrkdwn_welcomeMessage'
                ],
        });
    }, [store && store.currentCompanyUISettings]);

    useEffect(() => {
        /* This is to avoid cursor jumping to the beginnig of the text editor on each value change.
         InitialValue must be a different reference than updated field.value
         RTE issue https://github.com/sstur/react-rte/issues/403 */
        setInitialValue({
            trainingHeader:
                store.currentCompanyUISettings['ui.email.trainingHeader'],
            trainingFooter:
                store.currentCompanyUISettings['ui.email.trainingFooter'],
            reminderHeader:
                store.currentCompanyUISettings['ui.email.reminderHeader'],
            reminderFooter:
                store.currentCompanyUISettings['ui.email.reminderFooter'],
            assessmentHeader:
                store.currentCompanyUISettings['ui.email.assessmentHeader'],
            assessmentFooter:
                store.currentCompanyUISettings['ui.email.assessmentFooter'],
            welcomeMessage:
                store.currentCompanyUISettings['ui.email.welcomeMessage'],
        });
        doLoadData();
    }, [companyId, store && store.currentCompanyUISettings]);

    const tabsChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const { savingEmailSettings } = companyStore;
    const { assessmentEnabled } = store;

    return (
        <div className="top-guide">
            <div className="block new-settings-block">
                <div className="row">
                    <div className="col-md-8 ">
                        <Heading2 displayBlock my={8}>
                            Email settings
                        </Heading2>
                        <p className="text-thin">
                            Set up texts, which should display in training,
                            reminder and assessment e-mails
                        </p>
                    </div>
                </div>
                <ThemedNav dashboard>
                    <ThemedNavItem active={activeTab === '1' ? 'active' : ''}>
                        <ThemedNavLink
                            onClick={() => {
                                tabsChange('1');
                            }}
                        >
                            Training
                        </ThemedNavLink>
                    </ThemedNavItem>
                    <ThemedNavItem active={activeTab === '2' ? 'active' : ''}>
                        <ThemedNavLink
                            onClick={() => {
                                tabsChange('2');
                            }}
                        >
                            Reminders
                        </ThemedNavLink>
                    </ThemedNavItem>
                    {assessmentEnabled && (
                        <ThemedNavItem
                            active={activeTab === '3' ? 'active' : ''}
                        >
                            <ThemedNavLink
                                onClick={() => {
                                    tabsChange('3');
                                }}
                            >
                                Assessments
                            </ThemedNavLink>
                        </ThemedNavItem>
                    )}
                    <ThemedNavItem active={activeTab === '4' ? 'active' : ''}>
                        <ThemedNavLink
                            onClick={() => {
                                tabsChange('4');
                            }}
                        >
                            Welcome Message
                        </ThemedNavLink>
                    </ThemedNavItem>
                </ThemedNav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <br />
                        <div className="form-group col-md-8">
                            <HtmlField
                                field={localStore._form.$('trainingHeader')}
                                mrkdownField={localStore._form.$(
                                    'mrkdwn_trainingHeader'
                                )}
                                showLabel={true}
                                initialValue={initialValue}
                            />
                        </div>
                        <div className="form-group col-md-8">
                            <HtmlField
                                field={localStore._form.$('trainingFooter')}
                                mrkdownField={localStore._form.$(
                                    'mrkdwn_trainingFooter'
                                )}
                                showLabel={true}
                                initialValue={initialValue}
                            />
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <br />
                        <div className="form-group col-md-8">
                            <HtmlField
                                field={localStore._form.$('reminderHeader')}
                                mrkdownField={localStore._form.$(
                                    'mrkdwn_reminderHeader'
                                )}
                                showLabel={true}
                                initialValue={initialValue}
                            />
                        </div>
                        <div className="form-group col-md-8">
                            <HtmlField
                                field={localStore._form.$('reminderFooter')}
                                mrkdownField={localStore._form.$(
                                    'mrkdwn_reminderFooter'
                                )}
                                showLabel={true}
                                initialValue={initialValue}
                            />
                        </div>
                    </TabPane>
                    <TabPane tabId="3">
                        <br />
                        <div className="form-group col-md-8">
                            <HtmlField
                                field={localStore._form.$('assessmentHeader')}
                                mrkdownField={localStore._form.$(
                                    'mrkdwn_assessmentHeader'
                                )}
                                showLabel={true}
                                initialValue={initialValue}
                            />
                        </div>
                        <div className="form-group col-md-8">
                            <HtmlField
                                field={localStore._form.$('assessmentFooter')}
                                mrkdownField={localStore._form.$(
                                    'mrkdwn_assessmentFooter'
                                )}
                                showLabel={true}
                                initialValue={initialValue}
                            />
                        </div>
                    </TabPane>
                    <TabPane tabId="4">
                        <br />
                        <div className="form-group col-md-8">
                            <HtmlField
                                field={localStore._form.$('welcomeMessage')}
                                mrkdownField={localStore._form.$(
                                    'mrkdwn_welcomeMessage'
                                )}
                                showLabel={true}
                                initialValue={initialValue}
                            />
                        </div>
                    </TabPane>
                </TabContent>
                <div className="row">
                    {savingEmailSettings.includes(companyId) ? (
                        <Spinner />
                    ) : (
                        <ThemedButton
                            mode="secondary"
                            onClick={localStore._form.onSubmit}
                            airy
                            text="Save"
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default observer(EmailSettingsContainer);
