import { makeAutoObservable, reaction } from 'mobx';
import sumBy from 'lodash/sumBy';
import flatten from 'lodash/flatten';
import {
    TRANSPORTS,
    STATUS,
    REMINDER_DELAY_TYPE,
    EVENT_TYPE,
    AUTOMATION_SCHEDULE_TYPE,
} from '../../utils/constants';
import { defaultZero, getReminderDelayType } from '../../utils/helpers';
import { palette } from '@awarego/awarego-components';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import compact from 'lodash/compact';

const statuses = {
    [STATUS.DRAFT]: {
        label: 'Draft',
        icon: false,
        value: STATUS.DRAFT,
        color: palette.graphite.heavyMetal,
    },
    [STATUS.STOPPED]: {
        label: 'Stopped',
        icon: false,
        value: STATUS.STOPPED,
        color: palette.vibrant.red,
    },
    [STATUS.SCHEDULED]: {
        label: 'Scheduled',
        icon: false,
        value: STATUS.SCHEDULED,
        color: palette.vibrant.blue,
    },
    [STATUS.ACTIVE]: {
        label: 'Active',
        icon: false,
        value: STATUS.ACTIVE,
        color: palette.vibrant.orange,
    },
    [STATUS.COMPLETED]: {
        label: '✓ Completed',
        icon: true,
        value: STATUS.COMPLETED,
        color: palette.evergreen.aspargus,
    },
};

class Automation {
    selectedVideosIds = [];
    selectedUserListsIds = [];
    date = null;
    dueDate = null;
    interval = 'W';
    name = 'Untitled Training';
    scheduleType = 0;
    channel = null;
    reminders_enabled = false;
    reminders_channel = null;
    reminders_steps = [
        {
            delay: 1,
            delayType: REMINDER_DELAY_TYPE.DAY,
        },
    ];
    active = false;
    enabledLanguages = [];
    status = null;
    templateCustomized = false;
    id = null;
    templateTip = '';
    introMessage = '';
    introMessageEnabled = false;
    deliveryCalendarMonth = moment().format('YYYY-MM');
    deliveryCalendarDate = moment();
    deliverySchedule = [];
    userListSearchValue = '';
    isScorm = false;
    usersChanges = 0;
    disableSyncLists = false;
    employeeEmail = null; //use if we are creating this instance coming from the employee details page

    subjectsPerInterval = null;

    constructor(
        listStore,
        catalogStore,
        mainStore,
        companyId,
        questionsIndex,
        automationData,
        isTrial,
        havePartnerSubscription,
        employeeEmail,
        enabledLanguages //we are creating this instance coming from the employee details page
    ) {
        makeAutoObservable(this);
        this.listStore = listStore;
        this.catalogStore = catalogStore;
        this.mainStore = mainStore;
        this.companyId = companyId;
        this.questionsIndex = questionsIndex;
        this.havePartnerSubscription = havePartnerSubscription;
        this.isTrial = isTrial;
        this.employeeEmail = employeeEmail;
        this.enabledLanguages = enabledLanguages;
        this.channel =
            window.localStorage.getItem(`automationChannel-${companyId}`) ||
            TRANSPORTS.EMAIL;
        this.reminders_channel =
            window.localStorage.getItem(
                `automationRemindersChannel-${companyId}`
            ) || TRANSPORTS.EMAIL;
        if (automationData) {
            this._rawData = automationData.id ? automationData : null;
            this.name = automationData.name;
            this.id = automationData.id;
            if (automationData.frequency) {
                this.setFrequency(automationData.frequency);
            }
            if (automationData.active !== undefined)
                this.active = automationData.active === 1;
            if (automationData.reminders_enabled)
                this.reminders_enabled = automationData.reminders_enabled === 1;
            if (automationData.reminders_steps)
                automationData.reminders_steps.length > 0
                    ? this.reminders_steps.replace(
                          automationData.reminders_steps
                      )
                    : this.reminders_steps.replace([
                          {
                              delay: 1,
                              delayType: REMINDER_DELAY_TYPE.DAY,
                          },
                      ]);
            if (automationData.reminders_settings)
                this.reminders_channel =
                    automationData.reminders_settings.channel;
            if (automationData.sendOptions)
                this.channel = automationData.sendOptions.channel;
            else if (
                automationData.reminders_settings &&
                automationData.reminders_settings.deliveryChannel
            )
                this.channel =
                    automationData.reminders_settings.deliveryChannel;
            if (
                automationData.definition &&
                automationData.definition.introMessageEnabled
            ) {
                this.introMessageEnabled =
                    automationData.definition.introMessageEnabled;
            }
            if (
                automationData.definition &&
                automationData.definition.introductoryData
            ) {
                this.introMessage =
                    automationData.definition.introductoryData.message;
            }
            if (automationData.definition) {
                this.disableSyncLists =
                    automationData.definition.disableSyncLists;
            }
            if (automationData.events) {
                this.selectedVideosIds.replace(
                    flatten(
                        automationData.events.map((x) =>
                            x.subjects.map((s) => s.subject_id)
                        )
                    )
                );
                if (
                    automationData.events.length > 0 &&
                    !moment(automationData.events[0].send).isBefore(
                        moment(),
                        'hour'
                    ) &&
                    !automationData.isCopy
                ) {
                    this.date = automationData.events[0].send;
                }

                let result = [];
                automationData.events.map((e, i) => {
                    result.push({
                        date: e.send,
                        id: e.id,
                        tempId: i,
                        subjects: e.subjects,
                    });
                });
                this.deliverySchedule = result;
            } else if (automationData.subjects) {
                this.selectedVideosIds.replace(automationData.subjects);
            }
            if (automationData.lists)
                this.selectedUserListsIds.replace(
                    automationData.lists.map((x) => x.id)
                );
            this.scheduleType =
                automationData.scheduleType != null
                    ? automationData.scheduleType
                    : this.scheduleType;
            this.status = automationData.status;
            this.subjectsNames = automationData.subjectsNames;
            this.isScorm = automationData.isScorm;
            this.dueDate = automationData.finishDate;
        }
        reaction(
            () => this.scheduleType,
            (scheduleType) => {
                this.recalculateDeliverySchedule();
                if (scheduleType === AUTOMATION_SCHEDULE_TYPE.FUTURE)
                    this.moveCalendarViewToDate();
                if (scheduleType === AUTOMATION_SCHEDULE_TYPE.NOW)
                    this.moveCalendarViewToToday();
            }
        );
        reaction(
            () => this.date,
            () => {
                this.recalculateDeliverySchedule();
                this.moveCalendarViewToDate();
            }
        );
        reaction(
            () => this.interval,
            () => {
                this.recalculateDeliverySchedule();
                this.moveCalendarViewToDate();
            }
        );
        reaction(
            () => this.subjectsPerInterval,
            () => {
                this.recalculateDeliverySchedule();
                this.moveCalendarViewToDate();
            }
        );
        if (
            !moment(this.deliveryCalendarMonth).isSame(
                moment(this.date),
                'month'
            )
        ) {
            this.moveCalendarViewToDate();
        }
    }

    setName = (value) => {
        this.name = value;
    };

    setUserListSearchValue(userListSearchValue) {
        this.userListSearchValue = userListSearchValue;
    }

    setId = (value) => {
        this.id = value;
    };

    toggleIntroMessage = () => {
        this.introMessageEnabled = !this.introMessageEnabled;
    };

    setScheduleType = (type) => {
        this.scheduleType = type;
    };

    setDeliveryCalendarMonth = (momentMonth) => {
        this.deliveryCalendarMonth = momentMonth;
        // select first scheduled event in the month
        this.daysWithEventsInSelectedMonth.length > 0 &&
            this.setDeliveryCalendarDate(this.daysWithEventsInSelectedMonth[0]);
    };

    setDeliveryCalendarDate = (date) => {
        this.deliveryCalendarDate = date;
    };

    setDate = (date) => {
        this.date = moment(date);
    };

    setDueDate = (date) => {
        this.dueDate = date ? moment(date) : null;
    };

    setRemindersEnabled = () => {
        this.reminders_enabled = !this.reminders_enabled;
    };

    setChannel = (value, checked) => {
        let newChannel = this.channel;
        if (checked) {
            // add checked value to channel, this is called bitwise OR
            newChannel |= value;
        } else {
            // remove unchecked value from channel, this is called bitwise NOT
            newChannel &= ~value;
        }
        if (newChannel !== 0) {
            this.channel = newChannel;
            window.localStorage.setItem(
                `automationChannel-${this.companyId}`,
                this.channel
            );
            return true;
        } else {
            return false;
        }
    };

    setRemindersChannel = (value, checked) => {
        let newChannel = this.reminders_channel;
        if (checked) {
            // add checked value to channel, this is called bitwise OR
            newChannel |= value;
        } else {
            // remove unchecked value from channel, this is called bitwise NOT
            newChannel &= ~value;
        }
        if (newChannel !== 0) {
            this.reminders_channel = newChannel;
            window.localStorage.setItem(
                `automationRemindersChannel-${this.companyId}`,
                this.reminders_channel
            );
            return true;
        } else {
            return false;
        }
    };

    addReminderStep = () => {
        this.reminders_steps.push({
            delay: Math.ceil(0.4 * Math.exp(this.reminders_steps.length + 1)),
            delayType: 3,
        });
    };

    deleteReminderStep = (value) => {
        this.reminders_steps.remove(value);
    };

    setFrequency = (frequency) => {
        this.interval = frequency && frequency.substr(0, 1);
        this.subjectsPerInterval = frequency && frequency.substr(1, 2);
    };

    setInterval = (interval) => {
        this.interval = interval;
    };

    setSubjectsPerInterval = (subjectsPerInterval) => {
        this.subjectsPerInterval = subjectsPerInterval;
    };

    toggleVideoSelected(videoId) {
        if (this.selectedVideosIds.includes(videoId))
            this.selectedVideosIds.remove(videoId);
        else this.selectedVideosIds.push(videoId);
        this.templateCustomized = true;
        this.recalculateDeliverySchedule();
    }

    toggleUserListSelected(listId) {
        if (this.selectedUserListsIds.includes(listId))
            this.selectedUserListsIds.remove(listId);
        else this.selectedUserListsIds.push(listId);
    }

    reorderSubjectList(startIndex, endIndex) {
        if (endIndex < 0) {
            endIndex = 0;
        }
        const [removed] = this.selectedVideosIds.splice(startIndex, 1);
        this.selectedVideosIds.splice(endIndex, 0, removed);
        this.recalculateDeliverySchedule();
    }

    updateEventDate = (e, newDate) => {
        this.deliverySchedule.find((x) => x.tempId === e.tempId).date =
            new Date(newDate);
    };

    recalculateDeliverySchedule() {
        let result = [];
        let count = this.videosSelected.length;
        let idx = 0;
        let theDate = new Date();

        if (this.scheduleType === 0 && this.date) {
            theDate = new Date(this.date);
        } else {
            theDate.setHours(theDate.getHours() + 1);
            theDate.setMinutes(0);
            theDate.setSeconds(0);
            theDate.setMilliseconds(0);
        }

        if (count > 0) {
            let loopCounter = 0;

            // max 200 loops (just in case of infinite loop)
            while (idx < count && loopCounter < 200) {
                let event = {};
                event.date = new Date(theDate.getTime());
                event.tempId = loopCounter;
                event.subjects = [];
                for (let i = 0; i < this.subjectsPerInterval; i++) {
                    if (this.videosSelected[idx]) {
                        event.subjects.push(this.videosSelected[idx]);
                    }
                    idx++;
                }

                result.push(event);
                if (this.interval === 'M') {
                    theDate.setMonth(theDate.getMonth() + 1);
                } else if (this.interval === 'B') {
                    theDate.setDate(theDate.getDate() + 14);
                } else {
                    theDate.setDate(theDate.getDate() + 7);
                }

                loopCounter++;
            }
        }
        this.deliverySchedule = result;
    }

    moveCalendarViewToDate = () => {
        this.setDeliveryCalendarDate(this.date);
        this.setDeliveryCalendarMonth(moment(this.date));
    };

    moveCalendarViewToToday = () => {
        this.setDeliveryCalendarDate(new Date());
        this.setDeliveryCalendarMonth(moment());
    };

    removeUser(email) {
        if (this._rawData) {
            this._rawData.users = this._rawData.users.filter(
                (x) => x.email !== email
            );
            this.usersChanges = this.usersChanges + 1;
        }
    }

    get users() {
        if (this.usersChanges > 0) return this._rawData.users;
        else return this._rawData.users;
    }

    get isUserCountOk() {
        let usersAvailable = 0;
        let usersRegistered = 0;

        if (this.company) {
            if (this.company.users_available) {
                usersAvailable = this.company.users_available;
            }
            usersRegistered = this.getCurrentCompanyUserCount;
        }
        return usersAvailable >= usersRegistered;
    }

    get startDate() {
        if (!this._rawData || this._rawData.status === STATUS.DRAFT)
            return null;
        if (this.scheduleType === 2) return this._rawData.created;
        return this._rawData.firstBatch;
    }

    get startDateText() {
        let text;
        if (moment().isBefore(moment(this.startDate))) {
            text = 'Starts';
        } else {
            text = 'Started';
        }
        return `${text} on ${moment(this.startDate).format('ll')} `;
    }

    get endDate() {
        if (!this._rawData || this._rawData.status === STATUS.DRAFT)
            return null;
        return this._rawData.lastBatch;
    }

    get finishDate() {
        if (!this._rawData || this._rawData.status === STATUS.DRAFT)
            return null;
        return this._rawData.finishDate;
    }

    get statusInfo() {
        return statuses[this.status] || {};
    }

    get unavailableSubjectsSelected() {
        if (
            this.catalogStore.loadingCatalog ||
            this.selectedVideosIds.length === 0
        ) {
            return [];
        } else {
            return Object.keys(this.catalogStore.allSubjectsIndex)
                .filter((v) => this.selectedVideosIds.includes(parseInt(v)))
                .filter((k) => {
                    return (
                        this.catalogStore.allSubjectsIndex[k].disabled ||
                        (this.isTrial &&
                            !this.catalogStore.allSubjectsIndex[k]
                                .availableForBeta)
                    );
                });
        }
    }

    get hasUnavailableSubjects() {
        return (
            this.unavailableSubjectsSelected &&
            this.unavailableSubjectsSelected.length > 0
        );
    }

    get link() {
        if (this.status === STATUS.DRAFT) return `/trainings/edit/${this.id}`;
        if (this.employeeEmail) {
            return `/trainings/${this.id}/user/${this.employeeEmail}`; //This link is used within single employee view
        }
        return `/trainings/${this.isScorm ? 'p' : ''}${this.id}/view`;
    }

    get attendance() {
        return defaultZero(
            Math.round(
                (100 * (this._rawData.opened || 0)) / this._rawData.totalUsers
            )
        );
    }

    get awarenessScoreSent() {
        return defaultZero(
            Math.round(
                (100 * (this._rawData.score || 0)) /
                    this._rawData.totalForViewSent
            )
        );
    }

    get awarenessScore() {
        return defaultZero(
            Math.round(
                (100 * (this._rawData.score || 0)) / this._rawData.totalForView
            )
        );
    }

    get singleUserScore() {
        return defaultZero(
            Math.round(
                (100 * (this._rawData.viewed || 0)) / this._rawData.subjectsSent
            )
        );
    }

    get videosSelected() {
        if (this.catalogStore.loadingCatalog) return [];
        return this.selectedVideosIds
            .map((x) => this.catalogStore.allSubjectsIndex[x])
            .filter((x) => x != null);
    }

    get videosSelectedDuration() {
        return this.videosSelected.reduce((a, x) => {
            return a + x.duration || 0;
        }, 0);
    }

    get userListsSuffix() {
        if (this.catalogStore.loadingCatalog) return '';
        return this.selectedUserListsIds.length === 1
            ? ` - ${this.userListsSelected[0].name}`
            : '';
    }

    get userListsSelected() {
        if (this.catalogStore.loadingCatalog) return [];
        return compact(
            this.selectedUserListsIds.map(
                (x) => this.listStore.allListsIndex[x]
            )
        );
    }

    get filteredUserListsSelected() {
        return this.userListsSelected.filter(
            (x) =>
                !(
                    this.userListSearchValue.length > 0 &&
                    x.name !== null &&
                    !x.name
                        .toLowerCase()
                        .includes(this.userListSearchValue.toLowerCase())
                )
        );
    }

    get selectedUserCount() {
        return sumBy(
            this.selectedUserListsIds.map(
                (x) => this.listStore.allListsIndex[x]
            ),
            'usersCount'
        );
    }

    get canDelete() {
        if (!this.mainStore || !this.mainStore.isAdmin) return false;
        return true;
    }

    get canRename() {
        if (!this.mainStore || !this.mainStore.isAdmin) return false;
        return true;
    }

    get canCopy() {
        if (!this.mainStore || !this.mainStore.isAdmin) return false;
        if (!this._rawData) return false;
        if (this.active) return false;
        if (this._rawData.events.every((x) => x.status > 0)) return false;

        return true;
    }

    get canResume() {
        if (!this.mainStore || !this.mainStore.isAdmin) return false;
        if (!this._rawData) return false;
        return this.status === STATUS.STOPPED;
    }

    get canStop() {
        if (!this.mainStore || !this.mainStore.isAdmin) return false;
        if (!this._rawData) return false;
        if (!this.status === STATUS.ACTIVE || !this.status === STATUS.SCHEDULED)
            return false;
        if (
            this._rawData.events.every(
                (x) =>
                    x.status === STATUS.ACTIVE &&
                    x.reminders.every((y) => y.status === STATUS.ACTIVE)
            )
        )
            return false;
        if (
            this._rawData.events.some(
                (x) =>
                    x.status !== STATUS.ACTIVE ||
                    x.reminders.some((y) => y.status !== STATUS.ACTIVE)
            )
        )
            return true;

        return false;
    }

    get canRemind() {
        if (!this.mainStore || !this.mainStore.isAdmin) return false;
        if (
            !this._rawData ||
            !this.active ||
            this.status === STATUS.SCHEDULED ||
            this.isScorm
        )
            return false;
        return this._rawData.score < this._rawData.total;
    }

    get introductoryData() {
        let data = {};
        if (this.introMessage) {
            data.message = this.introMessage;
        }
        return data;
    }

    get remindersFromServer() {
        if (!this._rawData) return [];
        let remindersFromServer = [];
        this._rawData.events.map((e) => {
            e.reminders.map((r) => {
                remindersFromServer.push({
                    id: r.id,
                    date: r.send,
                    scheduleEventId: e.id,
                });
            });
        });
        return remindersFromServer;
    }

    get daysWithEventsInSelectedMonth() {
        if (this.date === null) return [];

        let smu = this.deliverySchedule
            .filter(
                (e) =>
                    new Date(e.date).getMonth() ===
                    new Date(this.deliveryCalendarMonth).getMonth()
            )
            .map((e) => moment(e.date).format('YYYY-MM-DD'));
        return smu;
    }

    get displayedCalendarEvents() {
        let events = this.deliverySchedule
            .map((e) => {
                return {
                    ...e,
                    name: e.subjects.map((s) => s.title).join(', '),
                };
            })
            .filter(
                (e) =>
                    new Date(e.date).getMonth() ===
                    new Date(this.deliveryCalendarMonth).getMonth()
            );

        // show an "empty" event if there's nothing scheduled on selected date
        if (
            !this.daysWithEventsInSelectedMonth.includes(
                moment(this.deliveryCalendarDate).format('YYYY-MM-DD')
            )
        ) {
            events.push({
                name: 'No event scheduled',
                date: moment(this.deliveryCalendarDate),
            });
            return orderBy(events, 'date');
        }

        return events;
    }

    get remindersList() {
        let reminders = [];
        this.reminders_enabled &&
            this.deliverySchedule.map((e) =>
                this.reminders_steps.map((r) =>
                    reminders.push(
                        moment(e.date).add(
                            r.delay,
                            getReminderDelayType(r.delayType, 2)
                        )
                    )
                )
            );
        return reminders;
    }

    get lessonsDelivered() {
        return `${this._rawData.subjectsSent}/${this._rawData.totalSubjects}`;
    }

    get eventsDTO() {
        let eventsWithReminders = [];
        this.deliverySchedule.map((e, index) => {
            const next = this.deliverySchedule[index + 1];
            //TODO: e.subjects is different object in case of creating or updating training. so need   have ( s.subject_id || s.id )
            // s.subject_id  - present on update, but s.id is different thing
            // s.subject_id  - absent on creation - so  need to use s.id
            eventsWithReminders.push({
                date: e.date,
                type: EVENT_TYPE.SCHEDULE,
                id: e.id || -1,
                subjects: e.subjects.map((s) => s.subject_id || s.id),
            });
            if (this.reminders_enabled) {
                this.reminders_steps.map((r) => {
                    let reminderDate = moment(e.date).add(
                        r.delay,
                        getReminderDelayType(r.delayType, 2)
                    );
                    if (!next || reminderDate.isBefore(next.date)) {
                        eventsWithReminders.push({
                            type: EVENT_TYPE.REMINDER,
                            date: reminderDate.toDate(),
                            id: -1,
                        });
                    }
                });
            }
        });
        return eventsWithReminders;
    }

    get dto() {
        return this.toDTO();
    }

    get lessonsSent() {
        return this._rawData.subjectsSent;
    }

    get lessonsTotal() {
        return this._rawData.subjects.length;
    }

    get totalUsers() {
        return this._rawData.totalUsers;
    }

    get engagement() {
        return this._rawData.engagement;
    }

    get standards() {
        return this._rawData.standards;
    }

    toDTO(extraData) {
        return {
            subjects: this.selectedVideosIds,
            name: this.name,
            id: this.id,
            dueDate: this.dueDate,
            introMessageEnabled: this.introMessageEnabled,
            userLists: this.selectedUserListsIds,
            sendOptions: { channel: this.channel },
            reminders_enabled: this.reminders_enabled,
            reminders_settings: {
                channel: this.reminders_channel,
                steps: this.reminders_steps.map((r) => {
                    return {
                        ...r,
                        // still send sendTime & timeType unnecessarily, so BE doesn't fail...BE needs a code cleanup
                        sendTime:
                            this.eventsDTO.length > 0 &&
                            new Date(this.eventsDTO[0].date).getHours(),
                        timeType: 1,
                    };
                }),
            },
            events: this.eventsDTO,
            subjectsPerInterval: this.subjectsPerInterval,
            scheduleType: this.scheduleType,
            frequency: `${this.interval}${this.subjectsPerInterval}`,
            templateCustomized: this.templateCustomized,
            status: this.status,
            enabledLanguages: this.enabledLanguages,
            ...extraData,
        };
    }
}

export default Automation;
