import React, { Fragment, useMemo, useState, useEffect } from 'react';
import { MenuItem, Menu } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LINKS, STATUS } from '../../../utils/constants';
import { calculateColor } from '../../../utils/helpers';
import {
    TextTiny,
    FlexDiv,
    StatusBadge,
    ProgressWithNumber,
    LightTooltip,
    NestedMenuItem,
    DarkArrowTooltipIcon,
} from '@awarego/awarego-components';
import RequireRole from '../../RequireRole';
import NameAndSublistCell from '../../../components/manager/NameAndSublistCell';
import DateCell from '../../../components/manager/DateCell';
import TableClient from '../../../components/table/TableClient';
import {
    caseAndDiacriticsInsensitiveSort,
    statusAlphabeticalSort,
} from '../../../components/table/table-helpers';

function AutomationListTable({
    automations,
    onDelete,
    pauseAutomation,
    resumeAutomation,
    lists,
    onDownloadPackage,
    isPackageDownloading,
    defaultSortBy,
}) {
    let navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const [automation, setOpenedAutomation] = useState(null);
    const rowsPerPage = 10;
    const maxVisibleLength = 30;

    const handleDownloadPackage = (type) => {
        if (onDownloadPackage) {
            onDownloadPackage(
                automation.id,
                automation._rawData.uniqueId,
                type
            );
        }
    };

    useEffect(() => {
        if (isPackageDownloading == false) {
            handleMenuClose();
        }
    }, [isPackageDownloading]);

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (x) => {
                    const subjects = x.row.original.subjectsNames;
                    return (
                        <NameAndSublistCell
                            name={x.value}
                            maxVisibleNameLength={maxVisibleLength}
                            subListItems={subjects}
                            maxVisibleSubListCount={1}
                            linkName={
                                x.row.original.isScorm
                                    ? null
                                    : x.row.original.status === STATUS.DRAFT
                                    ? `/trainings/edit/${x.row.original.id}`
                                    : `/trainings/${x.row.original.id}/view`
                            }
                        />
                    );
                },
                sortType: caseAndDiacriticsInsensitiveSort,
            },
            {
                Header: 'Status',
                accessor: '_rawData.status',
                Cell: (x) => (
                    <StatusBadge
                        color={x.row.original.statusInfo.color}
                        text={x.row.original.statusInfo.label}
                    />
                ),
                sortType: statusAlphabeticalSort,
            },
            {
                Header: '# Employees',
                accessor: '_rawData.totalUsers',
                Cell: (x) => {
                    const listNames = lists.filter((list) =>
                        x.row.original._rawData.lists.includes(list.id)
                    );
                    return listNames.length > 0 ? (
                        <LightTooltip
                            title={listNames.map((list) => (
                                <div key={list.id}>{list.name}</div>
                            ))}
                        >
                            <TextTiny underline lighter>
                                {x.value}
                            </TextTiny>
                        </LightTooltip>
                    ) : (
                        <TextTiny lighter>0</TextTiny>
                    );
                },
                sortDescFirst: true,
            },
            {
                Header: 'Start date',
                accessor: '_rawData.firstBatch',
                Cell: (x) => <DateCell date={x.value} />,
                minWidth: 112,
                sortDescFirst: true,
            },
            {
                Header: 'End date',
                accessor: '_rawData.lastBatch',
                Cell: (x) => <DateCell date={x.value} />,
                minWidth: 112,
                sortDescFirst: true,
            },
            {
                Header: 'Attendance',
                accessor: 'attendance',
                Cell: (x) =>
                    x.row.original.status === STATUS.ACTIVE ||
                    x.row.original.status === STATUS.COMPLETED ||
                    x.row.original._rawData.opened === 1 ? (
                        <Fragment>
                            <div>
                                <TextTiny bold color={calculateColor(x.value)}>
                                    {x.value}%
                                </TextTiny>
                            </div>
                            <TextTiny lighter>
                                {x.row.original._rawData.opened}/
                                {x.row.original._rawData.totalUsers}
                            </TextTiny>
                        </Fragment>
                    ) : (
                        ''
                    ),
                tooltip:
                    'Percentage of employees who have started to watch at least one lesson in a training.',
                sortDescFirst: true,
            },
            {
                Header: 'Completion rate',
                accessor: 'awarenessScore',
                Cell: (x) =>
                    x.row.original.status === STATUS.ACTIVE ||
                    x.row.original.status === STATUS.COMPLETED ||
                    x.row.original._rawData.opened === 1 ? (
                        <ProgressWithNumber value={x.value} height={6} />
                    ) : (
                        ''
                    ),
                tooltip: 'Completion rate across all training lessons.',
                sortDescFirst: true,
            },
            {
                Header: ' ',
                Cell: (x) => buildExtraActionsColumn(x.row.original),
            },
            {
                Header: 'Last Updated',
                accessor: '_rawData.updated',
                disableSortBy: false,
                disableFilters: false,
                show: false,
            },
        ],
        [caseAndDiacriticsInsensitiveSort, lists]
    );

    const buildExtraActionsColumn = (row) => (
        <>
            <DarkArrowTooltipIcon
                customFunction={handleMenuOpen}
                row={row}
                title="More"
                icon={'more'}
            ></DarkArrowTooltipIcon>
        </>
    );

    const handleMenuOpen = (e, automation) => {
        setOpenedAutomation(automation);
        setAnchorEl(e.currentTarget);
        e.stopPropagation();
    };

    const handleMenuClose = (e) => {
        setOpenedAutomation(null);
        setAnchorEl(null);
        if (e) {
            e.stopPropagation();
        }
    };

    // if you want some rows to be clickable and others not
    const createLink = (row) => {
        const isScorm = row.original.isScorm;
        if (!isScorm) {
            if (row.original.status === STATUS.DRAFT)
                return `/trainings/edit/${row.original.id}`;
            return LINKS.training;
        } else return null;
    };

    return (
        <FlexDiv mt={32}>
            <TableClient
                data={automations}
                columns={columns}
                link={(row) => createLink(row)}
                defaultSortBy={defaultSortBy}
                hiddenColumns={['_rawData.updated']}
                initialPageSize={rowsPerPage}
                hidePagination={automations.length <= rowsPerPage}
            />
            {automation && (
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={automation != null}
                    onClose={handleMenuClose}
                    elevation={1}
                >
                    {!automation.isScorm && (
                        <MenuItem
                            onClick={(e) => {
                                navigate(automation.link);
                                handleMenuClose(e);
                            }}
                        >
                            Open
                        </MenuItem>
                    )}
                    {automation.isScorm && (
                        <NestedMenuItem
                            text="Download training"
                            loading={isPackageDownloading}
                            items={[
                                {
                                    text: 'SCORM 1.2',
                                    props: {
                                        onClick: () => {
                                            handleDownloadPackage('scorm12');
                                        },
                                    },
                                },
                                {
                                    text: 'SCORM 2004 3rd Edition',
                                    props: {
                                        onClick: () => {
                                            handleDownloadPackage('scorm2004');
                                        },
                                    },
                                },
                                {
                                    text: 'cmi5',
                                    props: {
                                        onClick: () => {
                                            handleDownloadPackage('cmi5');
                                        },
                                    },
                                },
                            ]}
                        />
                    )}
                    <RequireRole>
                        {!automation.isScorm && (
                            <MenuItem
                                onClick={(e) => {
                                    navigate(
                                        `/trainings/create/copy/${automation.id}`
                                    );
                                    handleMenuClose(e);
                                }}
                            >
                                Duplicate
                            </MenuItem>
                        )}
                        {!automation.isScorm &&
                            (automation.status === STATUS.DRAFT ||
                                automation.status === STATUS.SCHEDULED) && (
                                <MenuItem
                                    onClick={(e) => {
                                        navigate(
                                            `/trainings/edit/${automation.id}`
                                        );
                                        handleMenuClose(e);
                                    }}
                                >
                                    Edit
                                </MenuItem>
                            )}
                        {!automation.isScorm &&
                            (automation.status === STATUS.SCHEDULED ||
                                automation.status === STATUS.ACTIVE) && (
                                <MenuItem
                                    onClick={(e) => {
                                        pauseAutomation(automation.id);
                                        handleMenuClose(e);
                                    }}
                                >
                                    Stop
                                </MenuItem>
                            )}

                        {!automation.isScorm &&
                            automation.status === STATUS.STOPPED && (
                                <MenuItem
                                    onClick={(e) => {
                                        resumeAutomation(automation.id);
                                        handleMenuClose(e);
                                    }}
                                >
                                    Resume
                                </MenuItem>
                            )}
                        {(automation.isScorm ||
                            automation.status === STATUS.DRAFT ||
                            automation.status === STATUS.SCHEDULED) && (
                            <MenuItem
                                onClick={(e) => {
                                    onDelete(automation.id, automation.isScorm);
                                    handleMenuClose(e);
                                }}
                            >
                                <span style={{ color: 'red' }}>Delete</span>
                            </MenuItem>
                        )}
                    </RequireRole>
                </Menu>
            )}
        </FlexDiv>
    );
}

export default AutomationListTable;
