export const EVENTS = {
    LOGIN: 1,
    LOGOUT: 2,
    SUBJECT_OPEN: 3,
    SUBJECT_CLOSE: 4,
    ANSWARE_CORRECT: 5,
    ANSWARE_INCORRECT: 6,
    VIDEO_START: 7,
    VIDEO_25: 8,
    VIDEO_50: 9,
    VIDEO_75: 10,
    VIDEO_95: 11,
    VIDEO_100: 12,
    CAMPAIGN_EMAIL: 13,
    NOTIFICATION_LINK: 14,
    DECISION: 16, //Decision was taken
    LAST_DECISION: 17, //Decision subject was completed
};

export const USER_ROLE = {
    ADMIN: 1,
    VIEWER: 2,
};

export const USERS_VIEW = {
    LISTS: 1,
    EDIT: 2,
    ADD_USERS: 3,
    LIST_USERS: 4,
    LIST_VIEW: 5,
};

export const TRANSPORTS = {
    EMAIL: 1,
    SLACK: 2,
    TEAMS: 4,
};

export const AUTOMATION_WIZARD_STEPS = {
    GOAL: 1,
    CONTENT: 2,
    EMPLOYEES: 3,
    SCHEDULE: 4,
    REMINDERS: 5,
    INTROMESSAGE: 6,
    LANGUAGES: 7,
    FINALIZE: 8,
    START: 9,
};

export const ASSESSMENT_WIZARD_STEPS = {
    GOAL: 1,
    CONTENT: 2,
    PERSONALIZE: 3,
    EMPLOYEES: 4,
    SCHEDULE: 5,
    REMINDERS: 6,
    // INTROMESSAGE:7,
    LANGUAGES: 7,
    FINALIZE: 8,
    START: 9,
};

export const STATUS = {
    DRAFT: -1,
    STOPPED: 0,
    SCHEDULED: 1,
    ACTIVE: 2,
    COMPLETED: 4,
};

export const REMINDER_DELAY_TYPE = {
    MINUTE: 1,
    HOUR: 2,
    DAY: 3,
};

export const EVENT_TYPE = {
    SCHEDULE: 1,
    REMINDER: 2,
};

export const AUTOMATION_SCHEDULE_TYPE = {
    FUTURE: 0,
    NOW: 1,
    DOWNLOAD_LINKS: 2,
};

export const SUBJECT_TYPE = {
    VIDEO: 1,
    SCORM: 2,
    DECISION: 3,
    SCORM_QUIZ: 4,
};

export const SCHEDULE_FREQUENCY = {
    WEEKLY: 'WEEKLY',
    BIWEEKLY: 'BIWEEKLY',
    MONTHLY: 'MONTHLY',
};

export const SCHEDULE_DAYS = [
    { id: 'MO', name: 'Monday' },
    { id: 'TU', name: 'Tuesday' },
    { id: 'WE', name: 'Wednesday' },
    { id: 'TH', name: 'Thursday' },
    { id: 'FR', name: 'Friday' },
    { id: 'SA', name: 'Saturday' },
    { id: 'SU', name: 'Sunday' },
];

export const SCHEDULE_HOURS = [
    { id: 0, name: '00:00' },
    { id: 1, name: '01:00' },
    { id: 2, name: '02:00' },
    { id: 3, name: '03:00' },
    { id: 4, name: '04:00' },
    { id: 5, name: '05:00' },
    { id: 6, name: '06:00' },
    { id: 7, name: '07:00' },
    { id: 8, name: '08:00' },
    { id: 9, name: '09:00' },
    { id: 10, name: '10:00' },
    { id: 11, name: '11:00' },
    { id: 12, name: '12:00' },
    { id: 13, name: '13:00' },
    { id: 14, name: '14:00' },
    { id: 15, name: '15:00' },
    { id: 16, name: '16:00' },
    { id: 17, name: '17:00' },
    { id: 18, name: '18:00' },
    { id: 19, name: '19:00' },
    { id: 20, name: '20:00' },
    { id: 21, name: '21:00' },
    { id: 22, name: '22:00' },
    { id: 23, name: '23:00' },
];

export const SCHEDULE_DAY_POS = [
    { id: 1, name: 'First' },
    { id: 2, name: 'Second' },
    { id: 3, name: 'Third' },
    { id: -1, name: 'Last' },
];

export const REPORT_EXECUTION_STATUS = {
    PLANNED: 0,
    RUNNING: 1,
    SUCCEEDED: 2,
    ERROR: 3,
};

export const FILE_TYPES = {
    CSV: 0,
    PDF: 1,
};

export const LINKS = {
    list: { url: '/users/list/', suffix: 'id' },
    reports: { url: '/reports/', suffix: 'id' },
    user: { url: '/users/user/', suffix: 'email' },
    assessment: { url: '/assessment/', suffix: 'view' },
    trainingEdit: { url: '/trainings/', suffix: 'edit' },
    training: { url: '/trainings/', suffix: 'view' },
};

export const trackingDisabled = 'Tracking of users is disabled';
