import React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container } from 'reactstrap';
import AutomationCreate from './AutomationCreate';
import AutomationList from './AutomationsListView/AutomationList';
import AutomationView from './SingleAutomation/AutomationView';
import AutomationUserDetails from './AutomationUserDetails';
import { Helmet } from 'react-helmet';
import { useStores } from '../../hooks/use-stores';
import RoleRoute from '../../hocs/RoleRoute';
import { USER_ROLE } from '../../utils/constants';

export default observer(({ companyId }) => {
    const { id } = useParams();
    const { store } = useStores();
    const { cmi5enabled } = store;

    return (
        <Container className="manager-content">
            <Helmet>
                <title>Training programs</title>
            </Helmet>
            <Routes>
                <Route
                    path="create/copy/:fromId/*"
                    element={
                        <RoleRoute>
                            <AutomationCreate
                                parentPath={'/trainings'}
                                companyId={companyId}
                                isScorm={false}
                                recommendationMode={false}
                            />
                        </RoleRoute>
                    }
                />
                <Route
                    path="create/*"
                    element={
                        <RoleRoute>
                            <AutomationCreate
                                parentPath={'/trainings'}
                                companyId={companyId}
                                isScorm={false}
                                recommendationMode={false}
                            />
                        </RoleRoute>
                    }
                />
                {cmi5enabled && (
                    <Route
                        path="create_scorm/*"
                        element={
                            <RoleRoute>
                                <AutomationCreate
                                    parentPath={'/trainings'}
                                    companyId={companyId}
                                    isScorm={true}
                                    recommendationMode={false}
                                />
                            </RoleRoute>
                        }
                    />
                )}
                <Route
                    path="edit/:editId/*"
                    element={
                        <RoleRoute>
                            <AutomationCreate
                                parentPath={'/trainings'}
                                companyId={companyId}
                                isScorm={false}
                                recommendationMode={false}
                            />
                        </RoleRoute>
                    }
                />
                <Route
                    path=":id/user/:email/*"
                    element={
                        <RoleRoute requiredRole={USER_ROLE.VIEWER}>
                            <AutomationUserDetails companyId={companyId} />
                        </RoleRoute>
                    }
                />
                <Route
                    path=":id/view/*"
                    element={
                        <RoleRoute requiredRole={USER_ROLE.VIEWER}>
                            <AutomationView companyId={companyId} />
                        </RoleRoute>
                    }
                />
                <Route
                    path="active"
                    element={
                        <AutomationList tab="active" companyId={companyId} />
                    }
                />
                <Route
                    path="completed"
                    element={
                        <AutomationList tab="completed" companyId={companyId} />
                    }
                />
                <Route
                    path="draft"
                    element={
                        <RoleRoute>
                            <AutomationList tab="draft" companyId={companyId} />
                        </RoleRoute>
                    }
                />
                <Route
                    path="*"
                    element={<AutomationList tab="all" companyId={companyId} />}
                />
            </Routes>
        </Container>
    );
});
