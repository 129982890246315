import enLang from 'validatorjs/src/lang/en';
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';

const plugins = {
    dvr: dvr({
        package: validatorjs,
        extend: ({ validator }) => {
            validator.setMessages('en', enLang);

            const messages = validator.getMessages('en');
            messages.required = 'You need to enter some information to save';
            validator.setMessages('en', messages);
        },
    }),
};

export default plugins;
