import Form from 'mobx-react-form';
import plugins from './plugins';

const fields = {
    name: {
        label: 'Company name',
        rules: 'string|required|between:4,150',
        placeholder: 'Company name',
    },
    theme: {
        label: 'Theme',
        rules: 'required',
        value: '1',
        placeholder: 'Company theme',
        extra: [
            { value: '1', label: 'Dark' },
            { value: '2', label: 'Light' },
        ],
    },
};

class CompanyForm extends Form {
    constructor(setup, options) {
        super({ fields, ...setup }, { plugins, ...options });
    }
}

export default CompanyForm;
