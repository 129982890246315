import React from 'react';
import { Avatar, Checkbox, FormControl, ListItemAvatar, MenuItem, Select } from '@mui/material';
import { observer } from 'mobx-react';
import { FlexDiv, TextDefault, TextLead } from '@awarego/awarego-components';

function SettingMultiSelect(
    {
        name,
        value,
        action,
        options,
        isLast,
        isPartnerCompanyDetails,
        maxCharacters = 30,
        noSavedMark,
        py,
        textLead,
    }) {

    const [savedMark, setSavedMark] = React.useState(false);

    const onChange = async (event) => {
        const target = event.target;
        setSavedMark(false);

        await action(target.value); // Waits for debounce to fully resolve via promise
        setSavedMark(true);
    };



    return (
        <FlexDiv borderBottom={!isLast}  py={py ? py : isPartnerCompanyDetails ? 20 : "0"}
        >
            <FlexDiv flexGrow={1} alignCenter>
                {textLead ?
                (
                    <TextLead bold>{name}</TextLead>
                ) : isPartnerCompanyDetails ? (
                    <TextDefault lighter>{name}</TextDefault>
                ) : (
                    <TextLead bold lighter>
                        {name}
                    </TextLead>
                )}
            </FlexDiv>
            <FlexDiv flexGrow={2} leftIndent />
            <FlexDiv flexGrow={1} alignCenter style={{ position: "relative" }}>
                <FormControl variant="outlined" color="primary" style={{ minWidth: 240}}>
                    <Select
                        multiple
                        value={value}
                        onChange={(e) => onChange(e)}
                        renderValue={(selected) => {
                            const selectedOptions = options.filter((list) => selected.includes(list.value));

                            let displayText = "";
                            let visibleOptions = [];
                            let moreCount = 0;

                            for (const list of selectedOptions) {
                                const itemText = list.name;
                                const potentialText = visibleOptions.length === 0 ? itemText : `${displayText}, ${itemText}`;
                                const remainingSpace = maxCharacters - (`${moreCount} more`.length + 2);

                                if (potentialText.length <= remainingSpace) {
                                    visibleOptions.push(itemText);
                                    displayText = potentialText;
                                } else {
                                    moreCount = selectedOptions.length - visibleOptions.length;
                                    break;
                                }
                            }


                            return (
                                <>
                                    {visibleOptions.join(", ")}
                                    {moreCount > 0 && `, ${moreCount} more`}
                                </>
                            );
                        }}
                    >
                        {options.map((list) => (
                            <MenuItem key={list.key} value={list.value} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                <Checkbox checked={value.includes(list.value)} color="primary" />
                                {list.img && (
                                    <ListItemAvatar sx={{ minWidth: 32 }}>
                                        <Avatar src={list.img} alt="" sx={{ width: 24, height: 24 }} />
                                    </ListItemAvatar>
                                )}
                                {list.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {savedMark && !noSavedMark && (
                    <TextDefault themed bold ml={16} style={{ position: "absolute", left: "-70px" }}>
                        Saved!
                    </TextDefault>
                )}
            </FlexDiv>
        </FlexDiv>
    );
}

export default observer(SettingMultiSelect);
