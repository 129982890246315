import { makeAutoObservable, runInAction } from 'mobx';
import services from '../services';
import { extractErrorMessage } from '../utils/helpers';
import {
    generateLoadList,
    generateCreateEntity,
    generateDeleteEntity,
    generateUpdateEntity,
} from '../utils/mobx';
import keyBy from 'lodash/keyBy';
import debounce from 'lodash/debounce';

const upper = (lower) => lower.replace(/^\w/, (c) => c.toUpperCase());

class CompanyStore {
    companies = [];
    savingEmailSettings = [];
    savingEnrolmentSetting = [];
    updatingLanguageSettings = false;
    addingManager = false;
    loadingCompanies = false;
    creatingCompany = false;
    loadingIntegrations = false;
    savingLanguage = false;
    savingCompany = false;
    syncRunning = false;
    uploadingLogo = false;
    switchingTrackingAnonymous = false;
    company = null;
    error = null;
    integrationError = null;
    googleError = null;
    azureError = null;
    azuressoError = null;
    slackError = null;
    addManagerError = null;
    loadingApiKeys = null;
    createApiKey = false;
    apiKeys = [];
    deletingApiKeys = [];
    deletingApiKey = null;
    deletingIntegration = null;
    updatingApiKeys = [];

    constructor(mainStore, subscriptionStore, commonStore) {
        [
            'azure',
            'google',
            'slack',
            'azuresso',
            'teams',
            'vanta',
            'vantasync',
        ].forEach((name) => {
            this[`${name}Datas`] = [];
            this[`loading${upper(name)}Datas`] = [];
            this[`loading${upper(name)}Groups`] = [];
            this[`saving${upper(name)}Data`] = [];
            this[`testing${upper(name)}Data`] = [];
            this[`${name}Groups`] = [];
        });
        makeAutoObservable(this);
        this.mainStore = mainStore;
        this.subscriptionStore = subscriptionStore;
        this.commonStore = commonStore;

        this.loadIntegrations = generateLoadList(
            'integrations',
            this,
            'loadingIntegrations',
            async (companyId, options) => {
                return services.Companies.integrationsService(companyId).list(
                    options
                );
            },
            'integrations'
        );
    }

    loadApiKeys = generateLoadList(
        'apikeys',
        this,
        'loadingApiKeys',
        async (companyId) => {
            return services.Companies.apiKeysService(companyId).list();
        },
        'apiKeys'
    );

    createNewApiKey = generateCreateEntity(
        'createApiKey',
        this,
        'creatingApiKey',
        async (companyId) => {
            const newKey = await services.Companies.apiKeysService(
                companyId
            ).create({});
            this.apiKeys.push(newKey);
            return this.apiKeys;
        },
        'apiKeys'
    );

    deleteApiKey = generateDeleteEntity(
        'deleteApiKey',
        this,
        'deletingApiKey',
        async (key, __, options) => {
            await services.Companies.apiKeysService(options.companyId).delete({
                id: key,
            });
            this.apiKeys = this.apiKeys.filter((x) => x.value !== key);
        }
    );

    setApiKeyName(companyId, id, value) {
        let apiKey = this.apiKeys.find((x) => x.id === id);
        if (apiKey) {
            apiKey.name = value;
            this.updateApiKeyName(companyId, id, value);
        }
    }

    updateApiKeyName = debounce(
        generateUpdateEntity(
            'apikeys',
            this,

            'updatingApiKeys',
            async (companyId, id, name) => {
                return services.Companies.apiKeysService(companyId).update(
                    { name },
                    id
                );
            }
        ),
        300
    );

    setError(error, name) {
        switch (name) {
            case 'integrations':
                this.integrationError = error;
                break;
            default:
                this.error = error;
        }
    }

    async loadCompanies(background = false) {
        if (!background) {
            this.loadingCompanies = true;
            this.error = null;
            this.companies.clear();
        }

        try {
            const result = await services.Companies.myCompanies();
            this.companies.replace(result);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingCompanies = false;
        }
    }

    async switchTrackingAnonymous(companyId) {
        this.switchingTrackingAnonymous = true;
        this.error = null;
        try {
            await services.Companies.switchTrackingAnonymous(companyId);
            let company = this.companies.find(
                (x) => x.company_id === companyId
            );
            if (company) company.anonymousTracking = true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.switchingTrackingAnonymous = false;
        }
    }

    async changeLanguage(companyId, value) {
        this.savingLanguage = true;
        this.error = null;
        try {
            await services.Companies.update(
                { defaultLanguage: value },
                companyId
            );
            let company = this.companies.find(
                (x) => x.company_id === companyId
            );
            if (company) company.defaultLanguage = value;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingLanguage = false;
        }
    }

    async openManageSession(companyId) {
        return services.Companies.openManageSession(companyId);
    }

    async handleCreateCompany(partnerId, onFinish) {
        this.creatingCompany = true;
        let result = await this.create(
            {
                partnerId,
                companyName:
                    this.subscriptionStore.companyForm.values().companyName,
                subscriptionCode:
                    this.subscriptionStore.companyForm.values().subscription,
                seats: this.subscriptionStore.companyForm.values().seats,
                validTo: this.subscriptionStore.companyForm.values().valid,
                validTill:
                    this.subscriptionStore.companyForm.values().validTill,
            },
            true
        );

        if (result) {
            this.creatingCompany = false;
            if (onFinish) onFinish();
            this.commonStore.success('Company created.');
        } else {
            this.creatingCompany = false;
            if (this.error) {
                this.commonStore.error(this.error);
            }
        }
    }

    async create(values, loadCompany) {
        this.savingCompany = true;
        try {
            const newCompanyId = values.partnerId
                ? await services.Partners.companiesService(
                      values.partnerId
                  ).create(values)
                : await services.Companies.create(values);
            if (newCompanyId && loadCompany) {
                this.loadCompanies(true);
                const result = await services.Companies.myCompanies();
                this.companies.replace(result);
                let company = this.companies.find(
                    (x) => x.company_id === newCompanyId
                );
                if (company) {
                    this.setCompany(company);
                }
            }

            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingCompany = false;
        }
    }

    async save(companyId, values) {
        this.savingCompany = true;
        try {
            let result = await services.Companies.update({
                id: companyId,
                ...values,
            });
            let company = this.companies.find(
                (x) => x.company_id === companyId
            );
            if (company)
                Object.assign(company, result, { company_name: result.name });
            return true;
        } catch (e) {
            //console.error('Save failed: ', e);
            this.error = extractErrorMessage(e);
        } finally {
            this.savingCompany = false;
        }
    }

    saveLanguageSettings = debounce(
        generateUpdateEntity(
            'languageSettings',
            this,
            'updatingLanguageSettings',
            async (companyId, values, callback) => {
                let body = { 'languages.disabled': [] };
                values
                    .filter((lang) => !lang.enabled)
                    .forEach((x) => {
                        body[`languages.disabled`].push(x.code);
                    });
                let result = await services.Companies.update({
                    id: companyId,
                    ...body,
                });
                let company = this.companies.find(
                    (x) => x.company_id === companyId
                );

                if (company) {
                    Object.assign(company, result);
                }
                if (callback) callback();
            }
        ),
        600
    );

    async saveEmailSettings(companyId, values) {
        if (this.savingEmailSettings.includes(companyId)) return false;
        this.savingEmailSettings.push(companyId);
        try {
            let body = {
                'ui.email.trainingHeader': values.trainingHeader,
                'ui.email.trainingFooter': values.trainingFooter,
                'ui.email.reminderHeader': values.reminderHeader,
                'ui.email.reminderFooter': values.reminderFooter,
                'ui.email.certificate': values.certificate,
                'ui.email.assessmentHeader': values.assessmentHeader,
                'ui.email.assessmentFooter': values.assessmentFooter,
                'ui.email.welcomeMessage': values.welcomeMessage,
                'ui.email.mrkdwn_trainingHeader': values.mrkdwn_trainingHeader,
                'ui.email.mrkdwn_trainingFooter': values.mrkdwn_trainingFooter,
                'ui.email.mrkdwn_reminderHeader': values.mrkdwn_reminderHeader,
                'ui.email.mrkdwn_reminderFooter': values.mrkdwn_reminderFooter,
                'ui.email.mrkdwn_certificate': values.mrkdwn_certificate,
                'ui.email.mrkdwn_assessmentHeader':
                    values.mrkdwn_assessmentHeader,
                'ui.email.mrkdwn_assessmentFooter':
                    values.mrkdwn_assessmentFooter,
                'ui.email.mrkdwn_welcomeMessage': values.mrkdwn_welcomeMessage,
            };
            let result = await services.Companies.update({
                id: companyId,
                ...body,
            });
            let company = this.companies.find(
                (x) => x.company_id === companyId
            );
            if (company) {
                Object.assign(company, result);
            }
            if (this.mainStore.currentCompany) {
                const settingsObj =
                    this.mainStore.currentCompany.uiSettings ||
                    this.mainStore.currentCompany.settings;

                settingsObj['ui.email.trainingHeader'] = values.trainingHeader;
                settingsObj['ui.email.trainingFooter'] = values.trainingFooter;
                settingsObj['ui.email.reminderHeader'] = values.reminderHeader;
                settingsObj['ui.email.reminderFooter'] = values.reminderFooter;
                settingsObj['ui.email.certificate'] = values.certificate;
                settingsObj['ui.email.assessmentHeader'] =
                    values.assessmentHeader;
                settingsObj['ui.email.assessmentFooter'] =
                    values.assessmentFooter;
                settingsObj['ui.email.mrkdwn_trainingHeader'] =
                    values.mrkdwn_trainingHeader;
                settingsObj['ui.email.mrkdwn_trainingFooter'] =
                    values.mrkdwn_trainingFooter;
                settingsObj['ui.email.mrkdwn_reminderHeader'] =
                    values.mrkdwn_reminderHeader;
                settingsObj['ui.email.mrkdwn_reminderHeader'] =
                    values.mrkdwn_reminderHeader;
                settingsObj['ui.email.mrkdwn_certificate'] =
                    values.mrkdwn_certificate;
                settingsObj['ui.email.mrkdwn_assessmentHeader'] =
                    values.mrkdwn_assessmentHeader;
                settingsObj['ui.email.mrkdwn_assessmentFooter'] =
                    values.mrkdwn_assessmentFooter;
            }
        } catch (e) {
            //console.error('Save failed: ', e);
            this.error = extractErrorMessage(e);
        } finally {
            this.savingEmailSettings.remove(companyId);
        }
    }

    async saveEnrolmentSetting(companyId, value) {
        if (this.savingEnrolmentSetting.includes(companyId)) return false;
        this.savingEnrolmentSetting.push(companyId);
        try {
            let body = { 'ui.sendEnrolment': value };
            let result = await services.Companies.update({
                id: companyId,
                ...body,
            });
            let company = this.companies.find(
                (x) => x.company_id === companyId
            );
            if (company) {
                Object.assign(company, result);
            }
            if (this.mainStore.currentCompany) {
                const settingsObj =
                    this.mainStore.currentCompany.uiSettings ||
                    this.mainStore.currentCompany.settings;
                settingsObj['ui.sendEnrolment'] = value;
            }
        } catch (e) {
            //console.error('Save failed: ', e);
            this.error = extractErrorMessage(e);
        } finally {
            this.savingEnrolmentSetting.remove(companyId);
        }
    }

    async uploadLogo(companyId, formData) {
        this.uploadingLogo = true;
        try {
            let result = await services.Resources.addLogo(companyId, formData);
            let company = this.companies.find(
                (x) => x.company_id === companyId
            );
            if (company) {
                Object.assign(company, result, { logo_name: result.logo_name });
            }
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.uploadingLogo = false;
        }
    }

    async uploadLogo2(company, formData) {
        this.uploadingLogo = true;
        try {
            let result = await services.Resources.addLogo(company.id, formData);
            //let company = this.companies.find(x => x.company_id === companyId);
            if (company) {
                Object.assign(company, result, { logo_name: result.logo_name });
            }
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.uploadingLogo = false;
        }
    }

    async removeLogo(companyId) {
        try {
            let result = await services.Resources.removeLogo(companyId);
            let company = this.companies.find(
                (x) => x.company_id === companyId
            );
            if (company) {
                Object.assign(company, result, { logo_name: null });
            }
            //await services.Resources.removeLogo(companyId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    async addManager(companyId, data) {
        this.addingManager = true;
        this.addManagerError = null;
        try {
            let result = await services.Companies.managersService(
                companyId
            ).create(data);
            let company = this.companies.find(
                (x) => x.company_id === companyId
            );
            if (company) {
                if (!company.managers) company.managers = [];

                company.managers.push(result);
            }
            await this.loadCompanies(true);
            return true;
        } catch (e) {
            this.addManagerError = extractErrorMessage(e);
        } finally {
            this.addingManager = false;
        }
    }

    async removeManager(companyId, email) {
        this.error = null;
        try {
            await services.Companies.managersService(companyId).delete(email);
            let company = this.companies.find(
                (x) => x.company_id === companyId
            );
            if (company && company.managers) {
                runInAction(() => {
                    company.managers = company.managers.filter(
                        (manager) => manager.email !== email
                    );
                });
            }
            return this.loadCompanies(true);
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    setCompany = (company) => {
        this.company = company;
    };

    async loadIntegrationData(companyId, integration) {
        let loadingKey = `loading${upper(integration)}Datas`;
        let dataKey = `${integration}Datas`;
        if (this[loadingKey].includes(companyId)) return false;
        this[loadingKey].push(companyId);
        try {
            let data = await services.Companies.integrationsService(
                companyId
            ).fetch(integration); //videoId
            let t = this[dataKey].find((x) => x.id === companyId);
            if (t) this[dataKey].remove(t);
            this[dataKey].push({ id: companyId, ...data });
        } finally {
            this[loadingKey].remove(companyId);
        }
    }

    async loadIntegrationGroups(companyId, integration) {
        let key = `loading${upper(integration)}Groups`;
        let errorKey = `${integration}Error`;

        if (this[key].includes(companyId)) return false;
        this[errorKey] = '';

        this[key].push(companyId);
        try {
            let data = await services.Companies.integrationsService(
                companyId
            ).integrationGroups(integration); //videoId
            this[`${integration}Groups`].replace(data);
            return data;
        } catch (e) {
            this[errorKey] = extractErrorMessage(e);
        } finally {
            this[key].remove(companyId);
        }
    }

    async saveIntegrationData(companyId, integration, data) {
        let key = `saving${upper(integration)}Data`;
        let dataKey = `${integration}Datas`;
        if (this[key].includes(companyId)) return false;

        this[key].push(companyId);
        try {
            let response = await services.Companies.integrationsService(
                companyId
            ).update(data, integration); //videoId

            let t = this[dataKey].find((x) => x.id === companyId);
            if (t) Object.assign(t, response);
            else this[dataKey].push({ id: companyId, ...response });
        } finally {
            this[key].remove(companyId);
        }
    }

    async saveIntegrationRule(
        companyId,
        integration,
        entityType,
        externalId,
        rule
    ) {
        try {
            await services.Companies.integrationsService(
                companyId
            ).saveIntegrationRule(integration, {
                entityType,
                externalId,
                rule,
            });

            if (entityType === 1) {
                let g = this[`${integration}Groups`].find(
                    (x) => x.id === externalId
                );
                if (g) g.rule = rule;
            }
        } catch (e) {
            // Intentionally suppressed
        }
    }

    async saveIntegrationRules(companyId, integration, entityType, values) {
        try {
            await services.Companies.integrationsService(
                companyId
            ).saveIntegrationRules(integration, { entityType, values });
            return true;
        } catch (e) {
            // Intentionally suppressed
        }
    }

    async testIntegration(companyId, integration, data) {
        let key = `testing${upper(integration)}Data`;

        if (this[key].includes(companyId)) return false;

        this[key].push(companyId);
        try {
            let response = await services.Companies.integrationsService(
                companyId
            ).testIntegrationData(integration, data);
            return response;
        } finally {
            this[key].remove(companyId);
        }
    }

    unlinkIntegration = generateDeleteEntity(
        'deleteIntegration',
        this,
        'deletingIntegration',
        async (companyId, integration) => {
            return services.Companies.integrationsService(companyId).delete(
                integration
            );
        }
    );

    async syncIntegration(companyId, integration, data) {
        if (this.syncRunning) return;
        this.syncRunning = true;
        try {
            let response = await services.Companies.integrationsService(
                companyId
            ).syncIntegration(integration, data);
            if (response.lastSync || response.latestRunningSyncId) {
                let existing = this[`${integration}DataIndex`][companyId];
                if (existing) {
                    Object.assign(existing, response);
                }
            }

            return response;
        } finally {
            this.syncRunning = false;
        }
    }

    updateSyncdata(companyId, data) {
        //console.log('updateSyncdata', companyId, data);
        if (this.azureDataIndex[companyId])
            Object.assign(this.azureDataIndex[companyId], data);
        if (this.googleDataIndex[companyId])
            Object.assign(this.googleDataIndex[companyId], data);
    }

    removeUserFromExcludedList(companyId, product, id) {
        const data = this[`${product}DataIndex`][companyId];
        if (data && data.excludedUsers) {
            data.excludedUsers.replace(
                data.excludedUsers.filter((x) => x.id !== id)
            );
        }
    }

    get azureDataIndex() {
        return keyBy(this.azureDatas, 'id');
    }

    get vantaDataIndex() {
        return keyBy(this.vantaDatas, 'id');
    }

    get vantasyncDataIndex() {
        return keyBy(this.vantasyncDatas, 'id');
    }

    get teamsDataIndex() {
        return keyBy(this.teamsDatas, 'id');
    }

    get azuressoDataIndex() {
        return keyBy(this.azuressoDatas, 'id');
    }

    get googleDataIndex() {
        return keyBy(this.googleDatas, 'id');
    }

    get slackDataIndex() {
        return keyBy(this.slackDatas, 'id');
    }

    get connectedIntegrations() {
        if (this.integrations.length === 0) return [];
        return this.integrations.filter((integration) => {
            return integration.connected;
        });
    }

    get availableIntegrations() {
        if (this.integrations.length === 0) return [];
        let result = this.integrations.filter((integration) => {
            if (integration.id === 'teams' || integration.id === 'google') {
                return !integration.connected && integration.enabled === '0';
            } else {
                return (
                    !integration.connected &&
                    !(
                        integration.enabled === '0' &&
                        integration.enabledMY === '0' &&
                        integration.enabledLMS === '0'
                    )
                );
            }
        });
        this.mainStore.havePartnerSubscription &&
            result.forEach(
                (integration) =>
                    (integration.needsUpgrade = !(
                        this.mainStore.currentCompany.subscription
                            .allowedItems ||
                        this.mainStore.currentCompany.subscription
                            .subscription_definition
                    ).integrations.includes(integration.id))
            );
        return result;
    }
}

export default CompanyStore;
