import React, { useEffect, useState, Fragment, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/use-stores';
import { Row, Col } from 'reactstrap';
import ThemedSpinner from '../../../components/themed/ThemedSpinner';
import AutomationListTable from './AutomationListTable';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import FilterModal from '../../../components/manager/filter-modal';
import {
    ThemedNav,
    ThemedNavItem,
    ThemedNavLink,
} from '../../../components/themed/ThemedComponents';
import { MenuItem } from '@mui/material';
import Snackbar from '../../../components/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import './AutomationList.scss';
import ErrorMessage from '../../../components/errormessage';
import NoItemsYet from '../../../components/manager/no-items-yet';
import LinkIfNeed from '../../../components/LinkIfNeed';
import {
    Block,
    FlexDiv,
    SpaceBetweenDiv,
    TextDefault,
    TextInput,
    Heading3,
    ThemedButton,
    palette,
    TextTiny,
} from '@awarego/awarego-components';
import HeaderSection from '../../../components/header/HeaderSection';
import CreateAssessmentButton from '../../../components/CreateAssessmentButton';

function AutomationList({ companyId, tab = 'all' }) {
    const {
        automationStore,
        listStore,
        commonStore,
        catalogStore,
        store,
        brandingStore,
        cmi5Store,
    } = useStores();
    const {
        loadingAutomations,
        automations,
        dismissedFirstTraining,
        loadingAutomationsInBackground,
        searchValue,
        subjectFilter,
        employeeFilter,
        sortBy,
        filteredAutomations,
        fromStartDateFilter,
        toStartDateFilter,
        fromEndDateFilter,
        toEndDateFilter,
        demoTrainingURL,
        error,
    } = automationStore;
    const { allSubjectsIndex } = catalogStore;
    const {
        trainingProgramsUnavailable,
        trainingUpgradeAllowed: needsUpgrade,
        cmi5enabled,
    } = store;
    const { supportEmail } = brandingStore;
    const [filterModal, setFilterModal] = useState(false);
    const { listsWithUsers: lists } = listStore;

    const [message, setMesage] = useState(null);
    const [messageShown, setMessageShown] = useState(false);
    const [messageType, setMessageType] = useState(null);

    useEffect(() => {
        automationStore.loadAutomations(companyId);
        listStore.loadCompanyLists(companyId);
        catalogStore.loadCatalog(companyId);
        catalogStore.loadThreatAreaCatalog(companyId);
    }, [automationStore, catalogStore, companyId, listStore]);

    const filterCount = [
        subjectFilter.length ? 1 : 0,
        employeeFilter.length ? 1 : 0,
        fromStartDateFilter !== null && toStartDateFilter !== null ? 1 : 0,
        fromEndDateFilter !== null && toEndDateFilter !== null ? 1 : 0,
    ].reduce((a, b) => a + b);

    const clearFilters = () => {
        automationStore.setSubjectFilter([]);
        automationStore.setEmployeeFilter([]);
        automationStore.setFromEndDateFilter(null);
        automationStore.setToEndDateFilter(null);
        automationStore.setFromStartDateFilter(null);
        automationStore.setToStartDateFilter(null);
    };

    const hideFilterModal = () => {
        setFilterModal(false);
    };

    const showFilterModal = () => {
        setFilterModal(true);
    };

    const showSnackBar = (message, messageType = 'success') => {
        setMesage(message);
        setMessageType(messageType);
        setMessageShown(true);
    };

    const closeSnackbar = () => {
        setMessageShown(false);
        setMesage(null);
        setMessageType(null);
    };

    const onDelete = (id, isScorm) => {
        commonStore.showConfirm(
            'Training will be deleted forever.',
            'Yes, delete',
            'Delete this training?',
            async () => {
                await automationStore.delete(
                    companyId,
                    isScorm ? `p${id}` : id
                );
                automationStore.loadAutomations(companyId);
                showSnackBar('Training deleted!');
            },
            true
        );
    };

    const onPause = (id) => {
        commonStore.showConfirm(
            'Are you sure you want to stop this training?',
            'Stop',
            'Stop training',
            async () => {
                const result = await automationStore.pauseAutomation(
                    companyId,
                    id,
                    true
                );
                if (result) showSnackBar('Training stopped!');
            },
            true
        );
    };

    const onResume = (id) => {
        commonStore.showConfirm(
            'Are you sure you want to resume this training?',
            'Resume',
            'Resume training',
            async () => {
                const result = await automationStore.resumeAutomation(
                    companyId,
                    id,
                    true
                );
                if (result) showSnackBar('Training resumed!');
            },
            true
        );
    };

    const onDownloadPackage = (id, uniqueId, type) => {
        cmi5Store.downloadZIP(companyId, id, uniqueId, type);
    };

    const tabs = useMemo(() => {
        return [
            {
                code: 'all',
                title: 'All',
            },
            {
                code: 'active',
                title: 'Active',
            },
            {
                code: 'completed',
                title: 'Completed',
            },
            {
                code: 'draft',
                title: 'Draft',
                visible: store.isAdmin,
            },
        ];
    }, [store.isAdmin]);

    const visibleTabs = useMemo(() => {
        return tabs.filter((tab) => tab.visible !== false);
    }, [tabs]);

    const sortOptions = [
        {
            value: '_rawData.updated',
            text: 'Last updated',
        },
        {
            value: '_rawData.firstBatch',
            text: 'Start date',
        },
        {
            value: '_rawData.lastBatch',
            text: 'End date',
        },
    ];

    const addHeaderSection = () => {
        return (
            <HeaderSection
                heading={'Training programs'}
                searchValue={searchValue || ''}
                searchPlaceholder={'Search by name'}
                onSearch={(e) => automationStore.setSearchValue(e.target.value)}
                onClearSearch={() => automationStore.setSearchValue('')}
                onShowFilter={showFilterModal}
                filterCount={filterCount}
                onClearFilters={clearFilters}
                buttons={<CreateAssessmentButton cmi5enabled={cmi5enabled} />}
            />
        );
    };

    const getTabCount = (t) => {
        const count = automationStore[`${t.code}Tab`].length;
        const badge = (
            <TextTiny
                bold
                padding={4}
                backgroundColor={palette.graphite.gray}
                color={palette.graphite.charcoal}
                ml={8}
            >
                {count}
            </TextTiny>
        );

        return badge;
    };

    if (trainingProgramsUnavailable) {
        return (
            <NoItemsYet
                img={'/static/img/training-locked.svg'}
                imgAlt={'training programs are unavailable'}
                title={'Training programs are unavailable in your current plan'}
                text={
                    'Please upgrade your subscription to access the training programs.'
                }
                heading={'Training programs'}
                buttons={
                    needsUpgrade ? (
                        <FlexDiv gap={24}>
                            <ThemedButton
                                as="a"
                                href={demoTrainingURL}
                                target="_blank"
                                rel="noreferrer"
                                mode="secondary"
                                text="Try demo"
                            />
                            <LinkIfNeed link={'/subscriptions/upgrade'}>
                                <ThemedButton
                                    mode="primary"
                                    text="Upgrade now"
                                />
                            </LinkIfNeed>
                        </FlexDiv>
                    ) : (
                        <FlexDiv gap={24}>
                            <ThemedButton
                                as="a"
                                href={demoTrainingURL}
                                target="_blank"
                                rel="noreferrer"
                                mode="secondary"
                                text="Try demo"
                            />
                            <ThemedButton
                                mode="primary"
                                as="a"
                                href={`mailto:${supportEmail}`}
                                text="Contact to upgrade"
                            />
                        </FlexDiv>
                    )
                }
            />
        );
    } else
        return loadingAutomations ? (
            <>
                {addHeaderSection()}
                <ThemedSpinner />
            </>
        ) : (
            <Fragment>
                <ErrorMessage error={error} />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    autoHideDuration={5000}
                    variant={messageType}
                    message={message || ''}
                    open={messageShown}
                    onClose={closeSnackbar}
                />
                {automations.length === 1 && !dismissedFirstTraining && (
                    <Row>
                        <Col md="12" xs="12">
                            <div className={'top-guide'}>
                                <div className={'block alert success'}>
                                    <div className="close">
                                        <img
                                            src="/static/img/close.svg"
                                            alt="Close the alert"
                                            onClick={() => {
                                                automationStore.dismissFirstTraining();
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <div className="alert-heading">
                                            Your first training program has been
                                            created.
                                        </div>
                                        <p>
                                            Once employees start their training
                                            the stats will increase, helping you
                                            monitor their progress.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
                {addHeaderSection()}
                {automations.length > 0 && (
                    <Fragment>
                        <Block tablePadding>
                            <SpaceBetweenDiv positionRelative ml={16} pr={32}>
                                {loadingAutomationsInBackground && (
                                    <CircularProgress
                                        size={15}
                                        className={'background-loader'}
                                        color="primary"
                                    />
                                )}
                                <ThemedNav clean>
                                    {visibleTabs.map((t) => (
                                        <ThemedNavItem
                                            active={tab === t.code}
                                            key={t.code}
                                        >
                                            <ThemedNavLink
                                                to={`/trainings/${t.code}`}
                                                as={Link}
                                            >
                                                <FlexDiv alignCenter>
                                                    {t.title} {getTabCount(t)}
                                                </FlexDiv>
                                            </ThemedNavLink>
                                        </ThemedNavItem>
                                    ))}
                                </ThemedNav>
                                {filteredAutomations.length > 0 && (
                                    <FlexDiv alignCenter>
                                        <TextDefault lighter mr={8}>
                                            Sort by:
                                        </TextDefault>
                                        <TextInput
                                            select
                                            inline
                                            value={sortBy}
                                            onChange={(e) => {
                                                automationStore.setSortBy(
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            {sortOptions.map((p) => (
                                                <MenuItem
                                                    key={p.value}
                                                    value={p.value}
                                                >
                                                    {p.text}
                                                </MenuItem>
                                            ))}
                                        </TextInput>
                                    </FlexDiv>
                                )}
                            </SpaceBetweenDiv>
                            {automationStore[`${tab}Tab`].length > 0 && (
                                <AutomationListTable
                                    automations={[
                                        ...automationStore[`${tab}Tab`],
                                    ]}
                                    onDelete={onDelete}
                                    pauseAutomation={onPause}
                                    resumeAutomation={onResume}
                                    lists={lists}
                                    onDownloadPackage={onDownloadPackage}
                                    isPackageDownloading={
                                        cmi5Store.isZIPDownloading
                                    }
                                    defaultSortBy={sortBy}
                                />
                            )}

                            {automationStore[`${tab}Tab`].length === 0 && (
                                <div className="no-assessments-found">
                                    <img
                                        src="/static/img/icon-booklist.svg"
                                        alt="No training programs found"
                                    />
                                    <div className="stats">
                                        <Heading3 displayBlock mt={16} mb={8}>
                                            No training programs found
                                        </Heading3>
                                        <p>
                                            Try adjusting your search or filter
                                            to find what you were looking for.
                                        </p>
                                    </div>
                                </div>
                            )}
                        </Block>
                    </Fragment>
                )}
                {automations.length === 0 && (
                    <NoItemsYet
                        img={'/static/img/training-empty.svg'}
                        imgAlt={'sneak peak into a training program'}
                        title={'No training programs yet'}
                        text={
                            'Build security culture and keep security awareness high with our behavior-changing training programs.'
                        }
                        heading={'Training programs'}
                        buttons={
                            <FlexDiv gap={24}>
                                <ThemedButton
                                    mode="primary"
                                    as={Link}
                                    to={`/trainings/create`}
                                    text="Create new"
                                />
                                <ThemedButton
                                    as="a"
                                    href={demoTrainingURL}
                                    target="_blank"
                                    rel="noreferrer"
                                    mode="secondary"
                                    text="Try demo"
                                />
                            </FlexDiv>
                        }
                    />
                )}
                {lists && lists.length > 0 && (
                    <Modal
                        isOpen={filterModal}
                        onRequestClose={hideFilterModal}
                        className="filter-modal"
                        ariaHideApp={false}
                    >
                        <FilterModal
                            onRequestClose={hideFilterModal}
                            resultCount={automationStore[`${tab}Tab`].length}
                            nameFilter1="Subjects"
                            array1={Object.values(allSubjectsIndex)
                                .filter((subject) => subject && subject.title)
                                .sort((a, b) =>
                                    (a.title || '').localeCompare(b.title || '')
                                )}
                            filter1={subjectFilter.slice(0)}
                            setFilter1={(value) => {
                                automationStore.setSubjectFilter(value);
                            }}
                            nameFilter2="Employee lists"
                            array2={lists}
                            filter2={employeeFilter.slice(0)}
                            setFilter2={(value) => {
                                automationStore.setEmployeeFilter(value);
                            }}
                            nameDateFilter1={'Start date'}
                            startDateFilter1={fromStartDateFilter}
                            setStartDateFilter1={(value) => {
                                // expl: date picker does not have time setter, current time is set by default
                                // which filters out trainings before that time. To fix it, time is set manually
                                let startDateFromTimeFixed = value?.set({
                                    h: 0,
                                    m: 0,
                                });
                                automationStore.setFromStartDateFilter(
                                    startDateFromTimeFixed
                                        ? startDateFromTimeFixed
                                        : value
                                );
                            }}
                            endDateFilter1={toStartDateFilter}
                            setEndDateFilter1={(value) => {
                                let startDateToTimeFixed = value?.set({
                                    h: 23,
                                    m: 59,
                                });
                                automationStore.setToStartDateFilter(
                                    startDateToTimeFixed
                                        ? startDateToTimeFixed
                                        : value
                                );
                            }}
                            nameDateFilter2={'End date'}
                            startDateFilter2={fromEndDateFilter}
                            setStartDateFilter2={(value) => {
                                let endDateFromTimeFixed = value?.set({
                                    h: 0,
                                    m: 0,
                                });
                                automationStore.setFromEndDateFilter(
                                    endDateFromTimeFixed
                                        ? endDateFromTimeFixed
                                        : value
                                );
                            }}
                            endDateFilter2={toEndDateFilter}
                            setEndDateFilter2={(value) => {
                                let endDateToTimeFixed = value?.set({
                                    h: 23,
                                    m: 59,
                                });
                                automationStore.setToEndDateFilter(
                                    endDateToTimeFixed
                                        ? endDateToTimeFixed
                                        : value
                                );
                            }}
                            filterCount={filterCount}
                            clearFilters={clearFilters}
                        />
                    </Modal>
                )}
            </Fragment>
        );
}

export default observer(AutomationList);
