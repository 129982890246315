import { makeAutoObservable } from 'mobx';
import sumBy from 'lodash/sumBy';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import { TRANSPORTS, STATUS } from '../../utils/constants';
import moment from 'moment';
import { defaultZero } from '../../utils/helpers';
import { palette } from '@awarego/awarego-components';

const statuses = {
    [STATUS.DRAFT]: {
        label: 'Draft',
        icon: false,
        value: STATUS.DRAFT,
        color: palette.graphite.heavyMetal,
    },
    [STATUS.SCHEDULED]: {
        label: 'Scheduled',
        icon: false,
        value: STATUS.SCHEDULED,
        color: palette.vibrant.blue,
    },
    [STATUS.ACTIVE]: {
        label: 'Active',
        icon: false,
        value: STATUS.ACTIVE,
        color: palette.vibrant.orange,
    },
    [STATUS.COMPLETED]: {
        label: '✓ Completed',
        icon: true,
        value: STATUS.COMPLETED,
        color: palette.evergreen.aspargus,
    },
};

class Assessment {
    selectedQuestionsIds = [];
    selectedUserListsIds = [];
    date = null;
    name = 'Untitled Assessment';
    threatAreas = [];
    scheduleType = 1;
    channel = null;
    active = false;
    enabledLanguages = [];
    introMessageEnabled = false;
    due = null;
    id = null;
    templateCustomized = false;
    reminders_enabled = false;
    reminders_channel = null;
    reminders_steps = [{ send: null }];
    status = null;
    companyName = '';
    companyDomain = '';
    emailHR = '';
    userListSearchValue = '';
    isScorm = false;
    overrideDuration = null;

    constructor(
        listStore,
        authStore,
        mainStore,
        questionsIndex,
        assessmentData,
        companyId,
        enabledLanguages
    ) {
        makeAutoObservable(this);
        this.listStore = listStore;
        this.authStore = authStore;
        this.mainStore = mainStore;
        this.companyId = companyId;
        this.questionsIndex = questionsIndex;
        this.enabledLanguages = enabledLanguages;
        this.channel =
            window.localStorage.getItem(`assessmentChannel-${companyId}`) ||
            TRANSPORTS.EMAIL;
        this.reminders_channel =
            window.localStorage.getItem(
                `assessmentRemindersChannel-${companyId}`
            ) || TRANSPORTS.EMAIL;
        this.companyName =
            window.localStorage.getItem(`companyName-${companyId}`) || '';
        this.companyDomain =
            window.localStorage.getItem(`companyDomain-${companyId}`) || '';
        this.emailHR =
            window.localStorage.getItem(`emailHR-${companyId}`) || '';
        if (assessmentData) {
            this._rawData = assessmentData.id ? assessmentData : null;
            this.name = assessmentData.name;
            this.id = assessmentData.id;
            if (assessmentData.sendOptions?.date)
                this.date = assessmentData.sendOptions.date;
            if (assessmentData.reminders_enabled)
                this.reminders_enabled = assessmentData.reminders_enabled;
            if (assessmentData.active !== undefined)
                this.active = assessmentData.active === 1;
            if (assessmentData.definition?.questions)
                this.selectedQuestionsIds.replace(
                    assessmentData.definition.questions
                );
            if (assessmentData.definition?.userLists)
                this.selectedUserListsIds.replace(
                    assessmentData.definition.userLists
                );
            if (assessmentData.sendOptions?.channel)
                this.channel = assessmentData.sendOptions.channel;
            if (assessmentData.definition?.personalizeData) {
                this.companyName =
                    assessmentData.definition.personalizeData.companyName;
                this.companyDomain =
                    assessmentData.definition.personalizeData.companyDomain;
                this.emailHR =
                    assessmentData.definition.personalizeData.emailHR;
            }
            if (assessmentData.definition?.overrideDuration) {
                this.overrideDuration =
                    assessmentData.definition.overrideDuration;
            }
            this.scheduleType = assessmentData.sendOptions?.scheduleType;
            this.due = assessmentData.due;
            this.reminders_channel = assessmentData.reminders_channel || 1;
            if (assessmentData.reminders_steps) {
                this.reminders_steps.replace(
                    assessmentData.reminders_steps.map((r) => ({
                        ...r,
                        alreadySet: true,
                    })) || [{ send: null }]
                );
            }
            this.threatAreas = assessmentData.areas;
            this.status = assessmentData.status;
            this.generalAssessmentStatus =
                assessmentData.generalAssessmentStatus;
            if (assessmentData.templateIds) {
                this.templateCustomized = assessmentData.template_customized;
            }
            this.isScorm = assessmentData.isScorm;
        }
    }

    get resilienceScore() {
        if (!this.threatAreas) return 0;
        return Math.round(
            this.threatAreas.reduce((acc, area) => {
                return acc + area.score || 0;
            }, 0) / this.threatAreas.length
        );
    }

    setUserListSearchValue(userListSearchValue) {
        this.userListSearchValue = userListSearchValue;
    }

    setName = (value) => {
        this.name = value;
    };

    setId = (value) => {
        this.id = value;
    };

    toggleIntroMessage = () => {
        this.introMessageEnabled = !this.introMessageEnabled;
    };

    setScheduleType = (type) => {
        this.scheduleType = type;
    };

    setChannel = (value, checked) => {
        let newChannel = this.channel;
        if (checked) {
            // add checked value to channel, this is called bitwise OR
            newChannel |= value;
        } else {
            // remove unchecked value from channel, this is called bitwise NOT
            newChannel &= ~value;
        }
        if (newChannel !== 0) {
            this.channel = newChannel;
            window.localStorage.setItem(
                `assessmentChannel-${this.companyId}`,
                this.channel
            );
            return true;
        } else {
            return false;
        }
    };

    setRemindersChannel = (value, checked) => {
        let newChannel = this.reminders_channel;
        if (checked) {
            // add checked value to channel, this is called bitwise OR
            newChannel |= value;
        } else {
            // remove unchecked value from channel, this is called bitwise NOT
            newChannel &= ~value;
        }
        if (newChannel !== 0) {
            this.reminders_channel = newChannel;
            window.localStorage.setItem(
                `assessmentRemindersChannel-${this.companyId}`,
                this.reminders_channel
            );
            return true;
        } else {
            return false;
        }
    };

    setDueDate = (date) => {
        this.due = date;
    };

    setDate = (date) => {
        this.date = date;
    };

    setDurationOverride = (duration) => {
        this.overrideDuration = duration;
    };

    setRemindersEnabled = () => {
        if (!this.reminders_enabled && this.scheduleType === 0) {
            this.setReminder(
                moment(this.date).add(1, 'days').set('minute', 0),
                0
            );
        }
        this.reminders_enabled = !this.reminders_enabled;
    };

    setReminder = (date, i, reminder) => {
        if (reminder) {
            reminder.send = date;
        } else if (this.reminders_steps.length > 0) {
            this.reminders_steps[i].send = date;
        }
    };

    addReminderStep = () => {
        // if there are already some reminders, create a new reminder one day after the latest one and check, that it's not past due date, else create an empty reminder with date not set
        let dayAfterLatestReminder =
            this.reminders_steps.length > 0 &&
            moment(
                this.reminders_steps[this.reminders_steps.length - 1].send
            ).add(1, 'days');

        this.reminders_steps.length > 0 &&
        moment(dayAfterLatestReminder).isBefore(this.due)
            ? this.reminders_steps.push({
                  id: -1,
                  send: dayAfterLatestReminder,
              })
            : this.reminders_steps.push({ send: null });
    };

    deleteReminderStep = (reminder) => {
        this.reminders_steps.remove(reminder);
    };

    toggleQuestionSelected(questionId) {
        if (this.selectedQuestionsIds.includes(questionId))
            this.selectedQuestionsIds.remove(questionId);
        else this.selectedQuestionsIds.push(questionId);
        this.templateCustomized = true;
    }

    toggleUserListSelected(listId) {
        if (this.selectedUserListsIds.includes(listId))
            this.selectedUserListsIds.remove(listId);
        else this.selectedUserListsIds.push(listId);
    }

    reorderQuestionsList(startIndex, endIndex) {
        if (endIndex < 0) {
            endIndex = 0;
        }
        const [removed] = this.selectedQuestionsIds.splice(startIndex, 1);
        this.selectedQuestionsIds.splice(endIndex, 0, removed);
    }

    get isUserCountOk() {
        let usersAvailable = 0;
        let usersRegistered = 0;

        if (this.company) {
            if (this.company.users_available) {
                usersAvailable = this.company.users_available;
            }
            usersRegistered = this.getCurrentCompanyUserCount;
        }
        return usersAvailable >= usersRegistered;
    }

    get statusInfo() {
        if (this.isScorm) {
            return {
                color: palette.graphite.heavyMetal,
                label: 'SCORM',
                transparent: true,
            };
        }
        return statuses[this.generalAssessmentStatus || this.status] || {};
    }

    get resilienceScoreDistribution() {
        return this._rawData.scoreDistribution.map((score) => ({
            ...score,
            employeePercent: (
                (score.value / this._rawData.usersFinished) *
                100
            ).toFixed(1),
        }));
    }

    get hasUnavailableQuestions() {
        return (
            this.unavailableQuestionsSelected &&
            this.unavailableQuestionsSelected.length > 0
        );
    }

    get link() {
        if (this.status === STATUS.DRAFT) return `/assessment/edit/${this.id}`;
        return `/assessment/${this.isScorm ? 'p' : ''}${this.id}/view`;
    }

    get questionsSelected() {
        return this.selectedQuestionsIds
            .map((x) => this.questionsIndex[x])
            .filter((x) => x != null);
    }

    get unavailableQuestionsSelected() {
        return this.questionsSelected.filter((x) => x.disabled);
    }

    get maxByAreaAndBehaviourForSelectedQuestions() {
        let maxByAreaAndBehaviour = {};
        this.questionsSelected.forEach((q) => {
            Object.keys(q.maxScoresByBehaviour).forEach((b) => {
                if (!maxByAreaAndBehaviour[`${q.area}-${b}`])
                    maxByAreaAndBehaviour[`${q.area}-${b}`] = 0;
                maxByAreaAndBehaviour[`${q.area}-${b}`] +=
                    q.maxScoresByBehaviour[b];
            });
        });
        return maxByAreaAndBehaviour;
    }

    get userListsSuffix() {
        return this.selectedUserListsIds.length === 1
            ? ` - ${this.userListsSelected[0].name}`
            : '';
    }

    get userListsSelected() {
        return compact(
            this.selectedUserListsIds.map(
                (x) => this.listStore.allListsIndex[x]
            )
        );
    }

    get filteredUserListsSelected() {
        return this.userListsSelected.filter(
            (x) =>
                !(
                    this.userListSearchValue.length > 0 &&
                    x.name !== null &&
                    !x.name
                        .toLowerCase()
                        .includes(this.userListSearchValue.toLowerCase())
                )
        );
    }

    get selectedUserCount() {
        return sumBy(
            this.selectedUserListsIds.map(
                (x) => this.listStore.allListsIndex[x]
            ),
            'usersCount'
        );
    }

    get personalizeData() {
        let data = {};
        if (this.companyName) {
            data.companyName = this.companyName;
        }
        if (this.companyDomain) {
            data.companyDomain = this.companyDomain;
        }
        if (this.emailHR) {
            data.emailHR = this.emailHR;
        }
        return data;
    }

    //  get listNames() {
    //     if (this.listStore !== null) {
    //         return this.listStore.listsWithUsers.filter((list) =>
    //             (this._rawData.definition.userLists.includes(list.id))
    //         )
    //     }
    //     return null
    // }

    get canCopy() {
        if (!this.mainStore || !this.mainStore.isAdmin) return false;
        if (!this._rawData) return false;
        if (this.active) return false;
        if (this._rawData.events.every((x) => x.status > 0)) return false;

        return true;
    }

    get canRemind() {
        if (!this.mainStore || !this.mainStore.isAdmin) return false;
        if (!this._rawData || !this.active || this.isScorm) return false;
        return this.status === STATUS.ACTIVE;
    }

    get isCompleted() {
        return this.status === STATUS.COMPLETED;
    }

    get questions() {
        return this._rawData.definition && this._rawData.definition.questions;
    }

    get personalizeEnabled() {
        return this.questionsSelected.find(
            (q) => 'personalizedKeysUsed' in q.definition
        ) !== undefined
            ? true
            : false;
    }

    get personalizedKeys() {
        let keys = [];
        this.personalizeEnabled &&
            this.questionsSelected.map((q) =>
                q.definition.personalizedKeysUsed !== undefined
                    ? q.definition.personalizedKeysUsed.map((k) => keys.push(k))
                    : null
            );
        return uniq(keys);
    }

    get deliveryDate() {
        return this._rawData.sendOptions.scheduleType === 1
            ? this.status === STATUS.DRAFT
                ? null
                : this._rawData.created
            : this._rawData.sendOptions.date;
    }

    get completionDate() {
        return this._rawData.completed;
    }

    get userScore() {
        return this._rawData.score;
    }

    get summaryDeliveryDateText() {
        if (this.date !== null)
            return moment(this.date).format(
                `L (LT) [${this.authStore.timeZone}]`
            );
        if (this.scheduleType === -1) return 'Manual';
        return 'Immediately';
    }

    get hasStarted() {
        return (
            (this.status === STATUS.ACTIVE ||
                this.status === STATUS.COMPLETED) &&
            this._rawData.usersFinished > 0
        );
    }

    get attendance() {
        return defaultZero(
            Math.round(
                (100 * (this._rawData.usersFinished || 0)) /
                    this._rawData.usersTotal
            )
        );
    }

    get selectedQuestionsDuration() {
        return (
            Object.values(this.questionsSelected).reduce(
                (q, { duration }) => q + duration,
                0
            ) / 60
        );
    }

    get recommendations() {
        return this._rawData.recommendations;
    }

    get areas() {
        return this._rawData.areas;
    }

    toDTO(extraData) {
        return {
            questions: this.selectedQuestionsIds,
            name: this.name.replace('&nbsp;', ' '),
            id: this.id,
            userLists: this.selectedUserListsIds,
            channel: this.channel,
            scheduleType: this.scheduleType,
            date: this.date,
            timeOfDay: moment(this.date).hours(),
            due: this.due ? this.due : null,
            reminders_enabled: this.reminders_enabled,
            reminders_settings: {
                channel: this.reminders_channel,
                steps: this.reminders_steps,
            },
            templateCustomized: this.templateCustomized,
            introMessage: this.introMessageEnabled,
            personalizeEnabled: this.personalizeEnabled,
            threatAreas: this.threatAreas,
            overrideDuration: this.overrideDuration,
            status: this.status,
            enabledLanguages: this.enabledLanguages,
            ...extraData,
        };
    }
}

export default Assessment;
