import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import classnames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import {
    CardDeck,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Alert,
} from 'reactstrap';
import StepsSelect from '../../components/manager/assessment/sidebar';
import SubjectSelect from '../../components/manager/automation/subject-select-ta-and-kb';
import SubjectsOrder from '../../components/manager/automation/subjects-order';
import AutomationDistribution from '../../components/manager/automation/distribution';
import AutomationReminders from '../../components/manager/automation/reminders';
import PreviewEmail from '../../components/manager/automation/preview-email';
import ErrorMessage from '../../components/errormessage';
import Modal from 'react-modal';
import ListEditorModal from '../users/modals/ListEditorModal';
import Snackbar from '../../components/Snackbar';
import IntroductoryFormDef from '../../forms/introductoryForm';
import {
    formatTime,
    isNameUntitled,
    querystring,
    fixHtml,
} from '../../utils/helpers';
import ThemedLinkButton from '../../components/themed/ThemedLinkButton';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import pluralize from 'pluralize';
import {
    AUTOMATION_WIZARD_STEPS,
    AUTOMATION_SCHEDULE_TYPE,
    STATUS,
} from '../../utils/constants';
import sanitizeHtml from 'sanitize-html';
import { useStores } from '../../hooks/use-stores';
import SaveChanges from '../../components/manager/wizard/save-changes';
import SelectEmployees from '../../components/manager/wizard/select-employees';
import ChangeName from '../../components/manager/wizard/change-name';
import WizardHeader from '../../components/manager/wizard/WizardHeader';
import MenuItem from '@mui/material/MenuItem';
import {
    TextTiny,
    TextInput,
    BigModal,
    SpaceBetweenDiv,
    FlexDiv,
    Heading1,
    TextDefault,
    palette,
    ThemedButton,
    ThemedCard,
    StatusBadge,
} from '@awarego/awarego-components';
import AlertWithIcon from '../../components/Alert';
import LockIcon from '@mui/icons-material/Lock';
import StarIcon from '@mui/icons-material/Stars';
import Summary from '../../containers/automations/summary-output';
import { reaction } from 'mobx';
import SubjectPreview from '../subjectPreview';
import IntroMessage from '../../components/manager/automation/intro-message';
import DeliveryCalendar from './delivery-calendar';
import WizardStepHeader from '../../components/manager/wizard/WizardStepHeader';
import StartScreen from '../../components/manager/automation/start-screen';
import { Badge, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import SettingMultiSelect from '../settings/SettingMultiSelect';

function AutomationCreate({
    companyId,
    parentPath,
    isScorm,
    initialData,
    customBackUrl,
    recommendationMode = false, //This Training wizard was started to create a recommended training
}) {
    const {
        automationStore,
        commonStore,
        listStore,
        userStore,
        store,
        authStore,
        catalogStore,
        brandingStore,
        cmi5Store,
    } = useStores();

    const [subjectsModal, setSubjectsModal] = useState(false);
    const [previewSubjectModal, setPreviewSubjectModal] = useState(false);
    const [previewLocation, setPreviewLocation] = useState(null);
    const [recipientsModal, setRecipientsModal] = useState(false);
    const [addEmployeeModal, setAddEmployeeModal] = useState(false);
    const [createdAutomation, setCreatedAutomation] = useState(false);
    const [message, setMessage] = useState(null);
    const [messageShown, setMessageShown] = useState(false);
    const [messageType, setMessageType] = useState(null);
    const [nameChangeModal, setNameChangeModal] = useState(false);
    const [isDraft, setIsDraft] = useState(false);
    const [redirectLink, setRedirectLink] = useState(null);
    const [saveChangesModal, setSaveChangesModal] = useState(false);
    const [emailPreview, setEmailPreview] = useState(false);
    const [category, setCategory] = useState('all');
    const [threatArea, setThreatArea] = useState('all');
    const [keyBehaviour, setKeyBehaviour] = useState('all');

    const navigate = useNavigate();
    const { editId: editId, fromId: copyFrom } = useParams();
    const back = querystring('back');

    const {
        automation,
        loadingCompanyData,
        step,
        steps,
        allowedSteps,
        slackConnected,
        teamsConnected,
        error,
        templates,
        selectedTemplates,
        selectedTemplatesData,
        sendError,
        wizardActionButtonText,
    } = automationStore;
    const {
        catalog,
        availableThreatAreas,
        availableKeyBehaviours,
        availableSubjectsByThreatArea,
    } = catalogStore;
    const { currentUser, timeZone, token } = authStore;
    const { listsWithUsers: lists, error: listError } = listStore;
    const { loadingUsers } = userStore;
    const {
        currentCompany,
        havePartnerSubscription,
        slackAvailable,
        teamsAvailable,
        languages,
        enabledLanguages,
    } = store;
    const { supportEmail, templateStandards } = brandingStore;
    let createListDisabled = !currentCompany || !currentCompany.canAddUsers;
    let signInLinksUrl;
    if (step === AUTOMATION_WIZARD_STEPS.START && createdAutomation) {
        signInLinksUrl = `${
            import.meta.env.REACT_APP_API_URL
        }/companies/${companyId}/trainings/${
            createdAutomation.id
        }/users/csv?token=${token}`;
    }
    const ongoing = automation && automation.status === STATUS.ACTIVE;

    const proTip = (
        <SpaceBetweenDiv my={8}>
            <Heading1>Pro tip</Heading1>
            <StarIcon />
        </SpaceBetweenDiv>
    );

    const localStore = useLocalObservable(() => {
        let introductoryForm = new IntroductoryFormDef(
            automation && automation.introductoryData,
            {}
        );

        reaction(
            () => {
                return automationStore.automation;
            },
            (automation) => {
                if (automation) {
                    let data = automation.introductoryData || {};
                    introductoryForm.update(data);
                    introductoryForm.validate();
                }
            }
        );

        introductoryForm.observe({
            path: 'message',
            key: 'value',
            call: ({ form }) => {
                form.submit();
                automationStore.toggleFinalStep(introductoryForm);
            },
        });

        return { introductoryForm };
        a;
    });

    const doLoadData = useCallback(async () => {
        localStore.introductoryForm.reset();
        await automationStore.prepareWizard(
            companyId,
            copyFrom,
            editId,
            isScorm,
            initialData,
            recommendationMode,
            enabledLanguages
        );
    }, [
        localStore.introductoryForm,
        automationStore,
        companyId,
        copyFrom,
        editId,
        recommendationMode,
        isScorm,
        enabledLanguages,
    ]);

    useEffect(() => {
        doLoadData();
    }, [doLoadData]);

    const toggleVideoSelected = (videoId) => {
        automation.toggleVideoSelected(videoId);
        automationStore.toggleRecipientsStep();
        if (automation.isScorm) {
            automationStore.toggleFinalStep();
        }
    };

    const sendPreview = async (targetEmail, previewType, selectedEvents) => {
        let result = await automationStore.sendPreview(
            companyId,
            targetEmail,
            previewType,
            selectedEvents,
            {
                ...localStore.introductoryForm.values(),
                message: fixHtml(localStore.introductoryForm.values().message),
            }
        );
        if (result) {
            hideEmailPreview();
            showSnackBar('Test email sent successfully');
        }
    };

    const openEmailPreview = () => {
        setEmailPreview(true);
    };

    const hideEmailPreview = () => {
        setEmailPreview(false);
    };

    const onDownloadPackage = (id, uniqueId, type) => {
        cmi5Store.downloadZIP(companyId, id, uniqueId, type);
    };

    const openNameChangeModal = (isDraft, redirectLink) => {
        automation.setName('');
        setSaveChangesModal(false);
        setNameChangeModal(true);
        setIsDraft(isDraft);
        setRedirectLink(redirectLink);
    };

    const hideNameChangeModal = () => {
        setNameChangeModal(false);
    };

    const openSaveChangesModal = () => {
        if (isScorm) navigate(back || parentPath);
        else setSaveChangesModal(true);
    };

    const redirectToUpgrade = () => {
        commonStore.showConfirm(
            'Do you want to save this training as draft?',
            'Save as draft & continue',
            'Before you upgrade your subscription',
            () => {
                initiateSavingProgress(null, '/subscriptions/upgrade');
            }
        );
    };

    const hideSaveChangesModal = () => {
        setSaveChangesModal(false);
    };

    const initiateSavingProgress = (e, redirectTo) => {
        if (isNameUntitled(automation.name, 'training'))
            return openNameChangeModal(true, redirectTo);
        return saveChanges(redirectTo);
    };

    const saveChanges = async (redirectTo = '/trainings') => {
        const isNew = automation.id == null;
        let result;
        if (automation.status === STATUS.SCHEDULED) {
            result = await automationStore.startAutomation(companyId, {
                introductoryData: localStore.introductoryForm.values(),
            });
        } else {
            result = await automationStore.saveDraft(companyId, {
                introductoryData: localStore.introductoryForm.values(),
            });
        }
        if (result) {
            !isNew
                ? commonStore.success('Training updated!')
                : commonStore.success('Training draft saved!');
            navigate(redirectTo);
        } else {
            if (automationStore.error) {
                showSnackBar(automationStore.error, 'error');
            }
        }
    };

    const createOptionsArray = (languages, enabledOnly) => {
        let optionsArray = [];
        if (enabledOnly) {
            languages =
                languages.filter((lang) =>
                    enabledLanguages?.includes(lang.code)
                ) || [];
        }
        for (let i = 0; i < languages.length; i++) {
            optionsArray.push({
                key: languages[i].code,
                value: languages[i].code,
                name: languages[i].english_name,
                img: languages[i].flag_image,
            });
        }
        return optionsArray;
    };
    const toggleUserListSelected = (listId) => {
        automation.toggleUserListSelected(listId);
        automationStore.toggleScheduleStep();
    };

    const reorderSubjectList = (sourceIndex, targetIndex) => {
        automation.reorderSubjectList(sourceIndex, targetIndex);
    };

    const onChangeScheduleType = (value) => {
        automation.setScheduleType(value);
        automationStore.toggleRemindersStep();
    };

    const onChangeInterval = (event) => {
        automation.setInterval(event.target.value);
    };

    const onChangeSubjectsPerInterval = (event) => {
        automation.setSubjectsPerInterval(event.target.value);
    };

    const onChangeRemindersEnabled = () => {
        automation.setRemindersEnabled();
        automationStore.toggleIntroMessageStep();
    };

    const onChangeDate = (value) => {
        automation.setDate(value);
        automationStore.toggleRemindersStep();
    };
    const onChangeDueDate = (value) => {
        automation.setDueDate(value);
        automationStore.toggleRemindersStep();
    };

    const dueEnabled =
        store.currentCompany &&
        store.currentCompanyUISettings['ui.trainings.due'];

    const onChangeChannel = (event) => {
        if (
            !automation.setChannel(
                Number(event.target.value),
                event.target.checked
            )
        ) {
            commonStore.warn('At least one channel needs to be selected');
        }
    };

    const onChangeRemindersChannel = (event) => {
        if (
            !automation.setRemindersChannel(
                Number(event.target.value),
                event.target.checked
            )
        ) {
            commonStore.warn('At least one channel needs to be selected');
        }
        automationStore.toggleIntroMessageStep();
    };

    const onDeleteReminderStep = (item) => {
        automation.deleteReminderStep(item);
        automationStore.toggleIntroMessageStep();
    };

    const onAddReminderStep = () => {
        automation.addReminderStep();
        automationStore.toggleIntroMessageStep();
    };

    const startAutomation = async () => {
        if (isScorm) {
            const dto = automation.toDTO({
                templateIds: automationStore.selectedTemplates,
                type: 'training',
            });
            const result = await cmi5Store.createPackage(companyId, dto);

            if (result) {
                automationStore.setStep(AUTOMATION_WIZARD_STEPS.START);
                setCreatedAutomation(result);
            } else {
                if (cmi5Store.error) {
                    showSnackBar(cmi5Store.error, 'error');
                }
            }
        } else {
            await localStore.introductoryForm.validate();
            await localStore.introductoryForm.showErrors(true);

            if (
                !automation.introMessageEnabled ||
                localStore.introductoryForm.isValid
            ) {
                let result = await automationStore.startAutomation(companyId, {
                    introductoryData: localStore.introductoryForm.values(),
                });
                if (result) {
                    automationStore.setStep(AUTOMATION_WIZARD_STEPS.START);
                    localStore.introductoryForm.reset();
                    setCreatedAutomation(result);
                } else {
                    if (automationStore.error) {
                        showSnackBar(automationStore.error, 'error');
                    }
                }
            }
        }
    };

    const stepChange = (step) => {
        automationStore.setStep(step);
    };

    const onChangeName = (e) => {
        automation.setName(e.target.value);
    };

    const onThreatAreaChange = (value) => {
        setThreatArea(value);
        setKeyBehaviour('all');
        catalogStore.setThreatAreaFilter(value);
        if (value === 'all') {
            setKeyBehaviour('all');
        }
    };

    const onKeyBehaviourChange = (keyBehaviourName) => {
        if (keyBehaviourName === 'all') {
            setThreatArea('all');
            setKeyBehaviour('all');
            catalogStore.setThreatAreaFilter('all');
        } else {
            let keyBehaviorObject = availableKeyBehaviours.find(
                (x) => x.kbName === keyBehaviourName
            );
            setThreatArea(
                keyBehaviorObject && keyBehaviorObject.threatAreaName
                    ? keyBehaviorObject.threatAreaName
                    : 'all'
            );

            setKeyBehaviour(keyBehaviourName);
            catalogStore.setKeyBehaviourFilter(
                keyBehaviorObject.threatAreaName || 'all',
                keyBehaviourName || 'all'
            );
        }
    };

    const onClearFilter = () => {
        onThreatAreaChange('all');
    };

    const toggleIntroMessage = () => {
        automation.toggleIntroMessage();
        automationStore.toggleFinalStep(localStore.introductoryForm);
    };

    const selectTemplate = (template) => {
        automationStore.setTemplate(template.id);
    };

    const nextStep = () => {
        if (automationStore.step === AUTOMATION_WIZARD_STEPS.FINALIZE)
            if (
                automation.name === 'Untitled Training' ||
                automation.name === '' ||
                automation.name == null
            ) {
                openNameChangeModal(false);
            } else {
                startAutomation();
            }
        else {
            const nextStep = automationStore.nextStepNumber;
            automationStore.setStep(nextStep);
            automationStore.toggleRecipientsStep();
            automationStore.toggleRemindersStep();
            automationStore.toggleIntroMessageStep();
            automationStore.toggleFinalStep(localStore.introductoryForm);
        }
    };

    const prevStep = () => {
        let index = automationStore.steps.findIndex(
            (x) => x.number === automationStore.step
        );
        let prevStep = automationStore.steps[index - 1]
            ? automationStore.steps[index - 1].number
            : automationStore.step - 1;

        automationStore.setStep(prevStep);
    };

    const showSubjectsModal = () => {
        setSubjectsModal(true);
    };

    const hideSubjectsModal = () => {
        setSubjectsModal(false);
    };

    const showPreviewSubjectModal = (subjectId) => {
        setPreviewLocation(subjectId);
        setPreviewSubjectModal(true);
    };

    const hidePreviewSubjectModal = () => {
        setPreviewSubjectModal(false);
    };

    const showRecipientsModal = () => {
        setRecipientsModal(true);
    };

    const hideRecipientsModal = () => {
        setRecipientsModal(false);
    };

    const showAddEmployeeModal = () => {
        setAddEmployeeModal(true);
    };

    const hideAddEmployeeModal = () => {
        setAddEmployeeModal(false);
    };

    const createRecipientsList = async (list) => {
        list.users = list.users.filter((x) => x.email);
        let result = await listStore.save(companyId, null, list);
        if (result) hideRecipientsModal();
        toggleUserListSelected(result);
        if (!addEmployeeModal) showAddEmployeeModal();
    };

    const requestUsersForList = (listId) => {
        userStore.loadUsers(companyId, listId, true);
    };

    const showSnackBar = (message, messageType = 'success') => {
        setMessage(message);
        setMessageShown(true);
        setMessageType(messageType);
    };

    const closeSnackbar = () => {
        setMessage(null);
        setMessageShown(false);
        setMessageType(null);
    };

    const getTemplateSubjectAvailabilityElement = (template) => {
        if (!template) return null;
        if (
            !template.disabledSubjects ||
            template.disabledSubjects.length === 0
        ) {
            return null;
        }

        const text = `${template.disabledSubjects.length}/${
            template.subjects.length + template.disabledSubjects.length
        } subjects unavailable in your plan`;

        return (
            <TextTiny bold error>
                <LockIcon fontSize={'inherit'} /> {text}
            </TextTiny>
        );
    };

    const getReviewSubjectAvailabilityElement = () => {
        const selectedSubjects = automation.videosSelected;

        if (!selectedSubjects) return null;

        const disabledSubjectsCount =
            automation.unavailableSubjectsSelected &&
            automation.unavailableSubjectsSelected.length;

        if (disabledSubjectsCount === 0) return null;

        const count = `${disabledSubjectsCount}/${selectedSubjects.length}`;

        return havePartnerSubscription ? (
            <AlertWithIcon severity="error">
                {count} of selected subjects are unavailable in your
                subscription. Please remove unavailable subjects or contact{' '}
                <a href={`mailto:${supportEmail}`}>{supportEmail}</a> to upgrade
                your subscription.
            </AlertWithIcon>
        ) : (
            <AlertWithIcon severity="error">
                {count} of selected subjects are unavailable in your
                subscription. Please remove unavailable subjects or{' '}
                <u style={{ cursor: 'pointer' }} onClick={redirectToUpgrade}>
                    upgrade
                </u>{' '}
                your subscription.
            </AlertWithIcon>
        );
    };

    return (
        <Fragment>
            <ErrorMessage error={error} />
            {loadingCompanyData || !automation || !catalog ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    {ongoing || automation.status === STATUS.COMPLETED ? (
                        <FlexDiv justifyCenter alignCenter pt={48}>
                            You can't edit an already ongoing or completed
                            training
                        </FlexDiv>
                    ) : currentCompany && currentCompany.canCreateTraining ? (
                        <div className={'training-wizard'}>
                            <WizardHeader
                                draftAllowed={!isScorm}
                                selectedTemplate={selectedTemplates}
                                isStartStep={
                                    step === AUTOMATION_WIZARD_STEPS.START
                                }
                                openSaveChangesModal={openSaveChangesModal}
                                item={automation}
                                onChangeName={onChangeName}
                                itemString="training"
                                initiateSavingProgress={initiateSavingProgress}
                                isNameUntitled={isNameUntitled(
                                    automation.name,
                                    'training'
                                )}
                            />
                            {automation.id !== null &&
                                automation.status === STATUS.SCHEDULED && (
                                    <div className="assessment-wizard-info-message">
                                        <span style={{ fontWeight: 700 }}>
                                            Edit training
                                        </span>
                                    </div>
                                )}
                            <div className={'training-wizard-body'}>
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    autoHideDuration={15000}
                                    variant={messageType}
                                    message={message || ''}
                                    open={messageShown}
                                    onClose={closeSnackbar}
                                />
                                {step !== AUTOMATION_WIZARD_STEPS.START && (
                                    <StepsSelect
                                        steps={steps}
                                        activeStep={step}
                                        allowedSteps={allowedSteps}
                                        onStepChange={stepChange}
                                        warningSteps={[
                                            automation.hasUnavailableSubjects
                                                ? AUTOMATION_WIZARD_STEPS.CONTENT
                                                : null,
                                        ]}
                                    />
                                )}
                                <div
                                    className={classnames(
                                        'training-wizard-content',
                                        {
                                            'expand-content':
                                                step ===
                                                    AUTOMATION_WIZARD_STEPS.SCHEDULE ||
                                                step ===
                                                    AUTOMATION_WIZARD_STEPS.START,
                                        }
                                    )}
                                >
                                    {step === AUTOMATION_WIZARD_STEPS.GOAL && (
                                        <FlexDiv column>
                                            <WizardStepHeader
                                                title={'Set a training goal'}
                                                subtitle={
                                                    'Use one of our ready-made templates or create a fully customized training program'
                                                }
                                            />
                                            {store.isTrialOnly && (
                                                <TextDefault
                                                    uppercase
                                                    bold
                                                    mb={8}
                                                >
                                                    Available training programs{' '}
                                                </TextDefault>
                                            )}
                                            <CardDeck
                                                className={'training-templates'}
                                            >
                                                {templates.map((t) => {
                                                    if (t.hidden || !t.enabled)
                                                        return null;
                                                    return (
                                                        <ThemedCard
                                                            key={t.id}
                                                            outline
                                                            disabled
                                                            {...{
                                                                selected:
                                                                    selectedTemplates.includes(
                                                                        t.id
                                                                    ),
                                                            }}
                                                            className={classnames(
                                                                'training-template',
                                                                {
                                                                    disabled:
                                                                        !t.enabled,
                                                                },
                                                                `training-template-${t.id}`
                                                            )}
                                                            onClick={() => {
                                                                if (t.enabled)
                                                                    selectTemplate(
                                                                        t
                                                                    );
                                                            }}
                                                        >
                                                            <CardBody
                                                                style={{
                                                                    paddingBottom: 8,
                                                                }}
                                                            >
                                                                <CardTitle>
                                                                    <Heading1
                                                                        displayBlock
                                                                        mb={8}
                                                                    >
                                                                        {
                                                                            t.title
                                                                        }
                                                                    </Heading1>
                                                                </CardTitle>
                                                                <CardText>
                                                                    {
                                                                        t.description
                                                                    }
                                                                </CardText>
                                                                <CardSubtitle>
                                                                    {t.subjects
                                                                        .length >
                                                                        0 && (
                                                                        <span>
                                                                            {
                                                                                t.verboseDuration
                                                                            }{' '}
                                                                        </span>
                                                                    )}
                                                                </CardSubtitle>
                                                                {getTemplateSubjectAvailabilityElement(
                                                                    t
                                                                )}
                                                                {templateStandards &&
                                                                    (
                                                                        t
                                                                            ?.definition
                                                                            ?.standards ||
                                                                        []
                                                                    ).length >
                                                                        0 && (
                                                                        <Box
                                                                            paddingTop={
                                                                                2
                                                                            }
                                                                            display="flex"
                                                                            style={{
                                                                                gap: 4,
                                                                            }}
                                                                        >
                                                                            {(
                                                                                t
                                                                                    ?.definition
                                                                                    ?.standards ||
                                                                                []
                                                                            ).map(
                                                                                (
                                                                                    s
                                                                                ) => {
                                                                                    return (
                                                                                        <StatusBadge
                                                                                            key={
                                                                                                s
                                                                                            }
                                                                                            color={
                                                                                                palette
                                                                                                    .graphite
                                                                                                    .heavyMetal
                                                                                            }
                                                                                            text={
                                                                                                s
                                                                                            }
                                                                                        />
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </Box>
                                                                    )}
                                                            </CardBody>
                                                        </ThemedCard>
                                                    );
                                                })}
                                            </CardDeck>
                                            {store.isTrialOnly && (
                                                <Fragment>
                                                    <FlexDiv gap={4}>
                                                        <TextDefault
                                                            uppercase
                                                            bold
                                                            mb={8}
                                                        >
                                                            All training
                                                            programs -{' '}
                                                        </TextDefault>
                                                        <TextDefault
                                                            color={
                                                                palette.vibrant
                                                                    .orange
                                                            }
                                                            bold
                                                            uppercase
                                                        >
                                                            <a
                                                                href="/subscriptions/upgrade"
                                                                className="yellow"
                                                            >
                                                                Upgrade to get
                                                                access
                                                            </a>
                                                        </TextDefault>
                                                    </FlexDiv>
                                                    <CardDeck
                                                        className={
                                                            'training-templates'
                                                        }
                                                    >
                                                        {templates.map((t) => {
                                                            if (
                                                                t.hidden ||
                                                                t.enabled
                                                            )
                                                                return null;
                                                            return (
                                                                <ThemedCard
                                                                    key={t.id}
                                                                    outline
                                                                    disabled
                                                                    {...{
                                                                        selected:
                                                                            selectedTemplates.includes(
                                                                                t.id
                                                                            ),
                                                                    }}
                                                                    className={classnames(
                                                                        'training-template',
                                                                        {
                                                                            disabled:
                                                                                !t.enabled,
                                                                        }
                                                                    )}
                                                                    onClick={() => {
                                                                        if (
                                                                            t.enabled
                                                                        )
                                                                            selectTemplate(
                                                                                t
                                                                            );
                                                                    }}
                                                                >
                                                                    <CardBody>
                                                                        <CardTitle>
                                                                            <Heading1
                                                                                displayBlock
                                                                                mb={
                                                                                    8
                                                                                }
                                                                            >
                                                                                {
                                                                                    t.title
                                                                                }
                                                                            </Heading1>
                                                                        </CardTitle>
                                                                        <CardText>
                                                                            {
                                                                                t.description
                                                                            }
                                                                        </CardText>
                                                                        <CardSubtitle>
                                                                            {t
                                                                                .subjects
                                                                                .length >
                                                                                0 && (
                                                                                <span>
                                                                                    {
                                                                                        t.verboseDuration
                                                                                    }{' '}
                                                                                </span>
                                                                            )}
                                                                        </CardSubtitle>
                                                                    </CardBody>
                                                                </ThemedCard>
                                                            );
                                                        })}
                                                    </CardDeck>
                                                </Fragment>
                                            )}
                                        </FlexDiv>
                                    )}
                                    {step ===
                                        AUTOMATION_WIZARD_STEPS.CONTENT && (
                                        <Fragment>
                                            <WizardStepHeader
                                                title={'Review subjects'}
                                                subtitle={
                                                    ongoing
                                                        ? 'You cannot modify subjects since at least some were already sent to people.'
                                                        : 'You can edit or add more subjects to this set of subjects.'
                                                }
                                                summary={`${
                                                    automation.videosSelected
                                                        .length
                                                } ${pluralize(
                                                    'subject',
                                                    automation.videosSelected
                                                        .length
                                                )} · ${formatTime(
                                                    automation.videosSelectedDuration,
                                                    true
                                                )} ${pluralize(
                                                    'minute',
                                                    formatTime(
                                                        automation.videosSelectedDuration,
                                                        true
                                                    )
                                                )}`}
                                                buttonText={
                                                    !ongoing && 'Add subjects'
                                                }
                                                onButtonClick={
                                                    showSubjectsModal
                                                }
                                            />
                                            {getReviewSubjectAvailabilityElement()}

                                            <SubjectsOrder
                                                templates={
                                                    selectedTemplatesData
                                                }
                                                videos={
                                                    automation.videosSelected
                                                }
                                                onRemoveSubject={
                                                    toggleVideoSelected
                                                }
                                                onReorderList={
                                                    reorderSubjectList
                                                }
                                                isTrial={store.isTrial}
                                            />
                                        </Fragment>
                                    )}
                                    {step ===
                                        AUTOMATION_WIZARD_STEPS.EMPLOYEES && (
                                        <SelectEmployees
                                            itemString="training"
                                            item={automation}
                                            lists={lists}
                                            toggleUserListSelected={
                                                toggleUserListSelected
                                            }
                                            ongoing={ongoing}
                                            addEmployeeModal={addEmployeeModal}
                                            showAddEmployeeModal={
                                                showAddEmployeeModal
                                            }
                                            hideAddEmployeeModal={
                                                hideAddEmployeeModal
                                            }
                                            recipientsModal={recipientsModal}
                                            showRecipientsModal={
                                                showRecipientsModal
                                            }
                                            hideRecipientsModal={
                                                hideRecipientsModal
                                            }
                                            error={listError}
                                            loadingUsers={loadingUsers}
                                            requestUsersForList={
                                                requestUsersForList
                                            }
                                            selectedLists={
                                                automation.selectedUserListsIds
                                            }
                                            selectedUserCount={
                                                automation.selectedUserCount
                                            }
                                            disabled={createListDisabled}
                                            createRecipientsList={
                                                createRecipientsList
                                            }
                                            currentCompany={currentCompany}
                                            redirectToUpgrade={
                                                redirectToUpgrade
                                            }
                                        />
                                    )}
                                    {step ===
                                        AUTOMATION_WIZARD_STEPS.SCHEDULE && (
                                        <>
                                            <WizardStepHeader
                                                title={'Schedule delivery'}
                                                subtitle={
                                                    ongoing
                                                        ? 'Modify delivery channel for this training.'
                                                        : 'Maximize your employee engagement by sending 1-4 subjects once a week.'
                                                }
                                            />
                                            <SpaceBetweenDiv
                                                alignStart
                                                pr={36}
                                                gap={16}
                                            >
                                                <FlexDiv column>
                                                    <AutomationDistribution
                                                        ongoing={ongoing}
                                                        date={automation.date}
                                                        dueDate={
                                                            automation.dueDate
                                                        }
                                                        dueEnabled={true}
                                                        deliverySchedule={
                                                            automation.deliverySchedule
                                                        }
                                                        interval={
                                                            automation.interval
                                                        }
                                                        subjectsPerInterval={
                                                            automation.subjectsPerInterval ==
                                                            null
                                                                ? recommendationMode
                                                                    ? 2
                                                                    : 3
                                                                : automation.subjectsPerInterval
                                                        }
                                                        scheduleType={
                                                            automation.scheduleType
                                                        }
                                                        channel={
                                                            automation.channel
                                                        }
                                                        slackAvailable={
                                                            slackAvailable
                                                        }
                                                        slackConnected={
                                                            slackConnected
                                                        }
                                                        teamsAvailable={
                                                            teamsAvailable
                                                        }
                                                        teamsConnected={
                                                            teamsConnected
                                                        }
                                                        onChangeDate={
                                                            onChangeDate
                                                        }
                                                        onChangeDueDate={
                                                            onChangeDueDate
                                                        }
                                                        onChangeScheduleType={
                                                            onChangeScheduleType
                                                        }
                                                        onChangeChannel={
                                                            onChangeChannel
                                                        }
                                                        onChangeInterval={
                                                            onChangeInterval
                                                        }
                                                        onChangeSubjectsPerInterval={
                                                            onChangeSubjectsPerInterval
                                                        }
                                                        adminTimeZone={timeZone}
                                                    />
                                                </FlexDiv>
                                                {automation.scheduleType ===
                                                    AUTOMATION_SCHEDULE_TYPE.FUTURE && (
                                                    <DeliveryCalendar
                                                        displayedEvents={
                                                            automation.displayedCalendarEvents
                                                        }
                                                        daysWithDot={
                                                            automation.daysWithEventsInSelectedMonth
                                                        }
                                                        setSelectedMonth={
                                                            automation.setDeliveryCalendarMonth
                                                        }
                                                        selectedDate={
                                                            automation.deliveryCalendarDate
                                                        }
                                                        setSelectedDate={
                                                            automation.setDeliveryCalendarDate
                                                        }
                                                        totalEvents={
                                                            automation
                                                                .deliverySchedule
                                                                .length
                                                        }
                                                        updateEventDate={
                                                            automation.updateEventDate
                                                        }
                                                        scheduleType={
                                                            automation.scheduleType
                                                        }
                                                        date={automation.date}
                                                        adminTimeZone={timeZone}
                                                    />
                                                )}
                                            </SpaceBetweenDiv>
                                        </>
                                    )}
                                    {step ===
                                        AUTOMATION_WIZARD_STEPS.REMINDERS && (
                                        <Fragment>
                                            <WizardStepHeader
                                                title={'Reminders (optional)'}
                                                subtitle={
                                                    'Keep your employees on track with their training by setting automatic reminders'
                                                }
                                            />
                                            <AutomationReminders
                                                reminders_enabled={
                                                    automation.reminders_enabled
                                                }
                                                reminders_channel={
                                                    automation.reminders_channel
                                                }
                                                reminders_steps={
                                                    automation.reminders_steps
                                                }
                                                slackAvailable={slackAvailable}
                                                slackConnected={slackConnected}
                                                teamsAvailable={teamsAvailable}
                                                teamsConnected={teamsConnected}
                                                onChangeRemindersEnabled={
                                                    onChangeRemindersEnabled
                                                }
                                                onChangeRemindersChannel={
                                                    onChangeRemindersChannel
                                                }
                                                onAddReminderStep={
                                                    onAddReminderStep
                                                }
                                                onDeleteReminderStep={
                                                    onDeleteReminderStep
                                                }
                                                scheduleType={
                                                    automation.scheduleType
                                                }
                                            />
                                        </Fragment>
                                    )}
                                    {step ===
                                        AUTOMATION_WIZARD_STEPS.INTROMESSAGE && (
                                        <Fragment>
                                            <WizardStepHeader
                                                title={
                                                    'Intro message (optional)'
                                                }
                                                subtitle={
                                                    'Customize the first message to introduce this training to employees.'
                                                }
                                            />
                                            <IntroMessage
                                                introductoryForm={
                                                    localStore.introductoryForm
                                                }
                                                automation={automation}
                                                toggleIntroMessage={
                                                    toggleIntroMessage
                                                }
                                            />
                                        </Fragment>
                                    )}
                                    {step ===
                                        AUTOMATION_WIZARD_STEPS.LANGUAGES && (
                                        <Fragment>
                                            <WizardStepHeader
                                                title={
                                                    'Available languages (optional)'
                                                }
                                                subtitle={
                                                    'Select the languages you want to make this training available in.'
                                                }
                                            />
                                            <SettingMultiSelect
                                                name="Languages"
                                                options={createOptionsArray(
                                                    languages
                                                )}
                                                value={
                                                    automation.enabledLanguages
                                                }
                                                noSavedMark={true}
                                                py={10}
                                                textLead={true}
                                                action={(value) =>
                                                    automationStore.setEnabledLanguages(
                                                        value
                                                    )
                                                }
                                            />
                                        </Fragment>
                                    )}
                                    {step ===
                                        AUTOMATION_WIZARD_STEPS.FINALIZE && (
                                        <Fragment>
                                            <WizardStepHeader
                                                title={'Finalize'}
                                                subtitle={
                                                    ongoing
                                                        ? 'Check out the summary before applying changes.'
                                                        : 'Check out the summary before you create the training.'
                                                }
                                            />

                                            {getReviewSubjectAvailabilityElement()}

                                            <Summary
                                                automation={automation}
                                                isScorm={isScorm}
                                                introductoryForm={
                                                    localStore.introductoryForm
                                                }
                                                actionButtonText={
                                                    wizardActionButtonText
                                                }
                                                adminTimeZone={timeZone}
                                            />
                                            <br />
                                        </Fragment>
                                    )}
                                    {step === AUTOMATION_WIZARD_STEPS.START && (
                                        <StartScreen
                                            createdTraining={createdAutomation}
                                            training={automation}
                                            ongoing={ongoing}
                                            signInLinksUrl={signInLinksUrl}
                                            isScorm={isScorm}
                                            onDownloadPackage={
                                                onDownloadPackage
                                            }
                                            isPackageDownloading={
                                                cmi5Store.isZIPDownloading
                                            }
                                        />
                                    )}
                                </div>
                                {(step === AUTOMATION_WIZARD_STEPS.GOAL ||
                                    step ===
                                        AUTOMATION_WIZARD_STEPS.INTROMESSAGE) && (
                                    <div className="right-sidebar">
                                        {step ===
                                            AUTOMATION_WIZARD_STEPS.GOAL &&
                                            automation.templateTip && (
                                                <div className={'pro-tip'}>
                                                    {proTip}
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: sanitizeHtml(
                                                                automation.templateTip
                                                            ),
                                                        }}
                                                    ></p>
                                                </div>
                                            )}
                                        {step ===
                                            AUTOMATION_WIZARD_STEPS.INTROMESSAGE && (
                                            <div className={'pro-tip'}>
                                                {proTip}
                                                <p>
                                                    Before you launch your first
                                                    training program, send an
                                                    email from your work email
                                                    to everyone involved and
                                                    tell them that they will
                                                    soon receive security
                                                    awareness training from
                                                    AwareGO in their inbox.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            {step !== AUTOMATION_WIZARD_STEPS.START && (
                                <div className={'training-wizard-footer'}>
                                    <div className={'actions'}>
                                        {step !==
                                            AUTOMATION_WIZARD_STEPS.GOAL &&
                                            step !==
                                                AUTOMATION_WIZARD_STEPS.START && (
                                                <span
                                                    className="previous-btn"
                                                    onClick={prevStep}
                                                >
                                                    Previous
                                                </span>
                                            )}
                                        <div className="actions-align-right">
                                            {!isScorm &&
                                                step ===
                                                    AUTOMATION_WIZARD_STEPS.FINALIZE &&
                                                automation.scheduleType !==
                                                    2 && (
                                                    <ThemedLinkButton
                                                        mode="secondary"
                                                        footer
                                                        onClick={
                                                            openEmailPreview
                                                        }
                                                        disabled={
                                                            automation.hasUnavailableSubjects
                                                        }
                                                        text="Preview Emails"
                                                    ></ThemedLinkButton>
                                                )}

                                            <ThemedLinkButton
                                                mode="primary"
                                                continue
                                                disabled={
                                                    (!allowedSteps.includes(
                                                        automationStore.nextStepNumber
                                                    ) &&
                                                        step !==
                                                            AUTOMATION_WIZARD_STEPS.FINALIZE) ||
                                                    (automation.hasUnavailableSubjects &&
                                                        step ===
                                                            AUTOMATION_WIZARD_STEPS.FINALIZE)
                                                }
                                                onClick={nextStep}
                                                text={wizardActionButtonText}
                                            ></ThemedLinkButton>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <BigModal
                                isOpen={subjectsModal}
                                onRequestClose={hideSubjectsModal}
                                heading="Add subjects"
                                filterContent={
                                    <FlexDiv gap={16} alignCenter $noWrap>
                                        {recommendationMode === false ? (
                                            <>
                                                <TextInput
                                                    select
                                                    value={category || 'all'}
                                                    onChange={(e) =>
                                                        setCategory(
                                                            e.target.value
                                                        )
                                                    }
                                                    inline
                                                >
                                                    <MenuItem value={'all'}>
                                                        All categories
                                                    </MenuItem>
                                                    {catalog.map((category) => (
                                                        <MenuItem
                                                            value={
                                                                category.name
                                                            }
                                                            key={category.id}
                                                        >
                                                            {category.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextInput>
                                                {category !== 'all' && (
                                                    <ThemedButton
                                                        mode="secondary"
                                                        $noTextTransform
                                                        $airy
                                                        size="tiny"
                                                        onClick={() => {
                                                            setCategory('all');
                                                        }}
                                                        text="Clear"
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <TextInput
                                                    select
                                                    value={threatArea || 'all'}
                                                    onChange={(e) =>
                                                        onThreatAreaChange(
                                                            e.target.value
                                                        )
                                                    }
                                                    inline
                                                >
                                                    <MenuItem value={'all'}>
                                                        All threat areas
                                                    </MenuItem>
                                                    {availableThreatAreas.map(
                                                        (threatArea) => (
                                                            <MenuItem
                                                                value={
                                                                    threatArea.threatAreaName
                                                                }
                                                                key={
                                                                    threatArea.id
                                                                }
                                                            >
                                                                {
                                                                    threatArea.threatAreaName
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </TextInput>
                                                <TextInput
                                                    select
                                                    value={
                                                        keyBehaviour || 'all'
                                                    }
                                                    onChange={(e) =>
                                                        onKeyBehaviourChange(
                                                            e.target.value
                                                        )
                                                    }
                                                    inline
                                                >
                                                    <MenuItem value={'all'}>
                                                        All key behaviours
                                                    </MenuItem>
                                                    {availableKeyBehaviours.map(
                                                        (keyBehaviour) => (
                                                            <MenuItem
                                                                value={
                                                                    keyBehaviour.kbName
                                                                }
                                                                key={
                                                                    keyBehaviour.id
                                                                }
                                                            >
                                                                {
                                                                    keyBehaviour.kbName
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </TextInput>
                                                {threatArea !== 'all' ||
                                                keyBehaviour !== 'all' ? (
                                                    <ThemedButton
                                                        mode="secondary"
                                                        $noTextTransform
                                                        size="tiny"
                                                        onClick={() => {
                                                            onClearFilter();
                                                        }}
                                                        text="Clear"
                                                    />
                                                ) : null}
                                            </>
                                        )}
                                    </FlexDiv>
                                }
                                modalContent={
                                    <SubjectSelect
                                        selection={automation.selectedVideosIds}
                                        catalog={
                                            recommendationMode
                                                ? availableSubjectsByThreatArea
                                                : catalog
                                        }
                                        category={category}
                                        onRequestClose={hideSubjectsModal}
                                        isTrial={store.isTrial}
                                        onVideoSelected={toggleVideoSelected}
                                        showPreviewSubjectModal={
                                            showPreviewSubjectModal
                                        }
                                    />
                                }
                                modalFooterContent={
                                    <SpaceBetweenDiv>
                                        <TextTiny themed bold>
                                            {
                                                (
                                                    automation.selectedVideosIds ||
                                                    []
                                                ).length
                                            }{' '}
                                            {pluralize(
                                                'subject',
                                                (
                                                    automation.selectedVideosIds ||
                                                    []
                                                ).length
                                            )}{' '}
                                            selected
                                        </TextTiny>
                                        <ThemedButton
                                            mode="primary"
                                            onClick={hideSubjectsModal}
                                            text="Done"
                                        />
                                    </SpaceBetweenDiv>
                                }
                            />
                            <SubjectPreview
                                isOpen={previewSubjectModal}
                                onClose={hidePreviewSubjectModal}
                                subjectId={previewLocation}
                                redirectToUpgrade={redirectToUpgrade}
                                hideSubjectsModal={hideSubjectsModal}
                            />
                            <Modal
                                ariaHideApp={false}
                                isOpen={recipientsModal}
                                onRequestClose={hideRecipientsModal}
                                className="Modal schedule"
                            >
                                <ListEditorModal
                                    result={automation.deliverySchedule}
                                    error={listError}
                                    onRequestClose={hideRecipientsModal}
                                    createRecipientsList={createRecipientsList}
                                />
                            </Modal>
                            <Modal
                                ariaHideApp={false}
                                isOpen={emailPreview}
                                onRequestClose={hideEmailPreview}
                                className="Modal schedule"
                            >
                                <PreviewEmail
                                    result={automation.deliverySchedule}
                                    scheduleType={automation.scheduleType}
                                    ownEmail={currentUser.email}
                                    sendError={sendError}
                                    interval={automation.interval}
                                    onRequestClose={hideEmailPreview}
                                    onPreviewSend={sendPreview}
                                />
                            </Modal>
                            <Modal
                                ariaHideApp={false}
                                isOpen={nameChangeModal}
                                onRequestClose={hideNameChangeModal}
                                className="Modal schedule not-wide"
                            >
                                <ChangeName
                                    name={automation.name}
                                    onRequestClose={hideNameChangeModal}
                                    nextStep={nextStep}
                                    onChangeName={onChangeName}
                                    isDraft={isDraft}
                                    redirectLink={redirectLink}
                                    saveChanges={saveChanges}
                                    itemString="training"
                                    isNameUntitled={isNameUntitled(
                                        automation.name,
                                        'training'
                                    )}
                                />
                            </Modal>
                            <Modal
                                ariaHideApp={false}
                                isOpen={saveChangesModal}
                                onRequestClose={hideSaveChangesModal}
                                className="Modal schedule not-wide"
                            >
                                <SaveChanges
                                    onRequestClose={hideSaveChangesModal}
                                    initiateSavingProgress={
                                        initiateSavingProgress
                                    }
                                    customBackUrl={customBackUrl}
                                    back={back}
                                    parentPath={parentPath}
                                    editing={
                                        automation.status === STATUS.ACTIVE ||
                                        automation.status ===
                                            STATUS.SCHEDULED ||
                                        automation.status === STATUS.DRAFT
                                    }
                                />
                            </Modal>
                        </div>
                    ) : (
                        <Fragment>
                            <br />
                            <br />
                            <br />
                            <ErrorMessage>
                                <Alert
                                    color="warning"
                                    className="header"
                                    isOpen={true}
                                >
                                    No license available
                                </Alert>
                            </ErrorMessage>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
}

export default observer(AutomationCreate);
