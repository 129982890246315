import React from 'react';
import {
    FlexDiv,
    TextLead,
    TextDefault,
    TextInput,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import { Avatar, ListItemAvatar, MenuItem } from '@mui/material';

export default observer(
    ({
        name,
        options,
        action,
        defaultValue,
        isLast,
        isPartnerCompanyDetails,
        noSavedMark,
    }) => {
        const [savedMark, setSavedMark] = React.useState(false);

        const onChange = async (event) => {
            const target = event.target;
            setSavedMark(false);
            await action(target.value);
            setSavedMark(true);
        };

        return (
            <FlexDiv
                py={isPartnerCompanyDetails ? 20 : "0"}
                borderBottom={!isLast}
            >
                <FlexDiv flexGrow={1} alignCenter>
                    {isPartnerCompanyDetails ? (
                        <TextDefault lighter>{name}</TextDefault>
                    ) : (
                        <TextLead bold lighter>
                            {name}
                        </TextLead>
                    )}
                </FlexDiv>
                <FlexDiv flexGrow={2} leftIndent />
                <FlexDiv flexGrow={1} alignCenter style={{ position: "relative" }}>
                    <TextInput
                        placeholder="Language"
                        name="companySize"
                        value={defaultValue}
                        inputProps={{ sx: { display: "flex", alignItems: "center" } }}
                        onChange={(e) => onChange(e)}
                        select
                    >
                        {options.map((x) => (
                            <MenuItem key={x.key} value={x.value}>
                                {x.img && <ListItemAvatar sx={{minWidth: 32}}><Avatar
                                    src={x.img}
                                    alt=""
                                    sx={{ width: 24, height: 24}}
                                /></ListItemAvatar>}
                                {x.name}
                            </MenuItem>
                        ))}
                    </TextInput>
                    {savedMark && !noSavedMark && (
                        <TextDefault themed bold ml={16} style={{ position: "absolute", left: "-70px" }}>
                            Saved!
                        </TextDefault>
                    )}
                </FlexDiv>
            </FlexDiv>
        );
    }
);
