import React, { Fragment, useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import pick from 'lodash/pick';
//import every from 'lodash/every';
import { Link } from 'react-router-dom';
import TeamsFormDef from '../../forms/teams';
import { Spinner } from 'reactstrap';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import ErrorMessage from '../../components/errormessage';
import { Row, Col } from 'reactstrap';
import { useStores } from '../../hooks/use-stores';
import IntegrationDetailSidebar from '../../components/company/integration-detail-sidebar';
import TextField from '../../components/inputs/textField';
import LaunchIcon from '@mui/icons-material/Launch';
import {
    Heading1,
    TextDefault,
    ThemedButton,
} from '@awarego/awarego-components';

export default observer(({ companyId }) => {
    const { companyStore, commonStore, brandingStore } = useStores();

    const store = useLocalObservable(() => ({
        _form: new TeamsFormDef(
            {},
            {
                hooks: {
                    onSuccess: async () => {
                        await companyStore.saveIntegrationData(
                            companyId,
                            'teams',
                            { enabled: '1', ...store._form.values() }
                        );
                    },
                },
            }
        ),
        update(values) {
            store._form.update(values);
        },
    }));

    const doLoadData = async () => {
        await companyStore.loadIntegrationData(companyId, 'teams');

        let obj = companyStore.teamsDataIndex[companyId];
        if (obj) {
            store.update(pick(obj, 'tenant', 'enabled'));
        }
    };

    useEffect(() => {
        doLoadData();
    }, [companyId]);

    const onToggleEnabled = async () => {
        if (companyStore.teamsDataIndex[companyId].enabled === '1') {
            await companyStore.saveIntegrationData(companyId, 'teams', {
                enabled: '0',
            });
        } else {
            await companyStore.saveIntegrationData(companyId, 'teams', {
                enabled: '1',
            });
        }
    };

    const testIntegration = async () => {
        try {
            let result = await companyStore.testIntegration(
                companyId,
                'teams',
                store._form.values()
            );
            if (result.success)
                commonStore.showMessage('Connection successful');
            else
                commonStore.showMessage(
                    'Connection failed: ' + result.error,
                    'error'
                );
        } catch (e) {
            commonStore.showMessage('Connection failed: ' + e.message, 'error');
        }
    };

    const {
        savingTeamsData,
        /*testingTeamsData,*/ loadingTeamsDatas,
        teamsError,
    } = companyStore;
    const { account_name, brandingLoaded, integrationTeamsUrl } = brandingStore;

    let data = companyStore.teamsDataIndex[companyId];

    return loadingTeamsDatas.includes(companyId) || !data ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <Link to=".." relative="path">
                <div className={'navigation back-button'}>
                    Back to Integration overview
                </div>
            </Link>
            <Row>
                <Col lg="8" xs="12">
                    <div className="integration-detail-block  align-left">
                        <div className="header-line">
                            {teamsError && (
                                <ErrorMessage
                                    error={teamsError}
                                    marginBottom={true}
                                />
                            )}
                            <Heading1>
                                {(brandingLoaded && account_name) || 'AwareGO'}{' '}
                                + Teams
                            </Heading1>

                            <img
                                src={`/static/img/logo-teams.png`}
                                alt="Teams logo"
                            />
                        </div>

                        {data.enabled === '0' ? (
                            <div>
                                <p
                                    className={
                                        data.enabled === '0'
                                            ? 'margin-bottom'
                                            : ''
                                    }
                                >
                                    Teams integration is used to send messages
                                    to users on Teams, when training is
                                    accessible, and to send reminders when
                                    training is scheduled. When connected, all
                                    existing and new Teams users will be
                                    automatically added to the{' '}
                                    {(brandingLoaded && account_name) ||
                                        'AwareGO'}{' '}
                                    employee list that you specify.
                                </p>
                                <div className="section">
                                    <div className="section-heading">
                                        Connection details
                                    </div>
                                    <TextField
                                        field={store._form.$('tenant')}
                                        minWidth={'350px'}
                                    />
                                    <p>
                                        All existing and new Azure AD users will
                                        be automatically added to the{' '}
                                        {(brandingLoaded && account_name) ||
                                            'AwareGO'}{' '}
                                        training list that you specify.
                                    </p>
                                </div>
                                <div className="section">
                                    <div className="section-heading">
                                        Connect
                                    </div>
                                    <div className="section">
                                        <p>
                                            Click on the following button to
                                            connect to MS Teams
                                        </p>
                                        {savingTeamsData.includes(companyId) ? (
                                            <Spinner />
                                        ) : (
                                            <ThemedButton
                                                mode="primary"
                                                onClick={store._form.onSubmit}
                                                text="Connect Teams"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}

                        {data.enabled === '1' ? (
                            <div>
                                <p>
                                    Reduce the number of emails your employees
                                    receive. Send them messages via Teams
                                    instead.
                                </p>

                                <div>
                                    <div className="integration-status">
                                        Status:&nbsp;
                                        <span className="success">
                                            Connected
                                        </span>
                                    </div>
                                    <div className="section">
                                        <div className="section-heading">
                                            Tenant id
                                        </div>
                                        <div className="integration-data">
                                            {data.tenant}
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="section-heading">
                                            Download app
                                        </div>
                                        <p>
                                            To be able to use the{' '}
                                            {(brandingLoaded && account_name) ||
                                                'AwareGO'}{' '}
                                            Teams integration you will need to
                                            download the Microsoft Teams{' '}
                                            {(brandingLoaded && account_name) ||
                                                'AwareGO'}
                                            app and install it for your team or
                                            organization.
                                        </p>
                                        <div className="integration-data">
                                            <a
                                                href={integrationTeamsUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <TextDefault themed>
                                                    Read installation
                                                    instructions
                                                </TextDefault>
                                                <LaunchIcon
                                                    fontSize="small"
                                                    color="primary"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <a
                                        href="https://resources.awarego.com/teams/AwareGO-Teams-app_1.0.6.zip"
                                        download="AwareGO-Teams-app_1.0.5.zip"
                                    >
                                        <ThemedButton
                                            mode="primary"
                                            text="Download app"
                                        />
                                    </a>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </Col>
                <Col lg="4" xs="12">
                    <IntegrationDetailSidebar
                        href={integrationTeamsUrl}
                        product="Teams"
                    />
                </Col>
                {data.enabled === '1' ? (
                    <Col lg="8" xs="12">
                        <div className="integration-detail-block  align-left">
                            <div>
                                <div className="section-heading">
                                    Unlink integration
                                </div>
                                <p>
                                    By unlinking this integration you will not
                                    be able to send messages to users using
                                    Teams directly. This will affect all
                                    currently running training programs.
                                </p>
                                <ThemedButton
                                    mode="secondary"
                                    color={'#AD2931'}
                                    onClick={onToggleEnabled}
                                    text="Unlink integration"
                                />
                            </div>
                        </div>
                    </Col>
                ) : (
                    ''
                )}
            </Row>
            <br />
            <br />
        </Fragment>
    );
});
